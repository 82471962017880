
class Group {

    constructor() {

    }

    async filterUrl(request) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'user/checkBusinessPackageDate'
        });

        if(!ret.business_package_valid) {
            runActionUrl('group/all');
        }
    }

    async allAction(request) {
        let self = this;

        var members_groups_display_index = request.members_groups_display_index ? request.members_groups_display_index : 4;
        var latest_groups_display_index = request.latest_groups_display_index ? request.latest_groups_display_index : 4;

        var ret = await core.libs.ws.sendAndWait({
            action: 'group/all',
            members_groups_display_index: members_groups_display_index,
            latest_groups_display_index: latest_groups_display_index
        });

        var ret2 = await this.getLatestTopics(request);

        var ret3 = await core.libs.ws.sendAndWait({
            action: 'user/checkBusinessPackageDate'
        });

        var view_template = request.type && request.type == 'list' ?
            core.template.group.groups_list :
            core.template.group.groups;

        $('#content').html(core.template.render(view_template, {
            most_members_groups_count: ret.most_members_groups_count,
            latest_groups_count: ret.latest_groups_count,
            most_members_groups: ret.most_members_groups,
            latest_groups: ret.latest_groups,
            current_members_groups_display: ret.most_members_groups.length,
            current_latest_groups_display: ret.latest_groups.length,
            see_more_most_members_groups: parseInt(ret.most_members_groups_count) > parseInt(ret.most_members_groups.length) ? true : false,
            see_more_latest_groups: parseInt(ret.latest_groups_count) > parseInt(ret.latest_groups.length) ? true : false,
            display_topics_section: app.user.groups.logged && ret2.topics_count > 0,
            can_add_new_group: ret3.business_package_valid, group_list: ret.most_members_groups_count > 0 || ret.latest_groups_count > 0
        }));

        if(app.user.groups.logged) {
            await this.loadLatestTopicsAction({topics_display_index: 2});
        }

    }

    async editAction(request) {

        let self = this;

        await self.filterUrl(request, true);

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/groupEdit',
            id: request.id
        });

        $('#content').html(core.template.render(core.template.group.edit, ret));

        var $form = $('#' + ret.form.id);
        core.libs.form.handle($form, ret, function (data) {
            (async() => {
                var ret = await core.libs.ws.sendAndWait({
                    action: 'admin/groupEdit',
                    data
                });

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }

                if (ret.success) {
                    runActionUrl('group/all');
                    showModalSuccess(ret.message, 5000);
                }
            })();
        });

        // $form.find('#field_admin_group_edit_max_members').hide();
        // $form.find('#field_admin_group_edit_open').parents('.col-6').hide();
    }


    async getLatestTopics(request) {

        var topics_display_index = request.topics_display_index ? request.topics_display_index : 2;

        var ret = await core.libs.ws.sendAndWait({
            action: 'topic/latest',
            topics_display_index: topics_display_index
        });

        return ret;
    }

    async loadLatestTopicsAction(request) {

        var ret = await this.getLatestTopics(request);

        if(ret.topics_count > 0) {
            for(let i in ret.topics) {
                ret.topics[i].responses_limited = ret.topics[i].responses.slice(0, 8);
                if (ret.topics[i].responses.length > 8) {
                    ret.topics[i].responses_left = `+`+(ret.topics[i].responses.length - 8)+' '+core.lang.get('topic.responses_left')
                }
            }
        }

        $('#content .groups-container .latest-topics .content').html(core.template.render(core.template.group.topic_list, {
            topics: ret.topics,
            current_topics_display: ret.topics.length,
            see_more_topics: parseInt(ret.topics_count) > parseInt(ret.topics.length) ? true : false,
        }));

        this.handleUserResponses(ret.topics, $('.latest-topics > div > div'), $('.latest-topics .topic-response'), $('.latest-topics .responses-on-topic'));
    }

    async showAction(request) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'group/show',
            id: request.id
        });

        var possible_join = false;
        var waiting_for_acceptance = false;

        var ret2 = await core.libs.ws.sendAndWait({
            action: 'group/checkJoinStatus',
            id: request.id
        });

        var ret3 = await core.libs.ws.sendAndWait({
            action: 'group/checkMembership',
            id_group: request.id
        });

        var ret4 = await core.libs.ws.sendAndWait({
            action: 'user/checkBusinessPackageDate'
        });

        switch(parseInt(ret2.status)) {
            case 0:
                possible_join = true;
            break;
            case 1:
                waiting_for_acceptance = true;
            break;
        }

        ret.group.group_image = ret.group.image ? ret.group.image.url : '/public/img/paih_space.jpg';

        $('#content').html(core.template.render(core.template.group.group, {
            group: ret.group,
            possible_join: possible_join,
            waiting_for_acceptance: waiting_for_acceptance,
            is_group_member: ret3.member,
            show_join_button: app.user.groups.logged && !ret3.member && !ret3.group_full && ret.group.open && ret4.business_package_valid,
            show_unjoin_button: ret3.member && ret.group.open,
            one_topic_mode: request.id_topic,
            display_topics_section: app.user.groups.logged && ret3.member,
            can_add_new_group: ret4.business_package_valid && !ret3.group_full
        }));


        if (request.id_topic) {
            await this.loadGroupTopicListAction(request.id, 1, request.id_topic, request.id_user, $('.group-topics'));
        } else if(request.tag) {
            await this.loadGroupTopicsAction(request.id, request.tag);
            await this.loadGroupTopicListAction(request.id, request.tag);
        }

        await this.loadMembersAction({id: request.id})
    }

    async loadMembersAction(request) {

        var members_display_index = request.members_display_index ? request.members_display_index : 4;

        var ret = await core.libs.ws.sendAndWait({
            action: 'group/getMembers',
            id: request.id,
            members_display_index: members_display_index
        });

        var view_template = request.type && request.type == 'list' ?
            core.template.group.members_list :
            core.template.group.members;

        $('#content .group-members').html(core.template.render(view_template, {
            group_members: ret.members, group_id: request.id,
            current_members_display: ret.members.length,
            see_more_members: parseInt(ret.members_count) > parseInt(ret.members.length) ? true : false,
        }));

    }

    async membersAction(request) {

        var self = this;

        await self.filterUrl(request);

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/groupMembers',
            id: request.id
        });

        var ret2 = await core.libs.ws.sendAndWait({
            action: 'admin/checkGroupMembersNumber',
            id: request.id
        });

        $('#content').html(core.template.render(core.template.group.admin_group_members, {members: ret.members, group_id: request.id, show_add_member_button: !ret2.group_full}));
        $('.add-group-member').click(async function() {
            var html = core.template.render(core.template.group.user_groups);
            var modal = showModal(
                core.lang.get('common.users'),
                html,
                [
                    { label: core.lang.get('common.close'), class: 'btn-secondary', close: true, onclick: function() {
                        self.groupMembersAction({id: request.id})
                    }},
                    { label: core.lang.get('group.join'), class: 'btn-primary', onclick: function() {

                        var selected_user_space = $(document).find('#group-users-modal .selected-user-space').val();

                        if(!selected_user_space.length) {
                            $(document).find('#group-users-modal .selection-error').show();
                            return false;
                        } else {
                            runAction({action: 'admin/addGroupMember',  user_id: app.user.id,  group_id: request.id, company_id: selected_user_space });
                            modal.hide();
                        }
                    }},
                ],
                false,
                { width: 1000 });

                $(modal._element).find('.btn.button-1').hide();

                self.loadUsersAction(request);
                self.membersAction({id: request.id})
        });

        $(document).on('keyup', '#group-users-modal .search-input', function() {
            var parent = $(this).parents('#group-users-modal');
            var value = $(this).val().toLowerCase();

            $(parent).find(".users-list tr").filter(function() {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
            });
        });
    }

    async loadUsersAction(request) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/getUsers',
            id: request.id
        });

        $(document).find('#group-users-modal').html(core.template.render(core.template.group.admin_groups_users, {
            users: ret.users, group_id: request.id
        }));

        $('#predef_modal').find('.btn.button-1').hide();
    }

    async loadGroupTopicsAction(id_group, tag = false) {

        var self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'topic/add',
        });

        var ret2 = await core.libs.ws.sendAndWait({
            action: 'group/checkMembership',
            id_group: id_group
        });

        $('#content .group-content .group-topics').html(core.template.render(core.template.group.topics, {
            form: ret.form, id_group: id_group, is_group_member: ret2.member, id_user: app.user.id
        }));

        var $form = $('#' + ret.form.id);

        $('.group-view .show-form-uploader').click(function() {
            $('#field_group_topic_image').slideToggle();
        });

        $('.group-view .submit-button').click(function() {
            $form.submit();
        });

        var tag_button = $('.group-topics .tag-button');

        tag_button.click(function() {
            var this_el = $(this);
            tag_button.removeClass('selected');
            this_el.addClass('selected');
            var type = this_el.data('type');

            runActionUrl('group/show/id/' + id_group + '/tag/' + type);
        });

        core.libs.form.handle($form, ret, function (data) {
            (async() => {
                var ret3 = await core.libs.ws.sendAndWait({
                    action: 'topic/add',
                    data
                });

                if (ret3.errors) {
                    core.libs.form.showErrors($form, ret3);
                }

                if (ret3.success) {
                    self.loadGroupTopicsAction(id_group);
                }

            })();
        });

        var url_params = getUrlParams();

        if(url_params.tag) {
            $('.group-topics .tag-button[data-type="'+url_params.tag+'"]').addClass('selected');
        }
    }

    async loadGroupTopicListAction(id_group, type, id_topic, chat_id_user, $el) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'topic/all',
            id_group: id_group,
            id_topic,
            type: type
        });

        for(let i in ret.topics) {
            ret.topics[i].responses_limited = ret.topics[i].responses.slice(0, 8);
            if (ret.topics[i].responses.length > 8) {
                ret.topics[i].responses_left = `+`+(ret.topics[i].responses.length - 8)+' '+core.lang.get('topic.responses_left')
            }
        }

        if (!$el) {
            $el = $('#content .group-content .group-topics .topic-list');
        }

        $el.html(core.template.render(core.template.group.topic_list, {
            topics: ret.topics, id_group: id_group, id_user: app.user.id, see_more_topics: false
        }));

        //this.handleUserResponses(ret.topics, $('.topic-list > div'), $('.group-topics .topic-response'), $('.group-topics .responses-on-topic'));

        if (chat_id_user && ret.topics) {
            app.group.shown_topic = ret.topics[0];
            app.group.chat({ id: id_topic, id_user: chat_id_user });
        }
    }


    handleUserResponses(topics, $hide_elements, $response_button, $topic_responses) {

        // User respond to the topic.
        $('.group-topics .topic-response').click(function() {
            /*$('#group_topic').hide();
            $('.topic-list > div').hide();
            let $topic = $(this).parents('.topic-item');
            $hide_elements.hide();
            $topic.show();
            let id_topic = $(this).data('id');
            for(let i in topics) {
                if (id_topic == topics[i].id) {
                    app.group.shown_topic = topics[i];
                    app.group.chat({ $el: $topic.find('.chat'), id: $(this).data('id'), id_user: app.user.id });
                }
            }*/
        });

        // Users clicks list of user who responded on the topic
        $topic_responses.click(function() {
            let id = $(this).data('id');
            for(let i in topics) {
                if (id == topics[i].id) {
                    app.group.shown_topic = topics[i];

                    let modal = showModal(core.lang.get('topic.responses'), core.template.render(core.template.group.topic_responses, {
                        topic: topics[i]
                    }), undefined, true, { width: 850 });
                }
            }
        });
    }

    async chat(request) {
        hideModal();

        var chat = await core.libs.ws.sendAndWait({
            action: 'topic/chat',
            id: request.id,
            id_user: request.id_user,
            get_topic: request.get_topic,
        });

//        console.log('chat', request);
//        console.log('chat', chat);

        if (chat.error) {
            //runActionUrl('home');
        }

        if (request.get_topic) {
            this.shown_topic = chat.topic;
        }

        var history = await core.libs.ws.sendAndWait({
            action: 'chat/history',
            id_chat: chat.chat.id,
            get_history: true,
        });

        if (!request.$el) {
            var group_topics = $('.group-topics');
            var topics_container = group_topics.length ? group_topics : $('.latest-topics');
            //request.$el = $('.group-topics').find(`.topic-item[data-id="${request.id}"] .chat`);
            request.$el = topics_container.find(`.topic-item[data-id="${request.id}"] .chat`);
        }

        // Onwer choose a conversation
        if (!request.user) {
            if (this.shown_topic) {
                if (this.shown_topic.user.id == request.id_user) {
                    request.user = new orm.user(this.shown_topic.user);
                } else {
                    for(let i in this.shown_topic.responses) {
                        if (this.shown_topic.responses[i].id == request.id_user) {
                            request.user = new orm.user(this.shown_topic.responses[i]);
                        }
                    }
                }
            } else {
                request.user = new orm.user({ name: '', avatar: '' });
            }
        }

        // Viewer opens the conversation
        if (!request.user && this.shown_topic) {
            request.user = new orm.user(this.shown_topic.user);
        }

        if (chat.chat.id_user_a == app.user.id && chat.chat.a_read == 0 || chat.chat.id_user_b == app.user.id && chat.chat.b_read == 0) {
            app.user.chat_notification_count--;
            app.user.updateNotificationDot();
        }

        this.createChatWindow(request, request.user, chat, history);
    }

    createChatWindow(request, user, chat, history) {
        this.chat_window = new ChatWindow();
        this.chat_window.init(request.$el, user, history.messages, chat.chat, async function(message) {
            message.panel = false;
            $('.chat_message_info').show();
            var ret = await core.libs.ws.sendAndWait({
                action: 'chat/send',
                id_chat: chat.chat.id,
                panel: true,
                message
            });
        }, false, {
            /*'[![meeting_url]!]': core.lang.get('chat.insert_meeting_link')*/
        });
    }

    async topicChat(request) {

        var chat = await core.libs.ws.sendAndWait({
            action: 'topic/chat',
            id: request.id,
            id_user: request.id_user,
            get_user: true
        });

        var history = await core.libs.ws.sendAndWait({
            action: 'chat/history',
            id_chat: chat.chat.id,
            get_history: true,
        });

        if(chat.chat_user) {
            let chat_user = new orm.user(chat.chat_user);
            this.createChatWindow(request, chat_user, chat, history);
        }
    }

    async onTriggerUrlAfter(data) {
        if (!$("#chat").is(":visible")) {
            this.chat_window = false;
        }
    }

    async listPopup() {
        var ret = await core.libs.ws.sendAndWait({
            action: 'topic/listPopup',
        });

        app.user.chat_notification_count = ret.count;
        app.user.updateNotificationDot();

        for(let i in ret.topics) {
            if (ret.topics[i].responses_count == 1) {
                ret.topics[i].responses_count_message = core.lang.get('topic.responses_count');
            } else {
                ret.topics[i].responses_count_message = core.lang.get('topic.responses_count_s');
            }
        }

        let html = core.template.render(core.template.group.list_popup, ret);

        let modal = showModal(core.lang.get('group.newest'), html, undefined, true, { width: 800 });
    }

    async topicsAction(request) {

        if (!app.user.groups.logged) {
            runActionUrl('home');
            return;
        }

        var type = request.tag ? request.tag : 1;

        var ret = await core.libs.ws.sendAndWait({
            action: 'topic/recommendations',
            type: type
        });

        let html = core.template.render(core.template.group.topics_from_menu, {
            topics: ret.topics,
            select_options: ret.select_options,
            selected_type: type,
            topics_list: ret.topics.length
        });
        $('#content').html(html);

        var tag_select = $('.all-topics .topic-list .tag-select');
        tag_select.on('change', function() {
            var selected_value = $(this).val();
            runActionUrl('group/topics/tag/' + selected_value);
        });

        if (request.topic) {
            var ret2 = await core.libs.ws.sendAndWait({
                action: 'topic/responders',
                type: type,
                id_group: request.group,
                id_topic: request.topic
            });

            let html2 = core.template.render(core.template.group.responders, { responders: ret2.responders, selected_type: type, id_topic: request.topic, id_group: request.group });

            $('#content .all-topics .topic-responders .content').html(html2);
        }

        if(request.chat && request.id_user) {
            app.group.topicChat({ id: request.topic, id_user: request.id_user, $el: $('.all-topics .topic-chat')});
        }

        var topic_items = $('.all-topics .topic-list .grid-avatar.topic-user');
        var topic_responders = $('.all-topics .topic-responders .grid-avatar.topic-user');
        var url_params = getUrlParams('group/topics');

        if(!url_params.group && !url_params.topic && $('.topic-list .grid-avatar.topic-user').length) {
            $('.topic-list .grid-avatar.topic-user').first().trigger('click');
        }

        if(!url_params.chat && $('.topic-responders .grid-avatar.topic-user').length) {
            $('.topic-responders .grid-avatar.topic-user').first().trigger('click');
        }

        topic_items.on('click', function() {
            topic_items.removeClass('selected');
            $(this).addClass('selected');
        })

        topic_responders.on('click', function() {
            topic_responders.removeClass('selected');
            $(this).addClass('selected');
        })

        if(url_params.topic) {
            $('.all-topics .topic-list').find(`.grid-avatar.topic-user[data-topic='${url_params.topic}']`).addClass('selected');
        }

        if(url_params.chat && url_params.id_user) {
            $('.all-topics .topic-responders').find(`.grid-avatar.topic-user[data-user='${url_params.id_user}']`).addClass('selected');
        }
    }

    async joinAction(request) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'group/join',
            group_id: request.group_id,
            company_id: request.company_id,
            auto_accepted: false
        });

        await this.showAction({id: request.group_id});
        await triggerUrl();
    }

    async unjoinAction(request) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'group/unjoin',
            user_id: app.user.id,
            group_id: request.group_id
        });

        await this.showAction({id: request.group_id});
    }

    async userSpacesAction(request) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'group/userSpaces'
        });

        if(ret.spaces.length) {
            if(ret.spaces.length == 1) {

                await this.joinAction({
                    group_id: request.group_id,
                    company_id: ret.spaces[0].id
                });

            } else {

                var html = core.template.render(core.template.group.user_groups);
                var modal = showModal(
                    core.lang.get('group.choose_company'),
                    html,
                    [
                        { label: core.lang.get('common.cancel'), class: 'btn-secondary', close: true, onclick: function() {
                            //self.groupMembersAction({id: request.id})
                        }},
                         { label: core.lang.get('group.join'), class: 'btn-primary', onclick: function() {

                            var selected_user_space = $(document).find('#group-users-modal .selected-user-space').val();

                            if(!selected_user_space.length) {
                                $(document).find('#group-users-modal .selection-error').show();
                                return false;
                            } else {
                                runAction({action: 'group/join',  user_id: app.user.id,  group_id: request.group_id, company_id: selected_user_space });
                                modal.hide();
                            }
                        }},
                    ],
                    false,
                    { width: 1000 });

                    $(document).find('#group-users-modal').html(core.template.render(core.template.group.spaces, {
                        spaces: ret.spaces, user_id: app.user.id, group_id: request.group_id, show_back_button: false,
                        action: 'group/join', button_text: core.lang.get('group.join')
                    }));

                    modal.hide();
            }
        }

        var user_space = $(document).find('#group-users-modal .user-space');
        var selected_user_space = $(document).find('#group-users-modal .selected-user-space');
        var selection_error = $(document).find('#group-users-modal .selection-error');

        user_space.click(function() {
            var this_element = $(this);
            user_space.removeClass('space-selected');
            this_element.addClass('space-selected');
            var space_id = this_element.data('company_id');
            selection_error.hide();
            selected_user_space.val(space_id);
        });

    }

}

app.group = new Group();

