Validator.registerAsync('email_available', function (email, attribute, req, passes) {
    (async() => {
        var ret = await core.libs.ws.sendAndWait({
            action: 'user/emailAvailable',
            email
        });

        passes(ret.available, ret.error);
    })();
});

Validator.registerAsync('url_available', function (url, attribute, req, passes) {
    (async() => {
        var ret = await core.libs.ws.sendAndWait({
            action: 'company/urlAvailable',
            url,
            id_company: attribute
        });

        passes(ret.available, ret.error);
    })();
});

Validator.registerAsync('min_files', function (file, attribute, req, passes) {
    // Validate on backend.
    passes(true);
});

Validator.registerAsync('max_files', function (file, attribute, req, passes) {
    // Validate on backend.
    passes(true);
});

Validator.registerAsync('max_file_size', function (file, attribute, req, passes) {
    (async() => {
        var ret = await core.libs.ws.sendAndWait({
            action: 'company/maxFileSize',
            file_size: file.size,
            max_file_size: attribute
        });

        passes(ret.file_size, ret.error);
    })();
});

Validator.registerAsync('max_file_width', function (file, attribute, req, passes) {
    (async() => {
        var ret = await core.libs.ws.sendAndWait({
            action: 'company/maxFileWidth',
            file: { name: file.name, size: file.size },
            file_name: file.name,
            max_file_width: attribute
        });

        passes(ret.file_width, ret.error);
    })();
});

Validator.registerAsync('availability_for_type', function (product_type, attribute, req, passes) {
    (async() => {
        var ret = await core.libs.ws.sendAndWait({
            action: 'product/availabilityForType',
            id: this.validator.input.id,
            id_user: app.user.id,
            product_type
        });

        passes(ret.available, ret.error);
    })();
});

Validator.registerAsync('password_strength', function(password, attribute, req, passes) {

    var valid = false;

    if (password.length >= 8 && 
        password.length <= 20 && 
        password.match(/[a-z]+/) && 
        password.match(/[A-Z]+/) && 
        password.match(/[0-9]+/)) {
            valid = true;
    }

    passes(valid);
});

Validator.registerAsync('checkbox_required', function(val, attribute, req, passes) {
    passes(val == '1');
});

Validator.registerAsync('is_unique', function (url, attribute, req, passes) {
    (async() => {
        var ret = await core.libs.ws.sendAndWait({
            action: 'company/checkUniqueUrl',
            id: this.validator.input.id,
            url: url
        });

        passes(ret.unique, ret.error);
    })();
});

Validator.registerAsync('current_password_check', function (password, attribute, req, passes) {
    (async() => {
        var ret = await core.libs.ws.sendAndWait({
            action: 'user/currentPasswordCheck',
            id_user: app.user.id,
            password
        });

        passes(ret.match, ret.error);
    })();
});

Validator.registerAsync('url_valid', function (url, attribute, req, passes) {
    var valid = /^[a-z0-9-]*$/.test(url);
    passes(valid);
});

Validator.registerAsync('url_reserved_words', function (url, attribute, req, passes) {
    var reserved_words = ['home'];
    var valid = !reserved_words.includes(url);
    passes(valid);
});

Validator.registerAsync('bigger_than_zero', function(number, attribute, req, passes) {
    var valid = false;
    if (parseInt(number) > 0) valid = true;
    passes(valid);
});

Validator.registerAsync('check_stand_visibility', function(number, attribute, req, passes) {
    var valid = true;
    passes(valid);
});

Validator.registerAsync('email_exists', function(number, attribute, req, passes) {
    var valid = true;
    passes(valid);
});

Validator.registerAsync('favorites_url', function(url, attribute, req, passes) {
    var valid = true;
    passes(valid);
});

Validator.registerAsync('public_space_available', function(status, attribute, req, passes) {
    var valid = true;
    passes(valid);
});

Validator.registerAsync('max_selected', function(file, attribute, req, passes) {
    var valid = true;
    passes(valid);
});

Validator.registerAsync('min_number', function(number, attribute, req, passes) {
    if(parseInt(number) >= parseInt(attribute)) {
        passes(true);
    } else {
        passes(false);
    }
});

Validator.registerAsync('max_number', function(number, attribute, req, passes) {
    if(parseInt(number) <= parseInt(attribute)) {
        passes(true);
    } else {
        passes(false);
    }
});

Validator.registerAsync('code_unique', function (code, attribute, req, passes) {
    var attributes = attribute.split(':');
    var affiliate_id = parseInt(attributes[0]);
    (async() => {
        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/checkUniqueCode',
            code: code,
            affiliate_id: affiliate_id
        });

        passes(ret.unique, ret.error);
    })();
});

Validator.registerAsync('expiration_date_valid', function (date, attribute, req, passes) {
    (async() => {
        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/checkExpireDateValid',
            date: date
        });

        passes(ret.valid, ret.error);
    })();
});