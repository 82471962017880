
libsPromise.then(() => {
    Mustache.Formatters = {
        // use: {{ zmienna | jakiś tekst | 123 | example_many_params }}
        example_many_params: function(param1, param2, param3) {
            console.log("example_many_params formatter", param1, param2, param3);
            return param1 + " + " + param2 + " + " + param3;
        },
        date: function(date) {
            if(!date) {
                return '';
            }
            if(typeof date != 'object') {
                if(/^\d+$/.test(date)) {
                    date = new Date( parseInt(date) * 1000);
                }else {
                    date = new Date( date);
                }
            }
            return date.toLocaleDateString();
        },
        time: function(date) {
            if(!date) {
                return '';
            }
            if(typeof date != 'object') {
                if(/^\d+$/.test(date)) {
                    date = new Date( parseInt(date) * 1000);
                }else {
                    date = new Date( date);
                }
            }
            return date.toLocaleTimeString();
        },
        datetime: function(date) {
            if(!date) {
                return '';
            }
            if(typeof date != 'object') {
                if(/^\d+$/.test(date)) {
                    date = new Date( parseInt(date) * 1000);
                }else {
                    date = new Date( date);
                }
            }
            return Mustache.Formatters.date(date) + ' ' + Mustache.Formatters.time(date);
        },
        sqldate: function(date) {
            if(typeof date == 'string') {
                date = new Date(date);
            }
            if(!date) {
                return date;
            }
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            var d = date.getDate();
            if(m < 10) {
                    m = '0' + m;
            }
            if(d < 10) {
                    d = '0' + d;
            }
            return y + '-' + m + '-' + d;
        },
        bool: function(value) {
            console.log(value, 'value')
            return !!value;
        },
        nl2br: function(value) {
            return value.replaceAll("\n", "<br/>");
        },
        typeof: function(value) {
            return typeof value;
        }
    };
});