/*
 * Show company
 */

var krpano_edit = null;
var krpano_preview = null;
//var krpano = null;

var hs_index = 0;

class Admin {
    logged = false;

    constructor() {

    }

    async setAction(request) {
    }

    async standsAction(request) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/stands'
        });

        if (ret.error) {
            showModalFail(ret.message, 5000);
            return;
        }

        $('#content').html(core.template.render(core.template.admin.stands, {stands: ret.stands}));
    }

    async assignStandAction(request) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/assignStand'
        });

        if (ret.error) {
            showModalFail(ret.message, 5000);
            return;
        }

        $('#content').html(core.template.render(core.template.admin.assign_stand, ret));

        var $form = $('#' + ret.form.id);

        core.libs.form.handle($form, ret, function (data) {
            (async() => {
                var ret = await core.libs.ws.sendAndWait({
                    action: 'admin/assignStand',
                    data
                });

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }

                if (ret.success) {
                    runActionUrl('admin/stands');
                }
            })();
        });
    }

    async standEditAction(request) {

        let self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/standEdit',
            id: request.id
        });

        if (ret.error) {
            showModalFail(ret.message, 5000);
            return;
        }

        function handleType() {
            let val = $('#field_admin_stand_edit_type select').val();
            if (val == '2') {
                $('#field_admin_stand_edit_stand_tree select option[skip_for_kit="1"]').prop('selected', false).hide();
            } else {
                $('#field_admin_stand_edit_stand_tree select option').show();
            }
        }
        $('#content').html(core.template.render(core.template.admin.stand_edit, ret)).find('#field_admin_stand_edit_type select').on('change', function () {
            handleType();
        });

        handleType();

        var $form = $('#' + ret.form.id);
        core.libs.form.handle($form, ret, function (data) {

            if(parseInt(data.type) > 1) {
                data['stand_tree'] = $form.find('select[name=stand_tree]').val();
            }

            (async() => {
                var ret = await core.libs.ws.sendAndWait({
                    action: 'admin/standEdit',
                    data
                });

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }

                if (ret.success) {
                    setTimeout(() => {
                        app.company.getStands(true);
                    }, 500);
                    //self.standsAction();
                    runActionUrl('admin/stands');
                }
            })();
        });
    }

    async standDelete(id) {

        let self = this;


        let modal = showModalConfirm(core.lang.get('admin.confirm_delete_stand'), async function () {
            modal.hide();

            var ret = await core.libs.ws.sendAndWait({
                action: 'admin/standDelete',
                id: id
            });

            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }
            else
            if (ret.confirm) { // is used so more confirm needed
                let modal = showModalConfirm('<h4 style="color: red">'+core.lang.get('admin.confirm_delete_used_stand', { bought: ret.stand.bought, used: ret.stand.used }) + '</h4>', async function () {
                    modal.hide();

                    var ret = await core.libs.ws.sendAndWait({
                        action: 'admin/standDelete',
                        id: id,
                        force: 1
                    });

                    if (ret.error) {
                        showModalFail(ret.message, 5000);
                        return;
                    }

                    runActionUrl('admin/stands');

                }, false, 'danger me-5', core.lang.get('common.yes'));

                return;
            }
            else {
                runActionUrl('admin/stands');
            }

        }, false, 'success', core.lang.get('common.yes'));

    }

    // CSV export of payment history
    async historyAction(request) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/history'
        });

        if (ret.error) {
            showModalFail(ret.message, 5000);
            return;
        }

        if (ret.csv) {
            function downloadFile(file) {
                // Create a link and set the URL using `createObjectURL`
                const link = document.createElement("a");
                link.style.display = "none";
                link.href = URL.createObjectURL(file);
                link.download = file.name;

                // It needs to be added to the DOM so it can be clicked
                document.body.appendChild(link);
                link.click();

                // To make this work on Firefox we need to wait
                // a little while before removing it.
                setTimeout(() => {
                    URL.revokeObjectURL(link.href);
                    link.parentNode.removeChild(link);
                }, 0);
            }

            // Dynamically create a File
            const myFile = new File([core.template.render(core.template.admin.history, ret)], "history.csv");

            // Download it using our function
            downloadFile(myFile);
        }
    }

    async groupsAction(request) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/groups'
        });

        if (ret.error) {
            showModalFail(ret.message, 5000);
            return;
        }

        $('#content').html(core.template.render(core.template.admin.groups.groups, {groups: ret.groups}));
    }

    async creditsAction(request) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/credits'
        });

        if (ret.error) {
            showModalFail(ret.message, 5000);
            return;
        }

        $('#content').html(core.template.render(core.template.admin.credits.credits, {credits: ret.credits}));
    }

    async creditEditAction(request) {

        let self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/creditEdit',
            id: request.id
        });

        if (ret.error) {
            showModalFail(ret.message, 5000);
            return;
        }

        $('#content').html(core.template.render(core.template.admin.credits.credit_edit, ret));

        var $form = $('#' + ret.form.id);
        core.libs.form.handle($form, ret, function (data) {
            (async() => {
                var ret = await core.libs.ws.sendAndWait({
                    action: 'admin/creditEdit',
                    data
                });

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }

                if (ret.success) {
                    runActionUrl('admin/credits');
                }
            })();
        });

        if (request.id)
            self.creditConversion();

        $('input#field_admin_credit_edit_value').on('change', self.creditConversion);
        $('#field_admin_credit_edit_currency input[name="currency"]').on('change', self.creditConversion);
    }

    creditConversion() {
        return;

        var credit_conversion_box = $('.credit-conversion-box');
        var credit_conversion_result = credit_conversion_box.find('.credit-conversion-result');
        var credit_conversion_currency = credit_conversion_box.find('.credit-conversion-currency');
        var credit_unit_value_input = $('input#field_admin_credit_edit_value');
        var currency_name_field = $('#field_admin_credit_edit_currency input[name="currency"]');
        var current_value = parseInt(credit_unit_value_input.val());
        var converted_value = current_value / 100;

        if (currency_name_field.val().length && current_value) {
            credit_conversion_box.removeClass('d-none');
            credit_conversion_result.html(converted_value);
            credit_conversion_currency.html(currency_name_field.val())
        } else {
            credit_conversion_box.addClass('d-none');
            credit_conversion_result.html('');
            credit_conversion_currency.html('');
        }
    }

    async paymentsAction() {

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/payments'
        });

        $('#content').html(core.template.render(core.template.admin.payments_history, {invoices: ret.invoices}));
    }

    async affiliatesAction() {
        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/affiliates'
        });

        $('#content').html(core.template.render(core.template.admin.affiliates.affiliates, {affiliates: ret.affiliates}));
    }

    async affiliateEditAction(request) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/affiliateEdit',
            id: request.id
        });

        if (ret.error) {
            showModalFail(ret.message, 5000);
            return;
        }

        $('#content').html(core.template.render(core.template.admin.affiliates.affiliate_edit, ret));

        var $form = $('#' + ret.form.id);
        core.libs.form.handle($form, ret, function (data) {
            (async() => {
                var ret2 = await core.libs.ws.sendAndWait({
                    action: 'admin/affiliateEdit',
                    data
                });

                if (ret2.errors) {
                    core.libs.form.showErrors($form, ret2);
                }

                if (ret2.success) {
                    runActionUrl('admin/affiliates');
                }
            })();
        });
    }

    async confirmDeleteAffiliateAction(data) {
        let html = core.template.render(core.template.admin.affiliates.delete_confirm, {id: data.id});
        showModal(core.lang.get('user.confirm_affiliate_delete'), html, undefined, true, {width: 800});
    }

    async deleteAffiliateAction(data) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/deleteAffiliate',
            data
        });

        if (ret.success) {
            $('#predef_modal').modal('hide');
            this.affiliatesAction();
        }
    }

    async groupEditAction(request) {

        let self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/groupEdit',
            id: request.id
        });

        if (ret.error) {
            showModalFail(ret.message, 5000);
            return;
        }

        $('#content').html(core.template.render(core.template.admin.groups.group_edit, ret));

        var $form = $('#' + ret.form.id);
        core.libs.form.handle($form, ret, function (data) {
            (async() => {
                var ret = await core.libs.ws.sendAndWait({
                    action: 'admin/groupEdit',
                    data
                });

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }

                if (ret.success) {
                    runActionUrl('admin/groups');
                }
            })();
        });
    }

    async groupJoinsAction(request) {

        let self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/groupJoins',
            id: request.id
        });

        $('#content').html(core.template.render(core.template.admin.groups.group_joins, {joins: ret.joins, group_id: request.id}));
    }

    async loadUsersAction(request) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/getUsers',
            id: request.id
        });

        $(document).find('#group-users-modal').html(core.template.render(core.template.group.admin_groups_users, {
            users: ret.users, group_id: request.id
        }));

        $('#predef_modal').find('.btn.button-1').hide();
    }

    async groupMembersAction(request) {

        let self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/groupMembers',
            id: request.id
        });

        $('#content').html(core.template.render(core.template.group.admin_group_members, {members: ret.members, group_id: request.id, show_add_member_button: true}));

        $('.add-group-member').click(async function () {
            var html = core.template.render(core.template.group.user_groups);
            var modal = showModal(
                    core.lang.get('common.users'),
                    html,
                    [
                        {label: core.lang.get('common.close'), class: 'btn-secondary', close: true, onclick: function () {
                                self.groupMembersAction({id: request.id})
                            }},
                        {label: core.lang.get('group.join'), class: 'btn-primary', onclick: function () {

                                var selected_user_space = $(document).find('#group-users-modal .selected-user-space').val();

                                if (!selected_user_space.length) {
                                    $(document).find('#group-users-modal .selection-error').show();
                                    return false;
                                } else {
                                    runAction({action: 'admin/addGroupMember', user_id: app.user.id, group_id: request.id, company_id: selected_user_space});
                                    modal.hide();
                                }
                            }},
                    ],
                    false,
                    {width: 1000});

            $(modal._element).find('.btn.button-1').hide();

            self.loadUsersAction(request);
            self.groupMembersAction({id: request.id})
        });

        $(document).on('keyup', '#group-users-modal .search-input', function () {
            var parent = $(this).parents('#group-users-modal');
            var value = $(this).val().toLowerCase();

            $(parent).find(".users-list tr").filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
            });
        });
    }

    async addGroupMemberAction(request) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'group/join',
            user_id: request.user_id,
            group_id: request.group_id,
            company_id: request.company_id,
            auto_accepted: true
        });

        this.groupMembersAction({id: request.group_id})
    }

    async userSpacesAction(request) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/userSpaces',
            user_id: request.user_id
        });

        if (ret.spaces.length) {
            if (ret.spaces.length == 1) {
                this.addGroupMemberAction({
                    user_id: request.user_id,
                    group_id: request.group_id,
                    company_id: ret.spaces[0].id
                });

                this.groupMembersAction({id: request.group_id});
                hideModal();

            } else {
                $(document).find('#group-users-modal').html(core.template.render(core.template.group.spaces, {
                    spaces: ret.spaces, user_id: request.user_id, group_id: request.group_id, show_back_button: true,
                    action: 'admin/addGroupMember', button_text: core.lang.get('group.add')
                }))

                this.groupMembersAction({id: request.group_id});


                $('#predef_modal').find('.btn.button-1').show();

                var user_space = $(document).find('#group-users-modal .user-space');
                var selected_user_space = $(document).find('#group-users-modal .selected-user-space');
                var selection_error = $(document).find('#group-users-modal .selection-error');

                user_space.click(function () {
                    var this_element = $(this);
                    user_space.removeClass('space-selected');
                    this_element.addClass('space-selected');
                    var space_id = this_element.data('company_id');
                    selection_error.hide();
                    selected_user_space.val(space_id);
                });

            }

            $(document).find('#group-users-modal .selection-error').hide();
        } else {
            $(document).find('#group-users-modal .selection-error').show();
        }


    }

    async acceptGroupJoinAction(request) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/acceptGroupJoin',
            user_id: request.user_id,
            group_id: request.group_id,
            company_id: request.company_id
        });

        if (ret.accepted) {
            this.groupMembersAction({id: request.group_id});
        }
    }

    async deleteGroupMemberAction(request) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'group/unjoin',
            user_id: request.user_id,
            group_id: request.group_id
        });

        this.groupMembersAction({id: request.group_id});
    }

    // temporary client search action
    async clientSearchAction(request) {
        var url_params = getUrlParams('clientSearch/query');
        var email_input = '';
        var url_input = '';

        if (url_params) {
            if (url_params.email)
                email_input = url_params.email;
            if (url_params.url)
                url_input = url_params.url;
        }

        $('#content').html(core.template.render(core.template.admin.client_search, {email_input: email_input, url_input: url_input}));

        if (url_params) {
            if (url_params.email)
                email_input = url_params.email;
            if (url_params.url)
                url_input = url_params.url;

            var ret = await core.libs.ws.sendAndWait({
                action: 'admin/clientSearch',
                email: email_input,
                url: url_input
            });

            var clients_found = ret.clients.length ? true : false;

            $('#admin-client-search .client-search-result-box').html(core.template.render(core.template.admin.client_search_result, {clients_found: clients_found, clients: ret.clients}));
        }

        var email_search_input = $('.client-search-box .email-input');
        var url_search_input = $('.client-search-box .url-input');
        var do_client_search = $('.client-search-box .do-client-search');

        do_client_search.on('click', function () {
            var query = '';
            var email_search_input_value = email_search_input.val();
            var url_search_input_value = url_search_input.val();

            if (email_search_input_value.length) {
                query += 'email/' + email_search_input_value + '/';
            }

            if (url_search_input_value.length) {
                query += 'url/' + url_search_input_value + '/';
            }

            if (email_search_input_value.length || url_search_input_value) {
                runActionUrl('admin/clientSearch/query/' + query);
            }
        })
    }

    async addCreditsAction(request) {
        if (request.id && request.cid) {
            var ret = await core.libs.ws.sendAndWait({
                action: 'admin/addClientCredits',
                client_id: request.id,
                company_id: request.cid
            });

            var can_add_client_credits = false;

            /*if (ret.client.invoice_company_name &&
                    ret.client.invoice_company_name.length &&
                    ret.client.invoice_street &&
                    ret.client.invoice_street.length &&
                    ret.client.invoice_zipcode &&
                    ret.client.invoice_zipcode.length &&
                    ret.client.invoice_city &&
                    ret.client.invoice_city.length &&
                    ret.client.invoice_nip &&
                    ret.client.invoice_nip.length) {
                can_add_client_credits = true;
            }*/

            can_add_client_credits = true;

            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }

            $('#content').html(core.template.render(core.template.admin.add_client_credits, {form: ret.form, client: ret.client, can_add_client_credits: can_add_client_credits}));

            var $form = $('#' + ret.form.id);
            core.libs.form.handle($form, ret, function (data) {
                data.client_id = request.id;
                data.company_id = request.cid;

                let html = core.template.render(core.template.admin.confirm_add_credits, {});
                let modal = showModal(core.lang.get('user.confirm_add_credits'), html, [
                    {label: core.lang.get('common.confirm'), class: 'btn btn-primary', onclick: function () {

                            (async() => {
                                var ret2 = await core.libs.ws.sendAndWait({
                                    action: 'admin/addClientCredits',
                                    data
                                });

                                if (ret2.errors) {
                                    core.libs.form.showErrors($form, ret2);
                                    $('#predef_modal').modal('hide');
                                }

                                if (ret2.success) {
                                    $('#predef_modal').modal('hide');
                                    runActionUrl('admin/creditsAdded');
                                }
                            })();

                        }},
                    {label: core.lang.get('common.cancel'), class: 'btn btn-sm btn-secondary', onclick: function () {
                            $('#predef_modal').modal('hide')
                        }}

                ], true, {width: 800});
            });
        }
    }

    async calculateClientCreditsAction() {

        var number_of_credits = 0;
        var affiliation_code = $('#field_admin_add_client_credits_affiliation_code input[name="affiliation_code"]').val();
        var current_currency = $('#field_admin_add_client_credits_currency select[name="currency"]').val();
        var current_credit_value = await app.invoice.getCurrentCreditValueForCurrency(current_currency);
        var transfer_amount = parseFloat($('#field_admin_add_client_credits_transfer_amount input[name="transfer_amount"]').val());

        if (transfer_amount) {
            number_of_credits = (transfer_amount / parseInt(current_credit_value.credit_unit_value) * 100);

            if (affiliation_code.length) {
                var ret = await core.libs.ws.sendAndWait({
                    action: 'admin/checkAffiliationCode',
                    code: affiliation_code
                });

                if (ret.success && ret.code_found && !ret.is_out_of_time_range) {
                    var credits_with_discount = parseInt(number_of_credits * ret.code.credits_discount / 100);
                    number_of_credits = number_of_credits + credits_with_discount;
                }
            }

            number_of_credits = parseInt(number_of_credits);

            $('#add-client-credits-form .credits-calculation-result').html(core.template.render(core.template.admin.calculated_client_credits, {number_of_credits: number_of_credits}));
        }
    }

    async creditsAddedAction(request) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'admin/creditsAdded'
        });

        $('#content').html(core.template.render(core.template.admin.credits_added, {clients_credits_added: ret.clients_credits_added}));
    }

}

app.admin = new Admin();

