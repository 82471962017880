libsPromise.then(() => {
    Mustache.Formatters.currency = function(value, rate) {
        let ret = (Math.round(value / rate * 100) / 100) + "";
        let tmp = ret.split('.');
        if (tmp.length == 1) {
            ret += '.00';
        } else
        if (tmp[1].length == 1) {
            ret += '0';
        }
        return ret;
    }
});