/*
 * This is beginning of Js for frontend, before any module is loaded.
 */

// All core controllers
const core = { libs: { } };

// All app modules
const app = { };
const orm = { };

var libsResolve;
const libsPromise = new Promise((resolve, reject) => {
    libsResolve = resolve;
});