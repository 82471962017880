
class ChatPanel {
    async init(user, chats, onchat, onmessage) {
        this.user = user;
        this.users = { };
        this.chats = { };
        this.onchat = onchat;
        this.id_chat = 0;
        
        this.$chatpanel = $('<div id="chatpanel"></div>');

        this.$chats = $(`<div class='chats'></div>`);
        this.$chatpanel.append(this.$chats);

        this.$chat = $(`<div class='chat'></div>`);
        this.$chatpanel.append(this.$chat);
        
        for(var i in chats) {
            this.addChat(chats[i]);
        }
        
        this.renderChats();

        $('#chat_panel_container').html(chats.length ? this.$chatpanel : '<h1>' + core.lang.get('chat.no_chats') + '</h1>');
    }
    
    addChat(chat) {
        this.chats[chat.id] = chat;
    }
    
    renderChats() {
        var p1 = [];
        var p2 = [];
        var p3 = [];
        for(var i in this.chats) {            
            var cl = [];
            
            // Is it the message to us?
            if (this.chats[i].id_user_a == this.user.id && this.chats[i].last_message == 1 || this.chats[i].id_user_b == this.user.id && this.chats[i].last_message == 0) {
                cl.push('received');
                // Do we already responded on any previous messsage?
                if (this.chats[i].responded) {
                    cl.push('responded');
                    p1.push({ time: this.chats[i].responded, i})
                } else {
                    p2.push({ time: this.chats[i].responded, i})
                }
            } else {
                // We sent the last nessage
                p3.push({time: this.chats[i].responded, i})
                cl.push('sent');
            }
            
            if (this.chats[i].id_user_a == this.user.id && this.chats[i].a_read == 0 ||
                        this.chats[i].id_user_b == this.user.id && this.chats[i].b_read == 0 ) { 
                    
                if (this.id_chat == this.chats[i].id) {
                    (async() => {
                        await core.libs.ws.sendAndWait({
                            action: 'chat/setRead',
                            id_chat: this.id_chat,
                        });
                    })();
                } else {
                    cl.push('not_read');
                }
            }
            
            this.chats[i].cl = cl.join(' ');
        }
        
        p1 = p1.concat(p2, p3);
        this.$chats.html('');
        for(var i in p1) {
            var message = this.chats[p1[i].i];
            if (!this.users[message.id]) {
                if (message.user) {
                    this.users[message.id] = new orm.user(message.user);
                } else {
                    var id = message.id_user_a == this.user.id ? message.id_user_b : message.id_user_a;
                    var name = message.id_user_a == this.user.id ? message.b_name : message.a_name;
                    var email = message.id_user_a == this.user.id ? message.b_email : message.a_email;
                    this.users[message.id] = { id, name, email };
                }
            }
            
            var user = new orm.user(this.users[message.id]);
            this.$chats.find(`[data-chat-id='${message.id}']`).remove();
            
            var self = this;
            // <span>${core.lang.get('chat.inactive')}</span>
            var $chat = $(`<div data-user-id='${user.id}' data-chat-id='${message.id}' class='chat ${message.cl} off'>${user.avatar()}<div><b>${user.name}</b><br />${user.email}</div></div>`).click(function() {
                self.id_chat = $(this).data('chat-id');
                self.id_user = $(this).data('user-id');
                $(this).parent().find('.active').removeClass('active');
                $(this).addClass('active');
                if ($(this).hasClass('not_read')) {
                    (async() => {
                        await core.libs.ws.sendAndWait({
                            action: 'chat/setRead',
                            id_chat: $(this).data('chat-id'),
                        });
                    })();
                    $(this).removeClass('not_read');
                }
                self.onchat($(this).data('chat-id'));                
            });
            
            if (self.id_chat == message.id) {
                $chat.addClass('active');
            }
            
            this.$chats.append($chat);
        }
    }
}

class ChatWindow {
    async init($el, user, messages, chat, onmessage, close_button = false, insert_buttons = {} ) {
        var self = this;
        this.user = new orm.user(user);
        this.id_chat = chat ? chat.id : 0;
        
        $el.find('#chat').remove();
        this.$chat = $('<div id="chat"></div>');

        this.$userdata = $(`<div class='grid-avatar userdata'><div>${core.lang.get('chat.private_chat_with')}</div><div>${user.avatar()}</div><div>${user.name}</div></div>`);
        this.$chat.append(this.$userdata);

        this.$history = $(`<div class='history'></div>`);
        this.$chat.append(this.$history);

        this.$text = $(`<textarea></textarea>`);
        this.$message = $(`<div class='message'></div>`);
        this.$message.append(this.$text);

        this.$button = $(`<button class='btn btn-primary mb-1'>${core.lang.get('chat.send')}</button>`).click(async () => {
            var message = { created: Date.now() / 1000, message: this.$text.val() };
            if (message.message.length > 0){
                await onmessage(message);
                this.$text.val('');
            }
        });
        
        this.$text.keypress(function(e) {
            if (e.key === 'Enter' || e.keyCode === 13) {
                self.$button.click();
                return false;
            }
        });

        if (close_button) {
            /*this.$close_button = $(`<button class='btn btn-danger mb-1 me-1'>${core.lang.get('chat.minimize')}</button>`).click(async () => {
                this.$chat.parent().fadeOut();
            });*/
            
            this.$message.append(this.$close_button);
        }

        this.$message.append(this.$button);
        this.$chat.append(this.$message);

        for (var k in insert_buttons) {
            var button = $(`<button class='btn btn-success mb-1 ms-1'>${insert_buttons[k]}</button>`).click(async () => {
                var message = { created: Date.now() / 1000, message: this.$text.val() ? this.$text.val()+' '+k : k };
                await onmessage(message);
                this.$text.val('');
            });
            this.$message.append(button);
        }
        
        this.addMessages(messages, chat);
        $el.append(this.$chat);
    }

    addMessages(messages, chat) {
        this.$history.html('');
        for(var i in messages) {
            this.addMessage(messages[i], chat, false);
        }
        
        setTimeout(() => { 
            this.$history.scrollTop(999999);
        }, 100);
    }

    addMessage(message, chat, scroll = true) {
//        console.log(message.user);
//        if (!message.user) {
//            message.user = this.user;
//        }
        
        var chat_user;

        if (this.$history) {            
            if (chat) {
                message = Object.assign(message, chat);
            }
            
            if (chat.id == this.id_chat) {
                var time = new Date(parseInt(message.created) * 1000);
                if (!time) {
                    time = new Date(parseInt(message.created));
                }
                
                var $send;
                
                //   ${message.id_user_sender}
                console.log(message.id_user_sender, this.user.id);
                
                if (message.id_user_sender != this.user.id) {
                    chat_user = new orm.user(app.user);
                    $send = $(`<div class='send' title='${time.toLocaleString()}'><div></div><span>${message.message}</span> ${chat_user.avatar()}</div>`);
                } else {                    
                    chat_user = this.user;
                    $send = $(`<div class='received' title='${time.toLocaleString()}'>${chat_user.avatar()} <span>${message.message}</span><div></div></div>`);
                }

                this.$history.append($send);
                
                if (scroll) {
                    setTimeout(() => { 
                        this.$history.scrollTop(999999)
                    }, 4);
                }
            }
            
            this.$chat.parent().fadeIn();
        }
    }
}
