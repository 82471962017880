class Chat {

    constructor() {
        this.chats = {};
        this.chat_to_id_user;
        this.chat = new ChatWindow();
        
        this.ir = 0;
    }
    
    async setAction(data) {
        this.has_chats = data.has_chats;
    }
    
    onLayoutReady() {
        if (this.has_chats) {
            $('.button_chat').removeClass('hide');
        } else {
            $('.button_chat').addClass('hide');
        }
        
        $('.online').click(async() => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'company/online',
            });
            
            if (ret.online) {
                $('.online').removeClass('off');
            } else {
                $('.online').addClass('off');
            }
        });
    }
    
    async showAction(request) {
        if (app.user.groups.logged) {
            var chat = await core.libs.ws.sendAndWait({
                action: 'chat/chat',
                id_company: request.id_company,
                get_history: true,
                get_user: true,
            });

            request.$el = $('#chat_on_body');
            request.$el.toggle();

            let user = new orm.user({ name: 'qwe', avatar: () => { return "ava"; } });
            let company_user = new orm.user(chat.chat_user);

            this.chat_window = new ChatWindow();
            this.chat_window.init(request.$el, company_user, chat.messages, chat.chat, async function(message) {
                message.panel = false;
                $('.chat_message_info').show();
                var ret = await core.libs.ws.sendAndWait({
                    action: 'chat/send',
                    id_chat: chat.chat.id,
                    panel: true,
                    message
                });
            }, false, {
                /*'[![meeting_url]!]': core.lang.get('chat.insert_meeting_link')*/
            });
        } else {
            showModal(
                core.lang.get('company.chat'),
                core.template.render(core.template.chat.not_logged_info, {}),
                undefined,
                true, 
                {width: 750}
            );
        }
    }
    
    async _showAction(data) {
        if (data) {
            this.show_data = data;
        } else {
            data = this.show_data;
        }
        
        var modal;

        if (!app.user.name || !app.user.email) {
            var ret = await core.libs.ws.sendAndWait({
                action: 'chat/show',
                id_receiver: data.id_receiver,
            });

            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }

            var html = core.template.render(core.template.chat.form, { form: ret.form, values: { name: this.name, email: this.email } });
            
            modal = showModal(
                core.lang.get('company.contact_with') + ' ' + data.company.name, 
                html, 
                undefined,
                true, 
                {width: 750}
            );
    
            var mode = 0;
            $(modal._element).find('input[type="radio"]').change(function() {
                if ($(this).val() == 0) {
                    mode = 0;
                    $('#field_chat_user_data_name').val('');
                    $('#field_chat_user_data_email').val('');
                    $('#field_chat_user_data_code').parents('.input-box').slideDown();
                    $('#field_chat_user_data_name').parents('.input-box').hide();
                    $('#field_chat_user_data_email').parents('.input-box').hide();
                } else {
                    mode = 1;
                    $('#field_chat_user_data_code').val('');
                    $('#field_chat_user_data_name').parents('.input-box').slideDown();
                    $('#field_chat_user_data_email').parents('.input-box').slideDown();
                    $('#field_chat_user_data_code').parents('.input-box').hide();
                }
                
                $(modal._element).find('input').keyup();
            });
            
            $(modal._element).find('#provide_name').change();
            
            $(modal._element).find('input').keyup(function() {
                $(this).parents('.modal-body').find('button').attr('disabled', true);
                if (mode == 1) {
                    if ($('#field_chat_user_data_name').val() != '' && $('#field_chat_user_data_email').val() != ''){
                        $(this).parents('.modal-body').find('button').removeAttr('disabled');
                    }
                } else {
                    if ($('#field_chat_user_data_code').val() != '') {
                        $(this).parents('.modal-body').find('button').removeAttr('disabled');
                    }
                }
            });

            var $form = $('#' + ret.form.id);
            $(modal._element).find('.submit').click(() => { $form.submit(); });
            
            core.libs.form.handle($form, ret, async(data_form) => {
                var ret = await core.libs.ws.sendAndWait({
                    action: 'chat/show',
                    data: data_form
                });
                
                if (ret.error_message) {
                    $form.parent().find('.alert-danger').html(ret.error_message).slideDown();
                } else {
                    $form.parent().find('.alert-danger').html('').hide();
                }

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }
                
                if (ret.success) {                    
                    app.user.name = ret.name;
                    app.user.email = ret.email;
                    
                    if (ret.code) {
                        modal.setBody(core.template.render(core.template.chat.code_info, { code: ret.code }));
                    } else {
                        if (ret.access_success) {
                            core.libs.ws.sendJson({
                                action: 'user/getAll',
                            });
                        }
                        
                        await this.showAction(data);
                        modal.hide();
                    }
                }
            });
        } else {
            if (modal) {
                modal.hide();
            }
            
            runActionHash('chat/panel');
        }
    }
    
    async receivedAction(data) {
        let shown = false;
        
        if (this.chat_window) {
            if (this.chat_window.id_chat == data.chat.id) {
                this.chat_window.addMessage(data.message, data.chat);
                shown = true;
            }
        } else if (app.group.chat_window) {
            if (app.group.chat_window.id_chat == data.chat.id) {
                app.group.chat_window.addMessage(data.message, data.chat);
                shown = true;
            }
        } else {
            if (this.chat_panel) {
                if (!this.chats[data.chat.id]) {
                    var history = await core.libs.ws.sendAndWait({
                        action: 'chat/history',
                        id_chat: data.chat.id
                    });

                    this.chats[data.chat.id] = history;
                }

                this.chats[data.chat.id].messages.push(data.message);
                this.chat.addMessage(data.message, data.chat);
                shown = true;
            }
        }
                
        if (core.config.performance_test) {
            this.ir++;
            $('.top > div').html('Messages received: '+this.ir);
        }
        
        if (!shown) {
            app.user.chat_notification_count = data.chat_notification_count;
            app.user.updateNotificationDot();
        } else {
            await core.libs.ws.sendAndWait({
                action: 'chat/setRead',
                id_chat: data.chat.id
            });
        }
        
        if (this.chat_panel) {
            this.chat_panel.addChat(data.chat);
            this.chat_panel.renderChats();
            //this.refreshCompanyOnline();
        }
    }
    
    async onlineAction(data) {
        return; 
        if (this.chat_panel.id_user && this.chat_panel.id_user == data.id_user) {
            if (data.online) {
                $('.open_meeting').removeClass('hide');
                hideModal();
                showModal(core.lang.get('common.information'), `<div class='text-center'><b>${data.name}</b><br /><br />`+core.lang.get('company.company_online')+`</div>`);
            } else {
                $('.open_meeting').addClass('hide');
                hideModal();
                showModal(core.lang.get('common.information'), `<div class='text-center'><b>${data.name}</b><br /><br />`+core.lang.get('company.company_offline')+`</div>`);
            }
        }
        
        if (data.online) {
            $(`.chats [data-user-id='${data.id_user}']`).removeClass('off').addClass('on').find('span').html(core.lang.get('chat.active'));
        } else {
            $(`.chats [data-user-id='${data.id_user}']`).removeClass('on').addClass('off').find('span').html(core.lang.get('chat.inactive'));
        }
    }
    
    async onPermissionsChanged () {
        if (await app.page.isFairCompany()) {
            $('body').addClass('fair_company').removeClass('not_fair_company');
            $('.online').removeClass('hide');
            $('.open_meeting').removeClass('hide');
            
            var ret = await core.libs.ws.sendAndWait({
                action: 'company/online',
                get: 1,
            });
            
            if (ret.online) {
                $('.online').removeClass('off');
            } else {
                $('.online').addClass('off');
            }
        } else {
            $('body').removeClass('fair_company').addClass('not_fair_company');
            $('.online').addClass('hide');
            $('.open_meeting').addClass('hide');
            $('.send_meeting').addClass('hide')
        }
        
        if (!app.user.groups.company) {
            //runActionUrl('company/create');
            //return;
        }
    }
    
    async panelAction(data) {

        if (!app.user.groups.logged) {
            runActionUrl('home');
            return;
        }
        
        this.onPermissionsChanged();
        
        $('#content').html(core.template.render(core.template.chat.panel));
            
        
        //await app.page.isFairCompany();
        
        //if (app.page.current_fair.show != '1') {
        //}
        
        $('.open_meeting').remove();
        $('.send_meeting').remove();
       
        var ret = await core.libs.ws.sendAndWait({
            action: 'chat/panel',
            //access: data && data.access && data.access != app.user.tempid ? data.access : false,
            //id_user: !(await app.page.isFairCompany()) && this.show_data ? this.show_data.company.id_user : false,
            //id_user: this.show_data ? this.show_data.company.id_user : false
        });

        if (ret.access_success) {
            core.libs.ws.sendJson({
                action: 'user/getAll',
            });
        }

        if (!ret.chats) {
            runActionHash('home');
            return;
        }
        
        this.chat_panel = new ChatPanel();
        this.chat_panel.init(app.user, ret.chats, async(id_chat) => {
            
            var history = await core.libs.ws.sendAndWait({
                action: 'chat/history',
                id_chat,
                get_history: this.chats[id_chat] ? false : true,
            });

            if (!this.chats[id_chat]) {
                this.chats[id_chat] = history;
            }
            
            /*if (history.meeting_url) {
                $('.open_meeting').off('click').click(() => {
                    window.open(history.meeting_url, '_blank').focus(); 
                });
            }
            
            if (app.page.company) {
                history.meeting_url = app.page.company.meeting_url;
                history.insert_meeting_url = '[![meeting_url]!]';
            }

            if (history.online) {
                if (history.meeting_url) {
                    $('.open_meeting').removeClass('hide');

                    if (history.insert_meeting_url) {
                        $('.send_meeting').removeClass('hide').off('click').click(async () => {
                            var ret = await core.libs.ws.sendAndWait({
                                action: 'chat/send',
                                id_chat,
                                panel: true,
                                message: { created: Date.now(), message: history.insert_meeting_url }
                            });
                        });
                    }
                }
            } else {
                showModal(core.lang.get('common.information'), `<div class='text-center'><b>${history.name}</b><br /><br />`+core.lang.get('company.company_offline')+`</div>`);
                $('.open_meeting').addClass('hide');
            }*/
            
            let user = new orm.user(history.user);
            this.chat.init(this.chat_panel.$chat, user, this.chats[id_chat].messages, this.chats[id_chat].chat, async function(message) {
                message.panel = true;
                $('.chat_message_info').show();
                var ret = await core.libs.ws.sendAndWait({
                    action: 'chat/send',
                    id_chat,
                    panel: true,
                    message
                });
            }, false, {
                /*'[![meeting_url]!]': core.lang.get('chat.insert_meeting_link')*/
            });
        });
        
        /*this.online_all = ret.online_all;
        this.refreshCompanyOnline();*/
        
        if (this.show_data) {
            $(`.chats [data-user-id='${this.show_data.company.id_user}']`).click();
            $('.go_back').off('click').click(() => {
                runActionHash(`fair/show/id/${this.show_data.id_fair}/index/${this.show_data.index}`);
            });
        } else {
            $('.go_back').off('click').click(() => {
                runActionHash(`home`);
            });
        }
    }
    
    refreshCompanyOnline() {
        return;
        for(var i in this.online_all) {
            if (this.online_all[i].online) {
                $(`.chats [data-user-id='${this.online_all[i].id_user}']`).removeClass('off').addClass('on').find('span').html(core.lang.get('chat.active'));;
            } else {
                $(`.chats [data-user-id='${this.online_all[i].id_user}']`).removeClass('on').addClass('off').find('span').html(core.lang.get('chat.inactive'));;
            }
        }
        
    }
    
    async hideChat(data) {
        $('#chat_on_body').remove();
    }
}

app.chat = new Chat();
