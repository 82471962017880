class Stand1 {
    constructor(krpano, pano_id, auto_rotate = 3000) {
        this.krpano = krpano;
        this.pano_id = pano_id;

        // Rotate gallery etc.
        this.auto_rotate = auto_rotate;
        this.tick = 0;
        this.stand_number = 1;
    }

    getHotspots() {
        var self = this;
        var data = this.krpano.embedded[this.pano_id].data;

        var hs_name;
        var hotspots = {};

        if (this.krpano.embedded[this.pano_id].mode == krpano.MODE_PREVIEW) {
            
            
            
            console.log('HOTSPOTS TURNED OFF!!!');
            return;
            
            hotspots.company_name = {
                hs_name: 'company_name',
                html: data.company.name,
                calls: [
                    "assignstyle(hotspot[company_name],'company_name');"
                ],
                ath: 10.5,
                atv: -25.4,
                rx: -25.4,
                ry: -10.5,
                width: 1510,
                height: 140,
                scale: 0.25,
            }

            var contact_html = '';
            if (data.company.address) {
                contact_html += '<b>' + core.lang.get('company.address') + '</b><br />' + data.company.address + '<br /><br />';
            }
            if (data.company.phone) {
                contact_html += '<b>' + core.lang.get('company.phone') + '</b><br />' + data.company.phone + '<br /><br />';
            }
            if (data.company.email) {
                contact_html += '<b>' + core.lang.get('company.email') + '</b><br />' + data.company.email + '<br /><br />';
            }
            if (data.company.www) {
                contact_html += '<b>' + core.lang.get('company.www') + '</b><br />' + data.company.www + '<br /><br />';
            }
            hotspots.contact_data = {
                hs_name: 'contact_data',
                html: contact_html,
                calls: [
                    "assignstyle(hotspot[contact_data],'contact_data');"
                ],
                click: () => {
                    this.krpano.clickContactData(this.pano_id);
                },
                ath: -73.5,
                atv: -4.6,
                rx: -4,
                ry: -20,
                rz: 0,
                width: 630,
                height: 620,
                scale: 0.25,
            }

            hotspots.logo = {
                hs_name: 'logo',
                url: data.company.logo.url,
                ath: -23.8,
                atv: -24.2,
                rx: -23.8,
                ry: 23.8,
                width: 140,
                height: 102,
                scale: 1,
            }
            hotspots.google_map = {
                hs_name: 'google_map',
                url: "public/img/google_map_logo.png",
                click: () => {
                    this.krpano.clickGoogleMap(this.pano_id);
                },
                ath: -110,
                atv: -8,
                ry: 33,
                width: 34,
                height: 40,
                scale: 1,
                tx: 100,
                ty: -20,
            }

            hotspots.open_street_map = {
                hs_name: 'open_street_map',
                url: "public/img/open_street_map_logo.png",
                click: () => {
                    this.krpano.clickOpenStreetMap(this.pano_id);
                },
                ath: -106,
                atv: -8,
                ry: 33,
                width: 34,
                height: 40,
                scale: 1,
                tx: 100,
                ty: -20,
            }

            /*hotspots.contact_form = {
             hs_name: 'contact_form',
             ath: -50,
             atv: 10,
             url: "public/img/contact_form_sm.png",
             }*/

            if (data.products) {
                var tickmod = (2 + this.tick) % Object.keys(data.products).length;
                data.products.forEach(function (key, i) {
                    if (i == tickmod) {
                        hs_name = 'product_center_image';
                        hotspots[hs_name] = {
                            hs_name,
                            url: data.products[i].image.url,
                            ath: -9,
                            atv: -3.3,
                            ry: 11,
                            rz: -0.4,
                            width: 100,
                            height: 70,
                            scale: 1,
                        }

                        hs_name = 'product_center_name';
                        hotspots[hs_name] = {
                            hs_name,
                            html: data.products[i].name,
                            calls: [
                                `assignstyle(hotspot[${hs_name}],'product_name');`
                            ],
                            ath: 7.6,
                            atv: -3.5,
                            rx: -3,
                            ry: -3.5,
                            width: 674,
                            height: 273,
                            scale: 0.25,
                        }
                    }
                });

                var main_product_index = 0;
                data.products.forEach(function (key, i) {
                    switch (data.products[i].type) {
                        case 1: // Main
                            if (main_product_index == 0) {
                                hs_name = 'product_' + i + '_image';
                                hotspots[hs_name] = {
                                    hs_name: hs_name,
                                    url: data.products[i].image.url,
                                    ath: -39.8,
                                    atv: -8,
                                    rx: -8,
                                    ry: 39.8,
                                    width: 100,
                                    height: 70,
                                    scale: 1,
                                }

                                hs_name = 'product_' + i + '_name';
                                hotspots[hs_name] = {
                                    hs_name: hs_name,
                                    html: data.products[i].name,
                                    calls: [
                                        `assignstyle(hotspot[${hs_name}],'product_name');`
                                    ],
                                    ath: -27.9,
                                    atv: -9,
                                    rx: -9,
                                    ry: 27.9,
                                    width: 565,
                                    height: 340,
                                    scale: 0.25,
                                }

                                hs_name = 'product_' + i + '_description';
                                hotspots[hs_name] = {
                                    hs_name: hs_name,
                                    html: data.products[i].description,
                                    calls: [
                                        `assignstyle(hotspot[${hs_name}],'product_description');`
                                    ],
                                    ath: -33.11,
                                    atv: 0.5,
                                    rx: -0.5,
                                    ry: 32.78,
                                    rz: 0.5,
                                    width: 1050,
                                    height: 320,
                                    scale: 0.25,
                                }

                                main_product_index++
                                break;
                            }

                            if (main_product_index == 1) {
                                hs_name = 'product_' + i + '_image';
                                hotspots[hs_name] = {
                                    hs_name: hs_name,
                                    url: data.products[i].image.url,
                                    ath: 25.6,
                                    atv: -9.85,
                                    rx: -9.85,
                                    ry: -26.3,
                                    width: 100,
                                    height: 70,
                                    scale: 1,
                                }

                                hs_name = 'product_' + i + '_name';
                                hotspots[hs_name] = {
                                    hs_name: hs_name,
                                    html: data.products[i].name,
                                    calls: [
                                        `assignstyle(hotspot[${hs_name}],'product_name');`
                                    ],
                                    ath: 38,
                                    atv: -8.6,
                                    rx: -8.6,
                                    ry: -38,
                                    rz: 0,
                                    width: 565,
                                    height: 240,
                                    scale: 0.25,
                                }

                                hs_name = 'product_' + i + '_description';
                                hotspots[hs_name] = {
                                    hs_name: hs_name,
                                    html: data.products[i].description,
                                    calls: [
                                        `assignstyle(hotspot[${hs_name}],'product_description');`
                                    ],
                                    ath: 34.21,
                                    atv: -0.1,
                                    rx: 0.1,
                                    ry: -34.11,
                                    rz: 0,
                                    width: 1040,
                                    height: 360,
                                    scale: 0.25,
                                }

                                main_product_index++
                                break;
                            }
                            break;
                        case 2: // Additional                            
                            break;
                        case 3:
                            // Skip others
                            break;
                    }
                });
            }

            if (data.gallery) {
                var tickmod = this.tick % Object.keys(data.gallery).length;
                Object.keys(data.gallery).forEach((key, i) => {
                    hs_name = 'gallery';

                    if (i == tickmod) { // rotate
                        //console.log(data.gallery[key].url);
                        hotspots[hs_name] = {
                            hs_name,
                            url: data.gallery[key].url,
                            ath: 74.2,
                            atv: -4.4,
                            rx: -4.1,
                            ry: 14,
                            width: 148,
                            height: 150,
                            scale: 1,
                            click: () => {
                                this.krpano.clickGallery(this.pano_id, data.gallery[key]);
                            }
                        };
                    }
                });
            }

            hotspots.documents = [];
            if (data.documents) {
//                Object.keys(data.documents).forEach(function (key, i) {
//                    var hs_name = 'documents_' + i;
//                    hotspots.hs_name = {
//                        hs_name: hs_name,
//                        ath: i * 5 + 40,
//                        atv: i * 6,
//                        html: data.documents[key].name,
//                        calls: [
//                            `assignstyle(hotspot[${hs_name}],'style1');`
//                        ]
//                    };
//                });
            }

            hotspots.video = [];
            if (data.video) {
                var tickmod = this.tick % Object.keys(data.video).length;
                Object.keys(data.video).forEach((key, i) => {
                    if (i == tickmod) { // rotate
                        var hs_name = 'video_name';
                        hotspots[hs_name] = {
                            hs_name,
                            html: data.video[key].name,
                            click: () => {
                                this.krpano.clickVideo(this.pano_id, data.video[key]);
                            },
                            calls: [
                                `assignstyle(hotspot[${hs_name}], 'video_name');`
                            ],
                            ath: 105.6,
                            atv: 1.4,
                            rx: 5,
                            ry: -10,
                            width: 578,
                            height: 180,
                            scale: 0.25,
                        };
                        var hs_name = 'video_image';
                        hotspots[hs_name] = {
                            hs_name,
                            url: data.video[key].icon.url,
                            click: () => {
                                this.krpano.clickVideo(this.pano_id, data.video[key]);
                            },
                            ath: 105.7,
                            atv: -7.3,
                            rx: -4,
                            ry: -20,
                            width: 148,
                            height: 100,
                            scale: 1,
                        };
                    }
                });
            }

            if (this.auto_rotate && !this.auto_rotate_function) {
                this.auto_rotate_function = setInterval(() => {
                    this.autoRotate();
                }, this.auto_rotate);
            }
        }

        return hotspots;
    }

    autoRotate() {
        this.tick++;
        if (this.auto_rotate) {
            this.krpano.renderHotspots(this.pano_id, [
                'gallery',
                'product_center_image',
                'product_center_name',
                'video_name',
                'video_image'
            ]);
        }
    }
}
