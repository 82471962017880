class Document {

    constructor() {
        
    }

    async displayDocumentsAction(data) {
        var self = this;

        (async () => {
            const id_company = data.id_company;
            const id_user = data.id_user;
            
            var ret = await core.libs.ws.sendAndWait({
                action: 'document/getAllDocuments',
                id_user
            });
            
            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }

            var all_documents = [];

            if(ret.documents) {
                ret.documents.forEach(function(doc) {
                    doc.file_name = doc.file.orgname;
                    all_documents.push(doc);
                })
            }

            var upload_available = all_documents.length < core.config.max_document_upload;
            var documents_exist = all_documents.length > 0;

            $('#modal').html(core.template.render(core.template.document.document_list_modal, {documents: all_documents, documents_exist: documents_exist, upload_available: upload_available, title: core.lang.get('document.documents')}));

            var options = {};
            var myModal = new bootstrap.Modal(document.getElementById('visual_form_modal'), options);
            myModal.show();

            $('#visual_form_modal .add-user-document').on('click', function() {
                myModal.hide();
                self.uploadAction({id_company: id_company, id_user: id_user});
            });

            $('#visual_form_modal .edit-user-document').on('click', function() {
                myModal.hide();
                var document_id = $(this).data('document_id');
                self.uploadAction({id_company: id_company, id: document_id });                
            });

        })();

        return false;

    }

    async uploadAction(data) {
        var self = this;

        (async () => {
            const id_company = data.id_company;
            
            var ret = await core.libs.ws.sendAndWait({
                action: 'document/upload',
                id_company,
                id: data.id
            });
            
            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }
            
            ret.form.id = 'visual_company_form';

            var file_type_images = ['image/png', 'image/jpeg', 'image/gif'];

            var file_type_other = {
                'application/pdf': '<i class="far fa-file-pdf fa-4x"></i>',
                'application/msword': '<i class="far fa-file-word fa-4x"></i>',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '<i class="far fa-file-excel fa-4x"></i>',
                'audio/mpeg': '<i class="far fa-file-audio fa-4x"></i>',
                'video/mp4': '<i class="far fa-file-video fa-4x"></i>',
                'text/plain': '<i class="far fa-file-alt fa-4x"></i>'
            }

            if(ret.document.file && file_type_images.includes(ret.document.file.type)) {
                ret.document.file_type_image = true;
            }

            if(ret.document.file && file_type_other.hasOwnProperty(ret.document.file.type)) {
                ret.document.file_type_other = true;
                ret.document.other_file_icon = file_type_other[ret.document.file.type];
            }

            $('#modal').html(core.template.render(core.template.document.document_form, {form: ret.form, values: ret.document, document: ret.document, title: core.lang.get('document.documents')}));

            var options = {};
            var myModal = new bootstrap.Modal(document.getElementById('visual_form_modal'), options);
            myModal.show();

            var $form = $('#' + ret.form.id);

            core.libs.form.handle($form, ret, function (data) {
                (async() => {
                    var ret = await core.libs.ws.sendAndWait({
                        action: 'document/upload',
                        data
                    });

                    if (ret.errors) {
                        core.libs.form.showErrors($form, ret);
                    }

                    if (ret.success) {
                        document.getElementById('visual_form_modal').addEventListener('hidden.bs.modal', function (event) {
                            showModalSuccess(ret.message, 5000);
                        })
                        myModal.hide();

                        app.company.updateKrpano();
                    }
                })();
            });
        })();

        return false;

    }

    async downloadAction(request) {
        var link = document.createElement("a");
        link.setAttribute('download', request.file.orgname);
        link.href = request.file.url;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

}

app.document = new Document();

