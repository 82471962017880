/*
 * Show company
 */

var hs_index = 0;
class Company {
    logged = false;

    constructor() {
        this.selected_space = 0;
        this.current_step = 1;
        this.hotspot_split = '___';

        this.switch_to_xml = true; // change to load changed index.xml

        this.hotspots_actions = {};

        if ('ontouchstart' in document.documentElement || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            window.mobile = true;
            $('body').addClass('mobile-device');
        } else {
            window.mobile = false;
        }
    }

    async setAction(request) {

        this.filled = request.filled ? true : false;

        // List of space langs from db.config
        this.space_langs = request.space_langs;

        runMethod('onCompanyChanged');
    }

    async onTriggerUrlNotFound(data) {
        await this.showAction({ url: data.action });
    }

    async homeAction() {
        var self = this;

        (async () => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'company/companyData'
            });

            var ret2 = await core.libs.ws.sendAndWait({
                action: 'product/getAllProducts'
            });

            $('#content').html(core.template.render(core.template.company.company_form, { form: ret.form, values: ret.company, company: ret.company, checked: ret.company.show_contact_form }));
            var krpano = ret.company ? core.template.render(core.template.company.show, { company: ret.company, gallery: Object.values(ret.company.gallery), products: ret2.products }) : '';
            $('#krpano_container').html(krpano);

            var $form = $('#' + ret.form.id);
            core.libs.form.handle($form, ret, function (data) {
                (async () => {
                    var ret = await core.libs.ws.sendAndWait({
                        action: 'company/companyData',
                        data
                    });

                    if (ret.errors) {
                        core.libs.form.showErrors($form, ret);
                    }

                    if (ret.success) {
                        self.homeAction();
                        showModalSuccess(ret.message, 5000);
                    }
                })();
            });
        })();
    }

    // Add or edit stand for logged users only!
    async editAction(request) {
        let self = this;

        if (!app.user.groups.logged) {
            runActionUrl('company/create');
            return;
        }

        await this.showStudioMenu();

        var params = {
            action: 'company/edit'
        }

        // if new create attempt, check if user can add more spaces
        if (!request.id) {
            var ret2 = await core.libs.ws.sendAndWait({
                action: 'user/checkBusinessPackageDate'
            });

            if (!ret2.can_add_new_spaces) {
                runActionUrl('company/studio');
            }
        }



        if (request.id) params.id = request.id;

        var ret = await core.libs.ws.sendAndWait(params);

        this.companies = { list: ret.companies } // for portals
        this.companies.public_company_exist = this.companies.list.find((c) => c.is_public ) ? true : false;
        this.companies.private_company_exist = this.companies.list.find((c) => !c.is_public ) ? true : false;

        for(let comp of this.companies.list) {
            comp.skip = comp.id == request.id;
        }

        if (ret.error) {
            if (ret.redirect_to_studio) {
                runActionUrl('company/studio');
            } else showModalFail(ret.message, 5000);

            return;
        }

        //$('.studio-items-container').html(core.template.render(core.template.company.edit, ret)).find('.field_edit_company_form_title, .field_edit_company_form_description').hide();

        //ret.values.city = ret.values['city_' + current_selected_lang];
        //ret.values.country = ret.values['country_' + current_selected_lang];
        $('.studio-items-container').html(core.template.render(core.template.company.edit, ret)).find('#field_edit_company_form_title, #field_edit_company_form_description, .btn-hide-seo, [id^="field_edit_company_form_additional_url"], .btn-hide-additional-urls').hide();

        this.company_edit_id = params.id;
        //await this.sendQrcode(false);

        var $form = $('#' + ret.form.id);
        core.libs.form.handle($form, ret, function (data) {
            (async () => {
                var ret = await core.libs.ws.sendAndWait({
                    action: 'company/edit',
                    id: request.id,
                    data
                });

                if (ret.error) {
                    showModalFail(ret.message, 5000);
                }

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                    for(let e in ret.errors) {
                        if (e.substring(0, 15) == 'additional_url_') {
                            $('input[name="'+e+'"]').val('').removeAttr('readonly');
                        }
                    }
                }

                if (ret.success) {
                    //runActionUrl('company/studio');
                    $('.studio-items-container .update-confirmation').show().delay(3000).fadeOut();
                }
            })();
        });

        $form.find('.btn-choose-space').click(function () {
            runAction({ action: 'company/chooseSpace', id_company: request.id })
        })

        $form.find('.btn-fill-data').click(function () {
            runAction({ action: 'company/fillData', id_company: request.id })
        })

        $form.find('.btn-show-seo').click(function () {
            $('.studio-items-container').find('#field_edit_company_form_title, #field_edit_company_form_description').slideDown();
            $form.find('.btn-show-seo').hide();
            $form.find('.btn-hide-seo').show();
        })

        $form.find('.btn-hide-seo').click(function () {
            $('.studio-items-container').find('#field_edit_company_form_title, #field_edit_company_form_description').slideUp();
            $form.find('.btn-show-seo').show();
            $form.find('.btn-hide-seo').hide();
        })

        $form.find('.btn-show-additional-urls').click(function () {
            $('.studio-items-container').find('[id^="field_edit_company_form_additional_url"]').slideDown();
            $form.find('.btn-show-additional-urls').hide();
            $form.find('.btn-hide-additional-urls').show();
        })

        $form.find('.btn-hide-additional-urls').click(function () {
            $('.studio-items-container').find('[id^="field_edit_company_form_additional_url"]').slideUp();
            $form.find('.btn-show-additional-urls').show();
            $form.find('.btn-hide-additional-urls').hide();
        })

        $('#field_edit_company_form_url .prepend').html(core.config.home + '/' + core.lang.language + '/');
        $('#change-status-buttons').html(ret.change_company_status_column_html);

        let lang = self.getLang();
        var options = { id: ret.values.id, company: ret.values, show_modal: false, lang };

        // only if company is edited;
        if (ret.company_edit_mode) {
            $form.find('#field_edit_company_form_lang select[name="lang"]').val(core.lang.language).change(async function () {
                $form.find('.invalid-feedback').hide();
                $form.find('[name]').removeClass('is-invalid');

                var selected_lang = $(this).val();
                $('#field_edit_company_form_url .prepend').html(core.config.home + '/' + selected_lang + '/');
                $('[id^="field_edit_company_form_additional_url"] .prepend').html(core.config.home + '/' + selected_lang + '/');

                let ret = await core.libs.ws.sendAndWait({
                    action: 'company/getCompanyData',
                    data: { id: request.id }
                });

                //var city_input = ret.company['city_' + selected_lang];
                //var country_input = ret.company['country_' + selected_lang];

                //$('#field_edit_company_form_city input[name="city"]').val(city_input);
                //$('#field_edit_company_form_country input[name="country"]').val(country_input).change();

                self.company_shown.lang = self.getLang();
                self.hotspot(self.hotspot_clicked);
                //self.loadCopyButtonAction(request.id, selected_lang);

                //self.preview({ id: ret.values.id, company: ret.values, show_modal: false });
                self.preview(options);
            });

            let tm = false;
            let update = function () {
                clearTimeout(tm); tm = setTimeout(() => { $form.submit(); }, 500);
            }

            let updateWithConfirm = function(el) {
                // var ret2 = await core.libs.ws.sendAndWait({
                //     action: 'company/checkBusinessPackageDate'
                // });

                let cl = true;
                let modal = showModalConfirm(core.lang.get('common.confirm_save'), function () {
                    cl = false;
                    modal.hide();
                    $(el.target).attr('readonly', true);
                    clearTimeout(tm); tm = setTimeout(() => { $form.submit(); console.log('SUBMIT URL'); }, 500); //
                }, false, 'success', core.lang.get('common.yes'));

                $(modal._element).on('hide.bs.modal', function (event) {
                    if (cl) {
                        console.log('CLEAR');
                        $(el.target).val('');
                    }
                });
            }

            $form.find('#field_edit_company_form_name').on('keyup', update);
            $form.find('#field_edit_company_form_logo').on('change', update);
            $form.find('#field_edit_company_form_default_language').on('change', update);
            $form.find('#field_edit_company_form_status').on('change', update);
            $form.find('#field_edit_company_form_industry').on('change', update);
            $form.find('#field_edit_company_form_url').on('change', update);
            $form.find('[id^="field_edit_company_form_additional_url"] input').on('change', updateWithConfirm).each(function() {
                if ($(this).val() != '') {
                    $(this).attr('readonly', true);
                }
            });
            $form.find('#field_edit_company_form_city input').on('keyup', update);
            $form.find('#field_edit_company_form_description input').on('keyup', update);
            $form.find('#field_edit_company_form_title input').on('keyup', update);
            $form.find('#field_edit_company_form_country input').on('keyup', update);
            $form.find('#field_edit_company_form_contact select').on('change', update);

            self.preview(options);
        }

        $(window).scroll(function () {
            /*if ($(window).width() > 1200 && $(window).height() > 660) {
                let pt = $(window).scrollTop() - 50;
                if (pt < 0) { pt = 0; }
                pt += 35;
                $('#hotspot_editor').css('padding-top', pt + 'px');
            } else {
                $('#hotspot_editor').css('padding-top', '35px');
            }*/
        });

        this.updateAvailableLanguages();
    }

    async sendQrcode(url_index = 0) {
        let lang = this.getLang();
        var ret = await core.libs.ws.sendAndWait({
            action: 'company/qrcode',
            id: this.company_edit_id,
            lang,
            url_index
        });

        if (!ret.error) {
            showModal('<h1>'+core.lang.get('company.qrcode')+'</h1>', core.template.render(core.template.company.qrcode, ret), false, true, { width: 600 });
            $('img#qrcode').attr('src', ret.qrcode);
            $('a#qrcode-link').attr('href', 'data:application/octet-stream;'+ret.qrcode); // Force download

            // let download = function(){
            //     var link = document.createElement('a');
            //     link.href = ret.qrcode;
            //     link.download = 'Download.jpg';
            //     document.body.appendChild(link);
            //     link.click();
            //     document.body.removeChild(link);
            // }

            // download();

            var $form = $('#' + ret.form.id);
            core.libs.form.handle($form, ret, async (data) => {
                var ret = await core.libs.ws.sendAndWait({
                    action: 'company/qrcode',
                    id: this.company_edit_id,
                    lang,
                    url_index,
                    data
                });

                if (ret.error) {
                    hideModal();
                    showModalFail(ret.message, 5000);
                }

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }

                if (ret.success) {
                    hideModal();
                    showModalSuccess(core.lang.get('company.qrcode_sent'), 3000);
                }
            });
        }
    }

    async fillDataAction(request, ret = false) {
        request.lang = request.lang || app.company.getLang();
        if (!ret) {
            ret = await core.libs.ws.sendAndWait({
                action: 'company/fillData',
                id: request.id_company,
                lang: request.lang,
                delete_lang: request.delete_lang,
            });
        }

        if (ret.error) {
            if (ret.redirect_to_studio) {
                runActionUrl('company/studio');
            } else showModalFail(ret.message, 5000);

            return;
        }

        //console.log(ret);

        // var buttons = [
        //     { label: core.lang.get('common.cancel'), class: 'btn-secondary', close: true },
        //     {
        //         label: core.lang.get('common.save'), class: 'btn-primary', close: false, onclick: () => {
        //             $('#' + ret.form.id).submit();
        //         }
        //     }
        // ];
        let buttons = [];

        if (request.leave_langs) {
            $('#company_data_form_container').html(core.template.render(core.template.company.fill_data_simple, ret));
            $('#fill_data #field_fill_data_default_language').remove();
            $('#field_fill_data_contact_data').appendTo($('#contact_data_field'));
        } else {
            showModal('<h1>'+core.lang.get('company.your_data')+'</h1>', core.template.render(core.template.company.fill_data, ret), buttons, true, { width: 900 });
            $('#field_fill_data_default_language').appendTo($('#default_lang_field_container'));
            $('#field_fill_data_contact_data').appendTo($('#contact_data_field'));
        }

        $('#field_fill_data_active_checkbox').on('click', function () {
            let default_language = $('#field_fill_data_default_language select').val();
            console.log('Deactivate lang', request.lang , ret.company.default_language, default_language);
            if (request.lang == default_language) { //ret.company.default_language) {
                showModalFail(core.lang.get('company.cant_deactivate_default_language'), 3000, false);
                return false;
            }
            return true;
        })
        $('#field_fill_data_active_checkbox').on('change', function () {
            let ico = $('#available_languages_container [data-lang="' + request.lang + '"] i');
            if ($(this).is(':checked')) {
                $('#field_fill_data_default_language select option[value="' + request.lang + '"]').show();//removeAttr('disabled');
                ico.removeClass('fa-eye-slash').addClass('fa-eye');
            } else {
                $('#field_fill_data_default_language select option[value="' + request.lang + '"]').hide();//attr('disabled', 'disabled');
                ico.addClass('fa-eye-slash').removeClass('fa-eye');
            }

            // Copy langs to back form
            //$('#edit_company_form select[name="lang"]').html($('#field_fill_data_default_language select').html());

            //app.company.updateAvailableLanguages(); ?
        })

        var $form = $('#' + ret.form.id);

        let tm;
        let update = function () {
            clearTimeout(tm); tm = setTimeout(() => { $form.submit(); }, 500);
        }
        $form.find('input,select').on('change', update);
        $('#field_fill_data_default_language select').on('change', update);

        $('#contact_data_container button').off('click').on('click', function () {
            hideModal();
            app.contact.addAction({
                modal: true, no_dataset: false, callback: function () {
                    let modal = bootstrap.Modal.getInstance($('.modal'));
                    $(modal._element).off('hide.bs.modal');
                    hideModal();
                    request.leave_langs = false;
                    setTimeout(function () { app.company.fillDataAction(request); }, 200);
                }
            });

            return false;
        })


        core.libs.form.handle($form, ret, function (data) {
            (async () => {
                data.default_language = $('#field_fill_data_default_language select').val();
                var ret = await core.libs.ws.sendAndWait({
                    action: 'company/fillData',
                    id: request.id_company,
                    lang: request.lang,
                    data
                });

                if (ret.error) {
                    showModalFail(ret.message, 5000);
                }

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }

                if (ret.success) {
                    //runActionUrl('company/studio');
                    $('.studio-items-container .update-confirmation').show().delay(3000).fadeOut();
                    app.company.updateAvailableLanguagesSelect(ret.company);
                    //hideModal();
                }
            })();
        });

        if (!request.leave_langs) {
            this.updateAvailableLanguages(ret.company, ret.langs);
        }

        setTimeout(function () { $('#predef_modal .modal-body').css('opacity', 1); }, 400);
    }

    copyLangsToBackForm(langs) {
        let options = ``, sel = ``;
        let select_lang = this.getLang();
        for (let lang of langs) {
            sel = lang.lang == select_lang ? 'selected="1"' : "";
            options += `<option value='${lang.lang}' ${sel}>${this.space_langs[lang.lang]}</option>`;
        }

        $('#edit_company_form select[name="lang"]').html(options).change();
    }

    async updateAvailableLanguagesSelect(data) {
        for (let lang of data.available_languages) {
            if (!lang.active) {
                $('#field_fill_data_default_language select option[value="'+lang.lang+'"]').addClass('hide');
            } else {
                $('#field_fill_data_default_language select option[value="'+lang.lang+'"]').removeClass('hide');
            }
        }

        this.updateVisibilityAvailableLanguagesSelect();
    }

    updateVisibilityAvailableLanguagesSelect() {
        if ($('#field_fill_data_default_language select option:not(".hide")').length > 1) {
            $('#field_fill_data_default_language select').parent().parent().show();
			$('#default_lang_info_container').show();
        } else {
            $('#field_fill_data_default_language select').parent().parent().hide();
			$('#default_lang_info_container').hide();
        }
    }


    // Update list of languases on the "default space langauge" select
    async updateAvailableLanguages(data, langs, select_lang) {
        if (!select_lang) {
            select_lang = data && data.default_language ? data.default_language : this.getLang();
        }

        if (!data) {
            data = this.company_shown.company;
        }
        if (!langs) {
            langs = data.available_languages;
        }

        // console.log('langs',langs);
        // console.trace();

        let sel = ``, sel2 = ``, options = ``, icon_buttons = ``;
        let options_count = 0, i = 0;
        let fill_data_form = $('#available_languages_container').length;

        for (let lang of data.available_languages) {
            i++;

            if (lang.lang == select_lang) {
                sel = 'selected="1"';
            } else {
                sel = '';
            }

            if (data.data && lang.lang == data.data.lang) {
                sel2 = 'selected'
            } else {
                sel2 = '';
            }

            let ico = lang.active ? ' <i class="fas fa-eye"></i> ' : ' <i class="fas fa-eye-slash"></i> ';
            let sel3 = lang.active ? ' active ' : ' inactive ';
            //let disabled = !lang.active ? ' disabled="disabled" ' : '';
            let disabled = !lang.active ? ' class="hide" ' : '';
            options += `<option ${disabled} value='${lang.lang}' ${sel}>${this.space_langs[lang.lang]}</option>`;
            if (!disabled) {
                options_count++;
            }
            icon_buttons += `<div data-lang="${lang.lang}" class="${sel2} ${sel3}"><span>` + i + '. ' + "<img src='public/flags/" + lang.lang + ".png' /> " + this.space_langs[lang.lang] + ico + '</span></div>';
        }

        // Copy langs to back form
        //$('#edit_company_form select[name="lang"]').html(options).change();
        app.company.copyLangsToBackForm(data.available_languages);

        if (fill_data_form) {
            //console.log('options', options);
            console.log('options_count', options_count);
            $('#field_fill_data_default_language select').html(options);
            this.updateVisibilityAvailableLanguagesSelect();

            $('#available_languages_container .langs > div').html(icon_buttons);

            $('#available_languages_container .langs > div > div').on('click', function () {
                $('#available_languages_container .langs > div .selected').removeClass('selected');
                $(this).addClass('selected');
                app.company.fillDataAction({ id_company: data.id, lang: $(this).data('lang'), leave_langs: true });
                app.company.scrollToSelectedLang();
            });

            $('#available_languages_container button.left').click(function () {
                $('#available_languages_container .langs > div > div.selected').prev().click();
            });
            $('#available_languages_container button.right').click(function () {
                $('#available_languages_container .langs > div > div.selected').next().click();
            });
            $('#available_languages_container button.add').click(function () {
                let modal = showModal(core.lang.get('common.add'), core.template.render(core.template.company.all_languages_chooser, { langs }), null, false, { width: 250 });
                $(modal._element).find('.all_languages_chooser button').on("click", function () {
                    modal.hide();

                    $('#predef_modal .modal-body').css('opacity', 0.4);
                    app.company.fillDataAction({ id_company: data.id, lang: $(this).data('lang') });

                    // Copy langs to back form
                    //app.company.copyLangsToBackForm();
                    //$('#edit_company_form select[name="lang"]').html($('#field_fill_data_default_language select').html());
                });

                return false;
            });
            $('#available_languages_container button.delete').click(function () {
                let selected = $('#available_languages_container .langs > div > div.selected');
                if (selected.siblings().length == 0) {
                    showModalFail(core.lang.get('company.cant_delete_language'));
                } else {
                    let modal = showModalConfirm(core.lang.get('company.confirm_delete_lang'), function () {
                        modal.hide();

                        let goto = selected.next().length ? selected.next().data('lang') : selected.prev().data('lang');

                        $('#predef_modal .modal-body').css('opacity', 0.4);
                        app.company.fillDataAction({ id_company: data.id, delete_lang: selected.data('lang'), lang: goto });

                        // Copy langs to back form
                        $('#edit_company_form select[name="lang"]').html($('#field_fill_data_default_language select').html());
                    }, false);
                }

                return false;
            });
        }

        setTimeout(function () { app.company.scrollToSelectedLang(false); }, 200);
    }

    scrollToSelectedLang() {
        let e = $('#available_languages_container .langs > div .selected');
        if (e.length) {
            e = e.position().left + e.parent().scrollLeft() - e.parent().position().left - 200;
            if (e < 0) { e = 0; }
            $('#available_languages_container .langs > div').animate({ scrollLeft: e + 'px' });
        }
    }

    async loadCopyButtonAction(company_id, current_lang) {

        var to_lang = (current_lang == 'pl' ? 'en' : 'pl').toUpperCase();
        var copy_button_text = core.lang.get('company.copy_to') + ' ' + to_lang;

        /*$('.studio-items-container .box #field_edit_company_form_copy_data').html(core.template.render(core.template.company.copy_button, {
            company_id: company_id, copy_button_text: copy_button_text
        }));*/

        $('.studio-items-container .box #field_edit_company_form_preview_space').html(core.template.render(core.template.company.preview_space, {
            company_id: company_id
        }));
    }

    async changeCompanyStatusAction(request) {
        //
        let modal = showModalConfirm(core.lang.get('company.confirm_change_status'), async function () {
            modal.hide();

            var ret = await core.libs.ws.sendAndWait({
                action: 'company/changeCompanyStatus',
                company_id: request.company_id,
                change_to_status: request.change_to_status
            });

            if (ret.success) {
                $('#change-status-buttons').html(ret.change_company_status_column_html);

                showModalSuccess(ret.message, 5000);
            } else {
                showModalFail(ret.message, 5000);
            }
        }, true, 'red', core.lang.get('company.yes_change_status'));
    }

    // Create stand for not logged users only! 3 steps!
    async createAction() {
        if (!app.user.groups.logged || !app.user.is_regular) {
            runActionUrl('home');
            return;
        }

        let self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/create'
        });

        this.stands = ret.stands;

        if (ret.stands.length) {
            for (let stand of ret.stands) {
               stand.selected_space = self.selected_space == stand.id;
            }
        }

        $('#content').html(core.template.render(core.template.company.create, {
            form: ret.form, values: ret.company, company: ret.company, stands: ret.stands,
            selection_done: self.selected_space > 0, show_step_2: self.current_step == 2, show_step_3: self.current_step == 3
        }));

        //var selected_space = 0;

        var $form = $('#' + ret.form.id);
        core.libs.form.handle($form, ret, function (data) {
            (async () => {
                data.stand = self.selected_space;
                data.lang = core.lang.language;

                var ret = await core.libs.ws.sendAndWait({
                    action: 'company/create',
                    data
                });

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }

                if (ret.success) {
                    app.user.is_regular = false;
                    app.user.groups.company = true;
                    await runMethod('onPermissionsChanged');

                    // $('.create-stand .step2').hide();
                    // $('.create-stand .step3 div.info b').html(data.email);
                    // $('.create-stand .step3').fadeIn();
                    runActionUrl('company/spaces');

                    showModalSuccess(core.lang.get('user.account_type_changed'), 5000);
                    $('.blue-tip').remove();
                }
            })();
        });

        $('.language-change .language-dropdown-item').click(function () {
            core.lang.changeLanguage($(this).data('language'));
            if (self.current_step == 3) runActionUrl('home');

            return false;
        });

        $('.create-stand .step1 [data-space] .btn-primary').click(function () {
            self.selected_space = $(this).parent().data('space');
            $(this).parents('.flex').find('> *').removeClass('selected').addClass('op');
            $(this).parent().parent().addClass('selected');
            $('.step1 .next').removeAttr('disabled');
        });

        $('.step1 .next').click(function () {
            $('.step1').removeClass('current-step').hide();
            $('.step2').addClass('current-step').fadeIn();
            self.current_step = 2;
            sessionRefresh();
        });

        $('.create-stand .step1 .btn-secondary').click(function () {
            let stand = $(this).parent().data('space');
            if (stand < 1) { stand = 1; }
            self.preview({ show_modal: true, company: { stand, config: { hotspots: {} } } });

            //            let modal = showModal(core.lang.get('company.preview'), `<div id="krpanoSWFObject"></div>`, undefined, true, { width: 1200 });
            //            $(modal._element).on('shown.bs.modal', function (event) {
            //                console.log('stand3', stand);
            //                setTimeout(() => {
            //                    self.preview({ company: { stand, config: { hotspots: {} } }});
            //                }, 1000);
            //                $(modal._element).off('shown.bs.modal');
            //            });
        });

        $('.create-stand .step2 input').change(function () {
            if ($('#field_register_email input').val() != '' && $('#field_register_name input').val() != '') {
                $('.create-stand .step2 .next').removeAttr('disabled');
            } else {
                $('.create-stand .step2 .next').attr('disabled', true);
            }
        });

        $('.create-stand .step2 .back-to-step1').click(function () {
            self.current_step = 1;
            $('.step2').removeClass('current-step').hide();
            $('.step1').addClass('current-step').fadeIn();
        })

        $('.create-stand .step2 .next').click(function () {
            self.current_step = 3;
            $form.submit();
        });
    }

    async getStands(force = false) {
        if (!this.stands || force) {
            var ret = await core.libs.ws.sendAndWait({
                action: 'company/getStands'
            });

            this.stands = ret.stands;
            //console.log(this.stands);
        }

        return this.stands;
    }

    /*
     * Shown when user clicks confirmation link on the email
     */
    async createDoneAction() {
        $('#content').html(core.template.render(core.template.company.create_done, {}));
        app.user.force_password_change = true;

        $('.create-stand .step4 button.btn-secondary').click(function () {
            if (!app.user.password_set) {
                app.user.accountPasswordAction('company/studio');
            } else {
                runActionUrl('company/studio');
            }

            return false;
        });

        $('.create-stand .step4 button.btn-primary').click(function () {
            if (!app.user.password_set) {
                app.user.accountPasswordAction('company/studio');
            } else {
                runActionUrl('company/studio');
            }

            return false;
        });

        this.preview();
    }

    /*
     * Show modal with krpano preview
     */
    async preview(options) {
        let edit_mode = current_action.substring(0, 12) == 'company/edit';
        //return;

        if (options == undefined) {
            options = this.company_shown;
        }

        options = options || {};
        options.lang = options.lang || core.lang.language;

        // Get selected variant ID
        if (options.button) {
            let id = parseInt($(options.button).parents('.space-box').find('.variants').val());
            if (id) {
                options.company.stand = id;
            }
        }

        if (!options.company) {
            let ret = await core.libs.ws.sendAndWait({
                action: 'company/getCompanyData',
                data: { id: options.id }
            });

            options.company = ret.company;
        }

        this.company_shown = options;
        //console.log(this.company_shown);

        if (options.show_modal) {
            showModal(this.company_shown.company.name, core.template.render(core.template.company.preview, {}), undefined, true, { width: 1800 });
        } else {
            if ($('.modal #krpanoSWFObject').length) {
                $('.modal #krpanoSWFObject').remove();
            }
        }

        if (!options.krpano) {
            let pano_id = 'krpanoSWFObject'
            let stands = await this.getStands();
            //console.log(options);

            let found = false;
            for (let stand of stands) {
                if ((stand.id == options.company.stand) || (!options.company && options.id == stand.id)) {
                    let stand_index_xml = core.config.home + "/public/upload/stand/" + stand.folder + "indexdata/index.xml";
                    if (!edit_mode && this.switch_to_xml) {
                        // Load with hotspots updated on XML
                        //stand_index_xml = 'indexxml/'+options.company.id+'/'+options.lang;

                        // Load without kme_border script
                        stand_index_xml = core.config.home + "/public/upload/stand/" + stand.folder + "indexdata/index-CLEAN.xml";
                    }

                    console.log('stand.folder', stand_index_xml);
                    //alert('stand.folder '+stand_index_xml);

                    found = true;
                    embedpano({
                        swf: core.config.home + "/public/js/krpano/krpano.swf",
                        id: 'krpano-' + pano_id,
                        xml: stand_index_xml,
                        //xml: core.config.home + "/krpano-stand/" + stand.folder,
                        target: pano_id,
                        consolelog: true,
                        passQueryParameters: true,
                        onready: (krpano) => {
                            //krpano.call("includexml('%FIRSTXML%/../../../../../js/kme_border.xml')");
                            window.krpano = options.krpano = krpano;

                            krpano.set('window_rotate', window.location.href.indexOf('company/edit') == -1);
                            krpano.set('edit_mode', edit_mode);

                            //krpano.set('events.onviewchanged', () => { console.log(123); });
                            krpano.set('events.onloadcomplete', () => { console.log('onloadcomplete'); this.updateKrpanoHotspots(options); });

                            setTimeout(() => {
                            //    this.updateKrpanoHotspots(options);
                            }, 20);

                        },
                        onerror: (krpano) => {
                            console.log('Krpano error');
                        }
                    });

                    break;
                }
            }

            if (!found) {
                console.log('Space with id '+options.company.stand+' not found!');
                console.log(stands);
            }
        } else {
            this.updateKrpanoHotspots(options);
        }
    }

    saveCurrentStand(stand_number) {
        var ret = core.libs.ws.sendAndWait({
            action: 'company/saveCurrentStand',
            current_stand: stand_number
        });
    }

    async updateKrpanoHotspots(options) {
        console.log('updateKrpanoHotspots');
        //console.trace();

        /*let resolution_dummy_image = {
            1: `
            s_1; 700x461
            s_2; 1444x745
            s_3; 1444x745
            s_4; 607x392
            s_5; 607x392
            s_6; 1444x745`,

            2: `
            s_1; 679x447
            s_2; 1515x835
            s_3; 1515x835
            s_4; 617x402
            s_5; 679x447
            s_6; 1515x835`,

            3: `
            ekran_1; 1444x745
            s_1; 1542x771
            s_2; 1444x745
            s_3; 1444x745
            s_4; 1444x745
            s_5; 1542x771
            s_6; 2745x1345
            s_7; 1677x455
            s_8; 1677x871`,

            4: `
            s_1; 2992x1088
            s_2; 1935x1235
            s_3; 1935x1235
            s_4; 1048x770
            s_5; 770x770
            s_6; 1639x937
            s_7; 1639x937
            s_8; 1048x770
            s_9; 770x770`,

            5: `
            s_1; 1281x723
            s_2; 725x179
            s_3; 725x519
            s_4; 1444x745
            s_5; 576x576
            s_6; 1444x745
            s_7; 576x576`
        };*/

        if (options == undefined) {
            options = this.company_shown;
        }

        /*let dummy = [];
        if (resolution_dummy_image[options.company.stand]) {
            dummy = resolution_dummy_image[options.company.stand].split("\n");
        }*/

        //console.log('dummy', dummy);

        let progress = 0;
        let krpano = options.krpano;

        let edit_mode = current_action.substring(0, 12) == 'company/edit';
        if (krpano) {

            let hotspots = {};
            options.portals = [];

            var ev = krpano.get('event.count');
            var hotspot_count = krpano.get('hotspot.count');
            //console.log('hotspot_count', hotspot_count);

            if (hotspot_count) {
                for (var i = hotspot_count - 1; i >= 0; i--) {
                    var hs_name = krpano.get('hotspot[ ' + i + '].name');
                    //console.log('hs_name', hs_name);

                    //krpano.call("assignstyle(hotspot["+hs_name+"],'mystyle');");

                    var onclick = krpano.get('hotspot[ ' + i + '].onclick');

                    if (onclick == 'picture_onclick') {
                        onclick = krpano.get('hotspot[ ' + i + '].backup_onclick');
                    }

                    var action = krpano.get('action[' + onclick + ']');
                    var action_data = false;


                    //console.log(hs_name, krpano.get('action[' + onclick + ']'));

                    if (action && action.content) {
                        let is_portal = false;
                        this.hotspots_actions[action.name] = action.content;

                        let js = action.content.indexOf('metajarHotspotAction') !== false ? action.content.match(/{.*}/) : false;
                        if (js && js[0]) {
                            eval('action_data = ' + js[0] + ';');

                            if (action_data) {
                                hotspots[hs_name] = { i, hs_name, id: action_data.id, action_data };

                                let hs_type = action_data.id.split(app.company.hotspot_split)[0];
                                if (hs_type.indexOf('portal') === 0) {
                                    options.portals.push(hs_type);
                                }
                                //console.log('hs_name', hs_name, 'id', action_data.id);
                            } else {
                                console.log(`Error hotspot "${hs_name}" action data parse!`);
                            }
                        }

                        if (action.content.indexOf('metajarHotspotAction') !== -1) {
                            //krpano.set('hotspot[ ' + i + '].url', core.config.home + '/public/img/white_pixel.png');
                        }
                    }

                    if (options.company.logo && options.company.logo.url) {

                    }
                }

                if (!options.default_hotspots) {
                    options.default_hotspots = {};
                }

                if (!options.default_hotspots[options.company.stand]) {
                    options.default_hotspots[options.company.stand] = {};
                }

                console.log('Edit mode', edit_mode);
                console.log('Mobile view', window.mobile);
                //alert("Mobile "+(window.mobile ? 'Yes' : 'no') + ', edit mode '+(edit_mode ? 'Yes' : 'No'));

                let count_set = 0;
                for (let hs_name in hotspots) {
                    let hotspot = hotspots[hs_name];

                    if (!options.default_hotspots[options.company.stand][hs_name]) {
                        options.default_hotspots[options.company.stand][hs_name] = { url: krpano.get('hotspot[ ' + hotspot.i + '].url') };
                    }

                    let set = false;

                    // Check all languages to count the progress.
                    for (let lang in this.space_langs) {

                        if (options.company.config.hotspots[lang]) {
                            for (let hi in options.company.config.hotspots[lang]) {
                                hi = hi.split(app.company.hotspot_split)[0];

                                let hid = hotspot.action_data.id.split(app.company.hotspot_split)[0];
                                //console.log('####', hs_name, lang, hi, hotspot.action_data.id);

                                if (hi == hid) {
                                    let hset = options.company.config.hotspots[lang][hi] ? options.company.config.hotspots[lang][hi] : options.company.config.hotspots[lang][hotspot.action_data.id];

                                    if (lang == options.lang) {
                                        // console.log('AAA', hi, options.company.config.hotspots[lang]);
                                        // console.log('---- configured '+options.lang, hotspot.i, hset);

                                        if (hset && hset.image && hset.image.url) {
                                            let image_url =  window.mobile ? hset.image.mobile_url : hset.image.url;

                                            console.log('hotspot[' + hotspot.i + '].url '+hs_name, image_url);
                                            krpano.set('hotspot[' + hotspot.i + '].url', image_url);

                                            //krpano.set('hotspot[' + hotspot.i + '].width', 'prop');
                                            //krpano.set('hotspot[' + hotspot.i + '].height', 'prop');
                                            krpano.set('hotspot[' + hotspot.i + '].scalemode', "resize");
                                            set = true;
                                            count_set++;
                                        } else {

                                        }

                                        if (hi.indexOf('portal') == 0) {
                                            console.log('portal hotspot set', hset);
                                            if (!hset.action.portal_company_is_public && options.company.id_user != app.user.id && !edit_mode) {
                                                console.log('removehotspot 1', hs_name);
                                                krpano.call("removehotspot('"+ hs_name + "')");
                                            } else {
                                                // krpano.set('hotspot[' + hotspot.i + '].url', core.config.home + '/public/img/portal-icon-296-296.png'); // Fixed cover image.
                                                // krpano.set('hotspot[' + hotspot.i + '].scalemode', "resize");
                                                // set = true;
                                            }
                                        }
                                    }

                                    if (hset && hset.image && hset.image.url) {
                                        progress++;
                                    } else {
                                        if (!edit_mode) {
                                            console.log('removehotspot 2', hs_name);
                                            krpano.call("removehotspot('"+ hs_name + "')");
                                        }
                                    }

                                    if (hset && hset.action && hset.action.id) {
                                        progress++;
                                    }
                                } else {

                                }
                            }
                        }
                    }

                    if (!set) {
                        //krpano.set('hotspot[ ' + hotspot.i + '].url', options.default_hotspots[options.company.stand][hs_name].url);
                        let filename = hotspot.id.split(app.company.hotspot_split);

                        if (filename[0].indexOf('portal') === 0) {
                            if (edit_mode) {
                                if (filename.length >= 2) {
                                    filename = filename[1];
                                    krpano.set('hotspot[' + hotspot.i + '].url', core.config.home + '/public/dummy_images/' + core.lang.language + '_' + filename);
                                } else {
                                    krpano.set('hotspot[' + hotspot.i + '].url', core.config.home + '/public/img/portal-icon-296-296.png'); // Fixed cover image.
                                }
                            }

                            krpano.set('hotspot[' + hotspot.i + '].scalemode', "resize");
                        } else {
                            if (edit_mode) {
                                console.log('Set dummy image for ' + hotspot.id + ':');
                                if (filename.length >= 2) {
                                    filename = filename[1];
                                    krpano.set('hotspot[' + hotspot.i + '].url', core.config.home + '/public/dummy_images/' + core.lang.language + '_' + filename);
                                } else {
                                    console.log('No dummy file set for hotspot '+hotspot.id);
                                }
                            } else {
                                console.log('removehotspot 3', hs_name);
                                //krpano.call("removehotspot('"+ hs_name + "')");
                            }
                        }
                    }
                }

                //console.log(hotspots);

                let pr = options.company.completed;
                if (pr) {
                    $('#space-progress > div').css('width', (pr) + '%').html(core.lang.get('company.completed') + ' <b>' + Math.round(pr) + '%<br />');
                    if (($('#space-progress').width() * pr / 100) < 100) {
                        $('#space-progress > div').css('color', '#444');
                    } else {
                        $('#space-progress > div').css('color', 'white');
                    }
                } else {
                    $('#space-progress').hide();
                }

                return true;
            } else {
                //                setTimeout(() => {
                //                    this.updateKrpanoHotspots(options);
                //                }, 20);
            }

            //console.log(options.portals);
        }


    }

    // Return lang for krpano, can be from edit mode or from layout
    getLang() {
        return $('#field_edit_company_form_lang').length ? $('#field_edit_company_form_lang select').val() : (this.company_shown.lang ? this.company_shown.lang : core.lang.language);
    }

    async pdfAction(data) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'company/getLibraryFileUrl',
            area: 'library',
            name: 'pdfs',
            id: data.id
        });

        if (ret.file) {
            window.open(ret.file.url);
        }
    }

    // Callback when hotspot is clicked on the krpano
    async hotspot(data) {
        if (data) {
            this.hotspot_clicked = data;
            let lang = this.getLang();

            let image = false, action = false;

            // hack for borders on hotspots
            if (data.hs_name) {
                data.hs_name = 'onclick'+data.hs_name; // hs_name - set in kme_border.xml
                if (this.hotspots_actions[data.hs_name]) {
                    let action_content = this.hotspots_actions[data.hs_name];
                    data.id = this.hotspots_actions[action.name];
                    let action_data;
                    let js = action_content.indexOf('metajarHotspotAction') !== false ? action_content.match(/{.*}/) : false;
                    if (js && js[0]) {
                        eval('action_data = ' + js[0] + ';');
                    }

                    //action_data.id = action_data.id.split(app.company.hotspot_split)[0];
                    this.hotspot_clicked = data = action_data;
                }
            }

            this.hotspot_clicked.id = this.hotspot_clicked.id.split(app.company.hotspot_split)[0];

            let id = this.hotspot_clicked.id;
            let is_portal = id.indexOf('portal') == 0;

            if (this.company_shown.company.config.hotspots[lang] && this.company_shown.company.config.hotspots[lang][id]) {
                action = this.company_shown.company.config.hotspots[lang][id].action;
                image = this.company_shown.company.config.hotspots[lang][id].image;
            }

//console.log(id);
//console.log(this.company_shown.company.config.hotspots[lang]);

            console.log('hotspot clicked', this.hotspot_clicked.id, id);
            console.log('action', action);
            console.log('image', image);

            if (current_action.substring(0, 12) == 'company/edit') {
                data.library_file_categories = app.library.library_file_categories;
                var empty_config = Object.keys(this.company_shown.company.config.hotspots).length == 0;

                if (is_portal) {
                    for(let comp of this.companies.list) {
                        comp.action_extra_class = comp.id == action.id ? 'selected' : '';
                    }

                    this.companies.list = this.companies.list.sort((a, b) => a.name < b.name ? -1 : 1);
                    this.companies.list = this.companies.list.sort((a, b) => a.action_extra_class > b.action_extra_class ? -1 : 1);
                }

                for (let i in data.library_file_categories) {
                    if (empty_config) {
                        if (data.library_file_categories[i].name == 'blank') {
                            data.library_file_categories[i].image_extra_class = `selected`;
                        }

                        if (data.library_file_categories[i].name == 'none') {
                            data.library_file_categories[i].action_extra_class = `selected`;
                        }

                    } else {
                        if (image && data.library_file_categories[i].name == image.category || (!image && data.library_file_categories[i].name == 'blank')) {
                            data.library_file_categories[i].image_extra_class = `selected`;
                        } else {
                            data.library_file_categories[i].image_extra_class = ``;
                        }

                        if (action && data.library_file_categories[i].name == action.category || (!action && data.library_file_categories[i].name == 'none')) {
                            data.library_file_categories[i].action_extra_class = `selected`;
                        } else {
                            data.library_file_categories[i].action_extra_class = ``;
                        }
                    }
                }

                //data.id = 'portal_1';

                data.hotspot_name_translated = core.lang.get('hotspot.' + id);
                if (this.company_shown.portals.indexOf(data.id) != -1) {
                    data.companies = this.companies;
                    $('#hotspot_editor').html(core.template.render(core.template.company.hotspot_editor_portal, data));
                } else {
                    $('#hotspot_editor').html(core.template.render(core.template.company.hotspot_editor, data));
                }
            } else {
                if (action) {
                    console.log('action', action);
                    if (is_portal) {
                        console.log('Portal!', action.portal_company_is_public , action.portal_company_id_user , app.user.id);
                        if (action.portal_company_is_public || action.portal_company_id_user == app.user.id) {
                            window.location.href = action.portal_company_url;
                        }
                    } else {
                        for (let i in app.library.library_file_categories) {
                            if (app.library.library_file_categories[i].name == action.category) {
                                //console.log(app.library.library_file_categories[i]);
                                console.log('hotspot clicked action', app.library.library_file_categories[i].view_action + '/id/' + action.id + '/public/true');
                                runAction({ action: app.library.library_file_categories[i].view_action, id: action.id, id_company: this.company_shown.company.id, lang: this.getLang(), public: true, custom_data: action.custom_data });
                            }
                        }
                    }
                } else {
                    console.log('no action set');
                }
            }
        }
    }

    async customHotspotActionAction(data) {
        if (data.custom_data.external_url) {
            window.open(data.custom_data.external_url)
        }
    }

    async showYoutubeVideoAction(data) {
        var youtube_video_url = data.custom_data.youtube_video;

        if (youtube_video_url.length) {
            if (youtube_video_url.includes('watch?v=')) {
                var url_parts = youtube_video_url.split('watch?v=');
                showModal(core.lang.get('video.video'), core.template.render(core.template.video.player, { video_url: data.custom_data.youtube_video, video_id: url_parts[1] }), undefined, true, { width: 800, animation: 'fade' });
            } else {
                showModalFail(core.lang.get('company.invalid_youtube_url'), 5000);
            }
        } else {
            showModalFail(core.lang.get('company.invalid_or_no_youtube_url'), 5000);
        }
    }

    async previewCompanySpaceAction(request) {

        let ret = await core.libs.ws.sendAndWait({
            action: 'company/getCompanyData',
            data: { id: request.id, skip_lang: 1 }
        });

        if (ret.company && ret.company.id_user == app.user.id) {
            //window.open('company/previewSpace/id/' + request.id)

            setTimeout(() => {
                const aElem = document.createElement("a");
                aElem.href = ret.company.url;
                aElem.target = "_blank";
                document.body.append(aElem);
                aElem.click();
                aElem.remove();
                console.log('OPEN!');
            });

            //window.open(ret.company.url)
        }
    }

    async previewSpaceAction(request) {
        $('#content').html(core.template.render(core.template.company.preview_company_space));
        this.preview({ id: request.id });
    }

    // Save selected image/action for hotspot
    async chooseFromContent(data) {
        console.log('chooseFromContent');
        let lang = this.getLang();
        let current_selected_id = 0;

        hideModal();

        await this.showCategoryFilesAction({ type: data.type, category: data.category, current_selected_id }, async (id) => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'company/updateHotspot',
                type: data.type,
                lang,
                id: this.company_shown.id,
                id_hotspot: this.hotspot_clicked.id,
                name: data.category,
                id_record: id,
            });

            this.company_shown.company.config = ret.config;
            this.company_shown.company.available_languages = ret.available_languages;
            this.company_shown.company.completed = ret.completed;
            this.updateKrpanoHotspots();
            await this.hotspot(this.hotspot_clicked);
            this.updateAvailableLanguages(null, null, lang);
        })
    }

    // Save selected image/action for hotspot
    async hotspotChoose(data) {
        let lang = this.getLang();
        let current_selected_id = 0;

        if (this.company_shown.company.config.hotspots[lang] && this.company_shown.company.config.hotspots[lang][this.hotspot_clicked.id]
            && this.company_shown.company.config.hotspots[lang][this.hotspot_clicked.id][data.type]) {
            current_selected_id = this.company_shown.company.config.hotspots[lang][this.hotspot_clicked.id][data.type].id;
        }

        hideModal();

        console.log('set hotspot',  data);

        let category = data.category;
        if (data.category == 'external_url' || data.category == 'youtube_video') {
            var ret = await core.libs.ws.sendAndWait({
                action: 'company/editHotspotCustomForm',
                category,
                lang,
                id: this.company_shown.id,
                id_hotspot: this.hotspot_clicked.id,
            });

            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }

            var html = core.template.render(core.template.company.edit_hotspot_custom_form, { form: ret.form, values: ret.custom_data, });
            var modal = showModal(core.lang.get('company.edit_hotspot_custom_form'), html, [
                { label: core.lang.get('common.close'), class: 'btn-secondary', close: true },
                {
                    label: core.lang.get('common.save'), class: 'btn-primary', onclick: function () {
                        $('#' + ret.form.id).submit();

                        // zakomentowane, bo modal zamykał się zawsze, nawet w przypadku błędów walidacji
                        //hideAllModals();
                    }
                },
            ], false, { width: 400 });

            var $form = $('#' + ret.form.id);

            let self = this;
            core.libs.form.handle($form, ret, function (data) {
                (async () => {
                    var ret = await core.libs.ws.sendAndWait({
                        action: 'company/editHotspotCustomForm',
                        data,
                        category,
                        lang,
                        id: self.company_shown.id,
                        id_hotspot: self.hotspot_clicked.id,
                    });

                    if (ret.errors) {
                        core.libs.form.showErrors($form, ret);
                    }

                    if (ret.success) {
                        self.company_shown.company.config = ret.config;
                        self.company_shown.company.completed = ret.completed;
                        self.updateKrpanoHotspots();
                        await self.hotspot(self.hotspot_clicked);
                        hideModal();
                    }
                })();
            });
        } else {
            var ret;
            if (data.category == 'portal') {
                ret = await core.libs.ws.sendAndWait({
                    action: 'company/updateHotspot',
                    type: data.type,
                    lang,
                    id: this.company_shown.id,
                    id_hotspot: this.hotspot_clicked.id,
                    name: 'action',
                    id_record: data.id,
                });
            } else if (data.category == 'blank') {
                ret = await core.libs.ws.sendAndWait({
                    action: 'company/updateHotspot',
                    type: data.type,
                    lang,
                    id: this.company_shown.id,
                    id_hotspot: this.hotspot_clicked.id,
                    name: 'blank',
                    id_record: -1,
                });

                // ustawiamy akcję na brak
                let action_none_button = $('.tags.action').find('.tag.action-category-none');
                $('.tags.action .tag').removeClass('selected');
                action_none_button.addClass('selected');
                action_none_button.trigger('click');

            } else if (data.category == 'none') {
                ret = await core.libs.ws.sendAndWait({
                    action: 'company/updateHotspot',
                    type: data.type,
                    lang,
                    id: this.company_shown.id,
                    id_hotspot: this.hotspot_clicked.id,
                    name: 'none',
                    id_record: -1,
                });
            } else {
                await this.showCategoryFilesAction({ type: data.type, category: data.category, current_selected_id }, async (id) => {
                    ret = await core.libs.ws.sendAndWait({
                        action: 'company/updateHotspot',
                        type: data.type,
                        lang,
                        id: this.company_shown.id,
                        id_hotspot: this.hotspot_clicked.id,
                        name: data.category,
                        id_record: id,
                    });

                    this.company_shown.company.default_language = ret.default_language;
                    this.company_shown.company.available_languages = ret.available_languages;
                    this.company_shown.company.config = ret.config;
                    this.company_shown.company.completed = ret.completed;
                    this.updateKrpanoHotspots();
                    await this.hotspot(this.hotspot_clicked);
                    this.updateAvailableLanguages(null, null, lang);
                })
            }

            if (ret) {
                this.company_shown.company.default_language = ret.default_language;
                this.company_shown.company.available_languages = ret.available_languages;
                this.company_shown.company.config = ret.config;
                this.company_shown.company.completed = ret.completed;
                this.updateKrpanoHotspots();
                await this.hotspot(this.hotspot_clicked);
                this.updateAvailableLanguages(null, null, lang);
            }
        }
    }

    async createStandAction() {
        var self = this;

        (async () => {

            var ret = await core.libs.ws.sendAndWait({
                action: 'company/createStand'
            });

            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }

            const stand_click = "krpano.embedded['preview-pano'].data.company.stand = $(this).data(\"number\"); krpano.render('preview-pano', krpano.MODE_PREVIEW, true); return false;";
            $('#content').html(core.template.render(core.template.company.create_stand, {
                stand_click,
                form: ret.form,
                values: ret.company,
                company: ret.company,
                checked: ret.company.show_contact_form,
                title: core.lang.get('company.ret')
            }));

            krpano.setData('preview-pano', { company: ret.company });
            krpano.render('preview-pano', krpano.MODE_PREVIEW);

            var $form = $('#' + ret.form.id);

            // Dynamic refresh the krpano.
            $form.find('#field_visual_company_form_name').keyup(function () {
                krpano.embedded['preview-pano'].data.company.name = $(this).val();
                //krpano.setupHotspots('preview-pano');
                krpano.renderHotspots('preview-pano', 'company_name');
            });

            // Dynamic refresh the krpano.
            ret.form.fields[2].onload = function (file) {
                if (!krpano.embedded['preview-pano'].data.company.logo) {
                    krpano.embedded['preview-pano'].data.company.logo = {};
                }

                krpano.embedded['preview-pano'].data.company.logo.url = URL.createObjectURL(file);
                //krpano.setupHotspots('preview-pano', 'logo');
                krpano.renderHotspots('preview-pano', 'logo');
            }

            core.libs.form.handle($form, ret, function (data) {
                (async () => {
                    var ret = await core.libs.ws.sendAndWait({
                        action: 'company/createStand',
                        data,
                    });

                    if (ret.errors) {
                        core.libs.form.showErrors($form, ret);
                    }

                    if (ret.success) {
                        showModalSuccess(ret.message, 5000);
                        runActionHash('company/dashboard');
                    }
                })();
            });
        })();

        return false;

    }

    async getCompanyPreviewData(id_user = 0) {
        var promises = [];

        var company = core.libs.ws.sendAndWait({
            action: 'company/visualCompany',
            id_user
        });
        promises.push(company);

        var products = core.libs.ws.sendAndWait({
            action: 'product/getAllProducts'
        });
        promises.push(products);

        var gallery = core.libs.ws.sendAndWait({
            action: 'gallery/home'
        });
        promises.push(gallery);

        var documents = core.libs.ws.sendAndWait({
            action: 'document/getAllDocuments'
        });
        promises.push(documents);

        var video_links = core.libs.ws.sendAndWait({
            action: 'video/getVideoLinks'
        });
        promises.push(video_links);

        promises.forEach(x => x = x.catch(err => console.log('getCompanyPreviewData', err)));
        var ret = Promise.all(promises);

        return {
            company: ret[0],
            products: ret[1],
            gallery: ret[2],
            documents: ret[3],
            video_links: ret[4]
        }
    }

    async contactAction(data) {
        (async () => {
            const id_company = data.id_company;

            var ret = await core.libs.ws.sendAndWait({
                action: 'company/contact',
                id_company: id_company
            });

            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }

            $('#modal').html(core.template.render(core.template.company.contact_form, { form: ret.form, values: {}, company: data.company }));

            var options = {};
            var myModal = new bootstrap.Modal(document.getElementById('contact_form_modal'), options);
            myModal.show();

            var $form = $('#' + ret.form.id);
            core.libs.form.handle($form, ret, function (data) {
                (async () => {
                    var ret = await core.libs.ws.sendAndWait({
                        action: 'company/contact',
                        data,
                        id_company
                    });

                    if (ret.errors) {
                        core.libs.form.showErrors($form, ret);
                    }

                    if (ret.success) {
                        document.getElementById('contact_form_modal').addEventListener('hidden.bs.modal', function (event) {
                            showModalSuccess(ret.message, 5000);
                        })
                        myModal.hide();
                    }
                })();
            });
        })();

        return false;
    }

    async dashboardAction() {
        var self = this;

        (async () => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'company/getCompanyData',
                /* data: { id_user: 0 } */
            });


            $('#content').html(core.template.render(core.template.company.dashboard, { company: ret.company }));

            $('.dashboard .icon').on('click', function () {
                var this_element = $(this);
                var type = this_element.data('type');
                var data = { id_company: ret.company.id };

                switch (type) {
                    case 'company-logo':
                        self.visualLogoAction();
                        break;
                    case 'company-name':
                        self.visualCompanyAction();
                        break;
                    case 'company-gallery':
                        runAction({ action: 'gallery/visualGallery' })
                        break;
                    case 'company-products':
                        self.displayProductsAction();
                        break;
                    case 'company-google-map':
                        self.visualMapAction({ type: 'google' });
                        break;
                    case 'company-open-street-map':
                        self.visualMapAction({ type: 'open_street' });
                        break;
                    case 'company-file-upload':
                        runAction({ action: 'document/displayDocuments' });
                        break;
                    case 'company-youtube-video':
                        runAction({ action: 'video/displayVideoLinks' });
                        break;
                    default:
                        break;
                }

            });

            //self.loadStandKrpanoAction({company: ret.company, stand_number: current_stand, user_products: ret2.products, gallery: ret3.gallery, documents: ret4.documents, video_links: ret5.video_links, only_preview: true});
            krpano.setData('preview-pano', ret);
            krpano.render('preview-pano', krpano.MODE_PREVIEW);
        })();
    }

    async displayProductsAction(data) {
        var self = this;

        (async () => {

            var ret = await core.libs.ws.sendAndWait({
                action: 'product/getAllProducts'
            });

            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }

            var all_products = [];
            var product_types = [core.lang.get('product.main'), core.lang.get('product.additional'), core.lang.get('product.other')];

            if (ret.products) {
                ret.products.forEach(function (prd) {
                    prd.type_text = product_types[parseInt(prd.type) - 1];
                    all_products.push(prd);
                });
            }

            var products_exist = all_products.length > 0;

            $('#modal').html(core.template.render(core.template.product.product_list_modal, { products: all_products, products_exist: products_exist, title: core.lang.get('product.products') }));

            var options = {};
            var myModal = new bootstrap.Modal(document.getElementById('visual_form_modal'), options);
            myModal.show();

            $('#visual_form_modal .add-user-product').on('click', function () {
                myModal.hide();
                self.visualProductAction({});
            });

            $('#visual_form_modal .edit-user-product').on('click', function () {
                myModal.hide();
                var product_id = $(this).data('product_id');
                self.visualProductAction({ id: product_id });
            });

        })();

        return false;

    }

    async showStandAction(data) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'company/getCompanyData',
            data: { id_user: data.company == 'metajar' ? 2 : 1 }
        });

        $('#content').html(core.template.render(core.template.company.show_stand, ret.company));

        $('#content').find('.button_contact').click(function () {
            var html = core.template.render(core.template.company.ret, { company: ret.company });
            var modal = showModal(ret.company.name, html, undefined, false, { width: 600, animation: 'slide-in-left' });
        });
    }

    async showAction(params) {
        console.log('showAction', params);
        var self = this;
        var ret = await core.libs.ws.sendAndWait({
            action: 'company/getCompanyData',
            strict: true,
            data: params,
            page_lang: core.lang.language
        });

        if (!ret.company) {
            runActionUrl('page/error404');
        } else if (ret.company) {
            var ret2 = await core.libs.ws.sendAndWait({
                action: 'user/checkType',
            });

            let company_url = self.getCompanyUrl(ret);

            const clone = JSON.parse(JSON.stringify(ret));
            console.log('clone', clone);

            history.pushState({}, ret.redirect_url, ret.redirect_url);

            console.log('company_url', company_url);

            //var can_add_to_favorites = app.user.groups.logged && ret2.regular
            var can_add_to_favorites = app.user.groups.logged && ret.company.id_user != app.user.id;

            clone.show_contact_icon = ret.company_data.contact_data && ret.company_data.contact_data.length >= 1;
            clone.prev_url = company_url.prev;
            clone.next_url = company_url.next;
            clone.lang = ret.lang;
            //clone.can_see_public_space = ret.company.can_see_public_space;
            clone.arrows_visible = false; // ret.company_list.length > 1; // 2023-09-05 Janusz chce
            clone.can_add_to_favorites = can_add_to_favorites;
            clone.show_change_language_icon = clone.company.available_languages.length > 1;

            let seo_title = (clone.company['title_'+core.lang.language] ? clone.company['title_'+core.lang.language] + ' - ' : '') + core.lang.get('common.title');
            let seo_description = (clone.company['description_'+core.lang.language] ? clone.company['description_'+core.lang.language] + ' - ' : '') + core.lang.get('common.description');
            document.title = seo_title;
            $('meta[name="description"]').attr("content", seo_description);

            // if (ret.company.id_contact != null) {

            //     var ret3 = await core.libs.ws.sendAndWait({
            //         action: 'contact/getContactData',
            //         id: ret.company.id_contact
            //     });

            //     show_contact_icon = ret.company.id_contact != null && ret3.contact_data;
            //     clone.show_contact_icon = show_contact_icon;
            //     clone.contact_data = ret3.contact_data;
            // }

            clone.single_icon_box = false; // !can_add_to_favorites && !show_contact_icon; - added iframe visible always

            if (!clone.company.hasValidStandardPackage) {
                if (clone.company.id_user == app.user.id) {
                    clone.company.space_currently_inactive_message = core.lang.get('company.your_space_currently_inactive')
                } else {
                    clone.company.space_currently_inactive_message = core.lang.get('company.space_currently_inactive')
                }
            }

            $('#menu nav').remove();

            var show_chat = app.user.has_valid_level_2 && clone.company.id_user != app.user.id;

            $('#menu').html(core.template.render(core.template.layout.menu, { id_company: clone.company.id, show_chat_in_space: show_chat }));

            $('#content').html(core.template.render(core.template.company.show, clone));

            if (ret.company.can_see_public_space) this.preview(ret);

            $('body').attr('action', 'company/show');

            $('.iframe-info a').attr('href', core.config.home + '/user/registration/affiliate_code/' + clone.company.affiliate_code);

            $('.dashboard .handle').click(() => {
                $('.dashboard .bottom-bar').toggleClass('visible')
            })

            $('.container.show-company .fullscreen-toggle.expand').on('click', () => { setFullScreen(true) })
            $('.container.show-company .fullscreen-toggle.compress').on('click', () => { setFullScreen(false) })

            fullScreenExit();

            $('.show-company span.change_language').click(function () {
                self.changeLanguage(clone.company.url, clone.company.active_languages, clone.lang);
            });

            this.last_viewed_company_url = clone.company.url;
        }
    }

    async changeLanguage(url, list, current) {
        let html = `<div class='company_languages_list'>`;
        for (let lang of list) {
            html += `<a href='${core.config.home}/${lang}/${url}'>${core.lang.get('common.' + lang)}</a>`;
        }

        html += '</div>';

        let modal = showModal(core.lang.get('company.change_language'), html, undefined, true, { width: 400 });
    }

    async showContactInfoAction(request) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'contact/getContactData',
            id_company: request.id_company,
            lang: request.lang,
            id: request.id,
        });

        if (!ret.contact_data.logo) {
            if (ret.contact_data.id_logo) {
                var ret2 = await core.libs.ws.sendAndWait({
                    action: 'library/getUserLibraryFiles',
                    id: ret.contact_data.id_logo,
                    category: 'logotypes',
                });
                ret.contact_data.logo = ret2.files[0] ? ret2.files[0] : false;
            } else {
                delete ret.contact_data.logo;
            }
        }

        ret.contact_data.social_media_list = ret.contact_data.facebook_url || ret.contact_data.twitter_url || ret.contact_data.instagram_url || ret.contact_data.linkedin_url || ret.contact_data.youtube_url;
        let html = core.template.render(core.template.company.contact_info, { data: ret.contact_data, list: ret.list, show_list: ret.list.length > 1 });
        let modal = showModal(core.lang.get('company.contact_info'), html, undefined, true, { width: 800 });
        $('#change_contact_select').on('change', function () {
            request.id = $(this).val();
            app.company.showContactInfoAction(request);
        });

        // var ret = await core.libs.ws.sendAndWait({
        //     action: 'contact/getById',
        //     id: request.id,
        //     remove: request ? request.remove : 0,
        // });

        // let html = core.template.render(core.template.contact.add, { form: ret.form, values: ret.values, dataset_form: ret.dataset_form, message_form: ret.message_form, category_name: core.lang.get('contact.contact_details') });
        // let modal = showModal(core.lang.get('company.contact_info'), html, undefined, true, { width: 600 });
        // await app.contact.loadDataAction(0);
        // return html;
    }

    getCompanyUrl(ret) {
        let index = ret.company_list.indexOf(ret.company.url);
        let prev_index = index == 0 ? ret.company_list.length - 1 : index - 1;
        let next_index = index == ret.company_list.length - 1 ? 0 : index + 1;
        let prev = ret.company_list[prev_index];
        let next = ret.company_list[next_index];

        return { prev, next }
    }

    async displayMapAction(data) {
        (async () => {
            var modal = showModal(data.type, core.template.render(core.template.company.map, data));
        })();

        return false;
    }

    async displayUserProductsAction() {
        var self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/companyData'
        });

        self.displayProductsAction({ id_company: ret.company.id });
    }

    // Redirect user
    async onPermissionsChanged() {
        if (app.user.hasGroup('pro')) {
            /*runActionHash('fair/list');*/
        } else if (app.user.hasGroup('company_filled')) {
            runActionHash('company/dashboard');
        } else {
            /*if (location.hash.indexOf('#user/activate/hash/') == -1) {
                var ret = await core.libs.ws.sendAndWait({
                    action: 'company/exists',
                    url: window.location.href
                });

                if (ret.exists) {
                    this.showAction({ id: ret.id_user });
                } else {
                    if (hash_changed_index > 1) {
                        runActionHash('home');
                    }
                }
            } else {
                // Activate account.
            }*/
        }
    }

    async importAction(data) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'company/import',
        });

        if (ret.error) {
            showModalFail(ret.message, 5000);
            return;
        }

        var modal = showModal(
            core.lang.get('company.import'),
            core.template.render(core.template.fair.form, { form: ret.form, values: ret.fair, fair: ret.fair }),
            [
                { label: core.lang.get('common.close'), class: 'btn-secondary', close: true },
                {
                    label: core.lang.get('common.save'), class: 'btn-primary', onclick: function () {
                        $('#' + ret.form.id).submit();
                    }
                },
            ]
        );

        var $form = $('#' + ret.form.id);
        core.libs.form.handle($form, ret, async (data) => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'company/import',
                data
            });

            if (ret.errors) {
                core.libs.form.showErrors($form, ret);
            }

            if (ret.success) {
                modal.hide();
                showModalSuccess(ret.message, 5000);
            }
        });
    }

    async studioAction() {
        if (!app.user.groups.logged || !app.user.groups.company) {
            runActionUrl('company/create');
            return;
        }

        var self = this;
        $('.studio-container .studio-nav-menu').show();
        await this.showStudioMenu('3dspaces');
        await this.spacesAction();

        //var space_box = $('.studio-container .spaces .space-box');

        /*space_box.find('.edit-button').on('click', function() {
            var company_id = $(this).data('company_id');
            self.editCompanyDetailsAction(company_id);
        });*/
    }

    async showStudioMenu(active, sub_el = null, form_id = false) {
        if (!app.user.groups.logged) {
            runActionUrl('user/login');
            return;
        }

        var ret = await core.libs.ws.sendAndWait({
            action: 'user/checkType',
        });


        var ret2 = await core.libs.ws.sendAndWait({
            action: 'company/getMarketplaceStands'
        });

        if (!$('.studio-container').length || $('#content .pricelist').length) {
            $('#content').html(core.template.render(core.template.company.studio, { form_id: form_id, is_regular_user: ret.regular, available_stands: ret2.stands.filter(x => x.show_in_marketplace).length }));

            var nav_menu = $('.studio-container .studio-nav-menu');
            var menu_element = nav_menu.find('.studio-menu-element');
            var studio_items_container = $('.studio-container .studio-items-container');
            var menu_item = menu_element.find('.item');

            menu_item.on('click', function () {

                var self = $(this);
                var parent_item = self.parent();

                nav_menu.find('.item').not(self).removeClass('item-active');

                var other_menu_elements = nav_menu.find('.studio-menu-element.sub').not(parent_item);
                other_menu_elements.find('.submenu').slideUp().removeClass('shown');
                other_menu_elements.find('.caret').removeClass('rotated');

                if (self.hasClass('item-active')) {
                    self.removeClass('item-active');
                } else {
                    self.addClass('item-active');
                }

                if (self.hasClass('sub')) {

                    var parent = self.parents('.studio-menu-element');
                    var submenu = parent.find('.submenu');
                    var caret = parent.find('.caret');
                    var icon = self.parent().find('.menu-item-icon');
                    if (submenu.hasClass('shown')) {
                        submenu.removeClass('shown');
                        caret.removeClass('rotated');
                    } else {
                        submenu.addClass('shown');
                        caret.addClass('rotated');
                    }

                    submenu.slideToggle();

                } else {
                    nav_menu.find('.studio-menu-element.sub').find('.submenu').slideUp();
                    nav_menu.find('.studio-menu-element.sub').find('.caret').removeClass('rotated');
                }

            });

            menu_element.find('.submenu-list li').on('click', function () {
                var self = $(this);
                var parent = self.parent();
                parent.find('li').not(self).removeClass('active');
                self.addClass('active');
            });
        }

        if (active) {
            var active_el = $('.studio-container .studio-nav-menu').find(`[data-name_id='${active}']`);
            var item_el = active_el.find('.item');
            if (!item_el.hasClass('item-active')) {
                item_el.trigger('click');
            }

            active_el.find('li').removeClass('active');
            $('.studio-menu-element.sub').not($(this)).find('li').removeClass('active');

            if (sub_el) {
                active_el.find(`[data-name_id='${sub_el}']`).addClass('active');
            }
        }
    }

    async spacesAction() {
        await this.showStudioMenu('3dspaces', 'myspaces');

        // checks if user is regular(no company) or company user
        // var ret4 = await core.libs.ws.sendAndWait({
        //     action: 'user/checkType',
        // });

        if (app.user.is_regular) {
            runActionUrl('user/profile');
            return;
        }

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/getUserSpaces'
        });

        var ret2 = await core.libs.ws.sendAndWait({
            action: 'user/checkBusinessPackageDate'
        });

        if (ret.spaces.length) {
            ret.spaces.forEach((space) => {
                if (space.user_current_level < space.stand_level) {
                    if (ret2.no_active_package) {
                        space.hide_space_status_select = true;
                        space.hide_space_status_select_info = core.lang.get('company.no_active_package');
                    } else {
                        space.hide_space_status_select = true;
                        space.hide_space_status_select_info = core.lang.get('company.package_too_small');
                    }
                } else {
                    if (ret2.no_active_package) {
                        space.hide_space_status_select = true;
                        space.hide_space_status_select_info = core.lang.get('company.no_active_package')
                    } else space.hide_space_status_select = false;
                }
            });
        }

        $('.studio-items-container').html(core.template.render(core.template.company.spaces, {
            spaces: ret.spaces,
            can_add_new_spaces: ret2.can_add_new_spaces,
            business_package_valid: ret2.business_package_valid,
            no_active_package: ret2.no_active_package
        }));

        $('.studio-items-container .stand-delete').on('click', function() {
            let id = $(this).data('id');
            let modal = showModalConfirm(core.lang.get('company.confirm_delete_company'), async function () {
                modal.hide();
                var ret = await core.libs.ws.sendAndWait({
                    action: 'company/delete',
                    id,
                });

                if (ret.success) {
                    app.company.spacesAction();
                }
            }, false);
        });

        this.recordActions();
    }

    async templatesAction(data) {

        await this.showStudioMenu('3dspaces', 'templates');

        // checks if user is regular(no company) or company user
        var ret3 = await core.libs.ws.sendAndWait({
            action: 'user/checkType',
        });

        if (ret3.regular) runActionUrl('home');

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/getUserTemplatesGrouped'
        });
console.log('company/getUserTemplatesGrouped', ret);

        // var ret2 = await core.libs.ws.sendAndWait({
        //     action: 'user/checkBusinessPackageDate'
        // });
        // console.log('ret2', ret2);
        /*var user_library_stands = [];

        if (ret.user_spaces.length) {
            let display_index = 1;
            for (let us of ret.user_spaces) {
                us.display_index = display_index;
                if (us.display_index > 1) us.hide_header_info = true;
                display_index++;
            }
        }

        if (ret.user_library_stands.length) {
            let display_index = 1;
            for (let uls of ret.user_library_stands) {
                if (!uls.current) {
                    uls.display_index = display_index;
                    if (uls.display_index > 1) uls.hide_header_info = true;
                    user_library_stands.push(uls)
                    display_index++;
                }
            }
        }

        $('.studio-items-container').html(core.template.render(core.template.company.templates, {
            user_spaces: ret.user_spaces, user_library_stands: ret.user_library_stands,
            can_add_new_spaces: ret2.can_add_new_spaces
        }));*/

        for (let s of core.template.company.templates_grouped) {
            //s.free =
        }

        //ret.can_add_new_spaces = ret2.can_add_new_spaces;

        $('.studio-items-container').html(core.template.render(core.template.company.templates_grouped, ret));
        function updateUseButton() {
            $('.studio-items-container select').each(function() {
                let sel = $(this).find('option[value="'+$(this).val()+'"]');
                let opacity = 1;
                if (parseInt($(sel).attr('used')) >= parseInt($(sel).attr('count'))) {
                    opacity = 0.4;
                }

                $(this).parents('.space-box').find('button.edit-button').css('opacity', opacity);
            });
        }
        $('.studio-items-container select').change(function() {
            updateUseButton();
        });

        updateUseButton();
    }

    async changeTemplateAction(data) {

        await this.showStudioMenu('3dspaces', 'templates');

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/getUserTemplates'
        });

        var ret2 = await core.libs.ws.sendAndWait({
            action: 'company/getMarketplaceStands',
            id_company: data.id_company
        });

        $('.studio-items-container').html(core.template.render(core.template.company.change_template, { user_stands: ret.all_user_stands, available_stands: ret2.stands.length + 100 }));

    }

    async getRandomSpaceAction(data) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/getRandomSpace',
            id_company: data.id_company
        });

        if (ret.url) runActionUrl(ret.url);
    }

    async setTemplateAction(data) {
        var self = this;
        var ret = await core.libs.ws.sendAndWait({
            action: 'company/setUserCurrentSpace',
            stand_id: data.id,
            //user_stand_id: data.user_stand_id,
            company_id: data.company_id
        });

        if (ret.success) {
            $('#predef_modal').modal('hide');
            runActionUrl('company/edit/id/' + data.company_id);
            showModalSuccess(core.lang.get('company.template_has_been_changed'), 5000);
        }

    }

    async setTemplateConfirmAction(data) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/getChangeTemplateMessages',
            stand_id: data.id,
            company_id: data.company_id
        });

        var box = $('#predef_modal .box-spaces-container').find('[data-user_stand_id="' + data.user_stand_id + '"]');
        var cancel_button = $('#predef_modal .box-spaces-container').find('.cancel-buy-space[data-user_stand_id="' + data.user_stand_id + '"]');
        $('#predef_modal .box-spaces-container .space-box .buy-confirm').hide();
        var buy_confirm = box.find('.buy-confirm');
        buy_confirm.show();

        buy_confirm.find('.message').html(core.template.render(core.template.company.set_space_messages, { messages: ret.messages }));

        cancel_button.click(() => {
            buy_confirm.hide();
        })
    }

    async crateNewUserSpaceAction(data) {
        // Get selected variant ID
        if (data.button) {
            let $select = $(data.button).parents('.space-box').find('.variants');
            if ($select) {
                let id = parseInt($select.val());
                if (id) {
                    data.id = id;
                }
            }
        }

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/createNewUserSpace',
            stand_id: data.id
        });
        if (ret.success && ret.new_company_id) {
            $('#predef_modal').modal('hide');
            runActionUrl('company/edit/id/' + ret.new_company_id);
        }
    }

    async marketplaceAction(data) {

        await this.showStudioMenu('3dspaces', 'marketplace');

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/getMarketplaceStands',
            check_purchase_possibility: true
        });

        var ret2 = await core.libs.ws.sendAndWait({
            action: 'user/getUser'
        });

        $('.studio-items-container').html(core.template.render(core.template.company.marketplace, { stands: ret.stands, user: ret2.user }));
    }

    async confirmSpacePurchaseAction(data) {
        var valid = await core.libs.ws.sendAndWait({
            action: 'company/getInvoiceDataValid',
        });

        if (valid.valid) {
            var ret = await core.libs.ws.sendAndWait({
                action: 'company/getStands',
            });

            let stand = ret.stands.find((x) => x.id == data.id );
            if (stand) {
                console.log(stand);
                let html = core.template.render(core.template.company.space_purchase_confirm, { space_id: data.id, stand });
                let modal = showModal(core.lang.get('company.confirm_space_purchase'), html, undefined, true, { width: 800 });

                $('.modal .choose-currency button').on('click', function() {
                    $(this).parent().find('.selected').removeClass('selected')
                    $(this).addClass('selected');
                    let cur = $(this).data('change-currency');
                    $("[data-currency][data-currency!='"+cur+"']").hide();
                    $("[data-currency='"+cur+"']").show();
                    return false;
                });
                $('.modal .choose-currency button:first-child').trigger('click');
            }
        } else {
            //let html = core.template.render(core.template.company.no_valid_invoice_data);
            let modal = showModalFail(core.lang.get('user.fill_invoice_data_before_purchase'));
        }
    }

    async chooseSpaceAction(data) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/getStands',
            id_company: data.id_company
        });

        let html = core.template.render(core.template.company.stand_list, { stands: ret.stands });
        let modal = showModal(core.lang.get('user.confirm_package_purchase'), html, undefined, true, { width: 800 });
    }

    async buySpaceAction(data) {
        data.currency = $('.modal .choose-currency button.selected').data('change-currency');
        var ret = await core.libs.ws.sendAndWait({
            action: 'company/buySpace',
            data
        });

        console.log(ret);
        if (ret.status_code == 'SUCCESS' && ret.redirect_uri) {
            window.location.href = ret.redirect_uri;
        } else {
            if (ret.reload) {
                app.company.templatesAction();
                showModalSuccess(core.lang.get('user.stand_has_been_bought'));
            } else {
                showModalFail(core.lang.get('user.payment_error'));
            }
        }

        if (ret.success) {
            $('#predef_modal').modal('hide');
            runActionUrl('company/templates');
        }
    }

    async showUserSpacesAction(data) {

        data.only_library = true;
        data.new_company = data.hasOwnProperty('new_company') ? true : false;

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/getUserTemplates',
            data
        });

        let html = '';
        if (ret.variants.length) {
            html = core.template.render(core.template.company.user_spaces, {
                variants: true,
                user_spaces: ret.variants,
                current_company_id: data && data.company_id ? data.company_id : false,
                new_company: data.new_company
            });
        }

        html += core.template.render(core.template.company.user_spaces, {
            variants: false,
            user_spaces: ret.user_spaces,
            current_company_id: data && data.company_id ? data.company_id : false,
            new_company: data.new_company
        });

        let buttons = [
            { label: core.lang.get('common.go_to_market'), class: 'btn-secondary', onclick: () => { runActionUrl('company/marketplace'); hideModal(); } },
            { label: core.lang.get('common.close'), class: 'btn-primary', close: true },
        ]

        let modal = showModal(core.lang.get('company.choose_template'), html, buttons, true, { width: 1000 });
    }

    async getSpacesListAction() {
        var ret = await core.libs.ws.sendAndWait({
            action: 'company/getUserSpaces'
        });

        $('.studio-items-container').html(core.template.render(core.template.company.spaces_list, { company_url: core.config.home, spaces: ret.spaces }));

        this.recordActions();
    }

    async copyHotspotDataAction(data) {

        var current_lang = app.company.getLang();

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/copyHotspotData',
            //current_lang: core.lang.language,
            current_lang: current_lang,
            company_id: data.company_id
        });

        this.company_shown.company = ret.company;

        if (ret.success) {
            showModalSuccess(ret.message, 5000);
        }
    }

    recordActions() {
        var td_list_space_name = $('.studio-container td.list-space-name');
        var company_url_input = $('.studio-container input.company-url-input');
        var company_status_select = $('.studio-container .company-status-select');

        // td_list_space_name.on('mouseover', function() {
        //     var self = $(this);
        //     td_list_space_name.not(self).find('.list-buttons').hide();
        //     self.find('.list-buttons').show();
        // });

        // td_list_space_name.on('mouseleave', function() {
        //     var self = $(this);
        //     self.find('.list-buttons').hide();
        // })

        var new_url_timer = false;

        company_url_input.on('keyup', async function () {

            var self = $(this);
            var old_url = self.data('old_value');
            var new_url = self.val();
            var company_id = self.data('company_id');

            if (!new_url.length) return false;

            if (new_url.length && new_url != old_url) {

                clearTimeout(new_url_timer);

                new_url_timer = setTimeout(async function () {

                    var url_valid = /^[a-z0-9-]*$/.test(new_url);

                    if (url_valid) {

                        var ret = await core.libs.ws.sendAndWait({
                            action: 'company/urlAvailable',
                            url: new_url
                        });

                        if (ret.available) {

                            var ret = await core.libs.ws.sendAndWait({
                                action: 'company/updateCompanyData',
                                column: 'url',
                                company_id: company_id,
                                value: new_url
                            });

                            if (ret.success) {
                                $('.update-confirmation-button').show().delay(3000).fadeOut();
                                self.attr('value', new_url);
                                self.attr('data-old_value', new_url);
                            } else {
                                self.val(old_url);
                                self.attr('value', old_url);
                                self.attr('data-old_value', old_url);
                            }

                        } else {

                            self.val(old_url);
                            self.attr('value', old_url);
                            self.attr('data-old_value', old_url);

                            $('.update-confirmation-error-button').html(core.lang.get('company.url_already_exists')).show().delay(3000).fadeOut();
                        }

                    } else {
                        $('.update-confirmation-error-button').html(core.lang.get('company.url_not_valid')).show().delay(3000).fadeOut();
                    }

                }, 1000);

            }
        });

        company_status_select.on('change', async function () {
            var self = $(this);
            self.css('opacity', 0.3);
            var company_id = self.data('company_id');
            var status = self.val();

            var ret = await core.libs.ws.sendAndWait({
                action: 'company/changeCompanyStatus',
                company_id: company_id,
                change_to_status: status
            });

            self.css('opacity', 1);

            if (ret.success) {
                switch (parseInt(status)) {
                    case 1:
                        $(self).parents('.space-box').removeClass('private');
                        var icon_type = 'fa-eye';
                        break;
                    case 2:
                        var icon_type = 'fa-pencil-alt';
                        break;
                    case 3:
                        $(self).parents('.space-box').addClass('private');
                        var icon_type = 'fa-lock';
                        break;
                }

                self.parent().find('.select-icon').html(`<i class="fas ${icon_type}"></i>`);
                $('.update-confirmation-button').show().delay(3000).fadeOut();

            } else {
                showModalFail(ret.message, 5000);
                self.val(3);
            }
        });
    }

    async editCompanyDetailsAction(company_id) {
        (async () => {

            var ret = await core.libs.ws.sendAndWait({
                action: 'company/companyDetails',
                id: company_id,
            });

            $('#modal').html(core.template.render(core.template.company.details_form, { form: ret.form, values: ret.company.company, company: ret.company.company }));

            var options = {};
            var myModal = new bootstrap.Modal(document.getElementById('details_form_modal'), options);
            myModal.show();

            var $form = $('#' + ret.form.id);
            core.libs.form.handle($form, ret, function (data) {

                (async () => {
                    var ret2 = await core.libs.ws.sendAndWait({
                        action: 'company/companyDetails',
                        data,
                        column: 'url'
                    });

                    if (ret2.errors) {
                        core.libs.form.showErrors($form, ret2);
                    }

                    if (ret2.success) {
                        myModal.hide();
                    }
                })();
            });
        })();

        return false;
    }

    handleCategoryFiles(files) {

        var img_thumb_categories = ['logotypes', 'photos'];
        var return_files = [];

        files.forEach(function (item) {
            item.has_thumb_url = img_thumb_categories.includes(item.category) ? true : false;
            return_files.push(item);
        });

        return return_files;
    }

    async getFoldersAction() {
        await this.showStudioMenu();

        $('.studio-items-container').html(core.template.render(core.template.company.folders));
    }

    async getLogotypesAction() {
        await this.showStudioMenu();
        $('.studio-items-container .contents-box').html(core.template.render(core.template.company.logotypes));
    }

    async showCategoryFilesAction(data, onclick = false) {
        var self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'library/getUserLibraryFiles',
            category: data.category,
            only_on_home: true,
            include_folders: true
        });

        ret.selected_category = data.category;
        ret.selected_type = data.type;

        console.log('showCategoryFilesAction', ret);

        // if (ret.folders.length) {
        //     ret.diplay_folders_section = true;
        // }
        ret.display_folders_section = false;

        for (let i in ret.files) {
            ret.files[i].template = 'library.' + ret.files[i].category;
            ret.files[i].selected = ret.files[i].id == parseInt(data.current_selected_id) ? true : false;
            ret.files[i].edit_action_event = false;
        }

        let options = { width: 950 };

        let no_files_message;
        let add_files_button = '<button class="btn btn-primary" onclick="hideAllModals(); runActionUrl(\'/library/add/category/'+data.category+'/folder/'+data.folder_id+'\'); return false;">'+core.lang.get('library.go_to_add_'+data.category)+'</button>';

        if (data.category == 'logotypes' || data.category == 'photos') {
            //no_files_message = '<button class="btn btn-primary" onclick="hideAllModals(); runActionUrl(\'/library/add/category/'+data.category+'/folder/'+data.folder_id+'\'); return false;">'+core.lang.get('library.go_to_add_'+data.category)+'</button>';
        }

        ret.no_files_message = no_files_message;
        ret.add_files_button = add_files_button;

        let modal = showModal(core.lang.get('library.choose_content'), core.template.render(core.template.company.category_files, ret), undefined, data.hide_modal != undefined ? data.hide_modal : true, options);

        var library_file_box = $(modal._element).find('.library-file-box');

        library_file_box.click(function () {
            var file_id = $(this).data('file_id');
            if ($(this).hasClass('folder')) {
                console.log('AAAA', { folder_id: file_id, selected_category: ret.selected_category, selected_type: ret.selected_type });
                runAction({action: "library/loadFilesForFolder", folder_id: file_id, selected_category: ret.selected_category, selected_type: ret.selected_type, onclick });
            } else {
                modal.hide();

                var self = $(this);
                library_file_box.removeClass('file-selected');
                self.addClass('file-selected');

                if (onclick) {
                    onclick(file_id);
                }
            }
        });
    }

    async copyUrlAction(request) {
        var url = '';
        var input = document.createElement('input');
        if (request.el) {
            let p = request.el.parent().find('.prepend');
            if (p.length) {
                url = p.html();
            }
            url += request.el.parent().find('input').val();
        } else {
            url = request.url ? request.url : window.location.href;
        }

        input.setAttribute('value', url);
        document.body.appendChild(input);
        setTimeout(() => {
            input.select();
            input.setSelectionRange(0, 99999); // For mobile devices
            navigator.clipboard.writeText(input.value);
            $(input).remove();

            if (request.el) {
                request.el.addClass('action');
                setTimeout(() => {
                    request.el.removeClass('action');
                }, 300);
            }

            console.log('copyUrlAction', url);
        }, 100);

        //showModalSuccess(core.lang.get('company.url_copied_to_clipboard'), 3000);
    }

    async searchAction(data) {

        var url_params = getUrlParams('company/search');
        var url_param_decoded = unescape(decodeURIComponent(url_params.query));

        var groups_display_index = data.groups_display_index ? data.groups_display_index : 4;
        var companies_display_index = data.companies_display_index ? data.companies_display_index : 4;

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/search',
            query: url_params.query ? url_param_decoded : data.query,
            groups_display_index: groups_display_index,
            companies_display_index: companies_display_index
        });

        if (ret.groups.length) {
            ret.groups.forEach((gp) => {
                ret.langs.forEach((lang) => {
                    gp['display_name_' + lang] = gp['name_' + lang].length > 30 ? gp['name_' + lang].slice(0, 30) + '...' : gp['name_' + lang];
                })
            })
        }

        $('#content').html(core.template.render(core.template.company.search, {
            query: data.query,
            groups_count: ret.groups_count,
            groups: ret.groups,
            companies_count: ret.companies_count,
            companies: ret.companies,
            current_groups_display: ret.groups.length,
            current_companies_display: ret.companies.length,
            see_more_groups: parseInt(ret.groups_count) > parseInt(ret.groups.length) ? true : false,
            see_more_companies: parseInt(ret.companies_count) > parseInt(ret.companies.length) ? true : false,
        }));

        if (url_params.query) {
            $('.top-menu .nav-search').val(url_param_decoded);
            $('.top-menu .reset-search').show();
        }
    }

    async copy(el, confirm_el) {
        let url = $(el).val();
        $(el)[0].select();
        document.execCommand('copy');
        $(el).val("...");
        $(el).val(url);
        $(confirm_el).show();
        setTimeout(() => { $(confirm_el).hide(); }, 1000);
    }

    async iframeAction(data) {
        var self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'company/iframe',
            id: data.id_company
        });

        if (ret.error) {
            showModalFail(ret.message, 5000);
        } else {

            let html = core.template.render(core.template.company.iframe, ret);
            let modal = showModal(core.lang.get('company.iframe'), html, undefined, true, { width: 900 });

            $('#field_iframe_generate_lang').appendTo('#share-url-container .row');
            $('#field_iframe_generate_url').appendTo('#share-url-container .row');
            $('#field_iframe_generate_generated').appendTo('#share-code-container');

            var $form = $('#' + ret.form.id);

            let generateCode = function () {

                let style = ``;
                if ($form.find("[name='border']").val() != '0') {
                    $form.find("[name='color']").parent().parent().parent().show();
                    style = ` style="border: ${$form.find("[name='border']").val()}px solid ${$form.find("[name='color']").val()}"`;
                } else {
                    $form.find("[name='color']").parent().parent().parent().hide();
                }

                let iframe_html = `<iframe src='${core.config.home + '/' + $form.find("[name='lang']").val() + '/' + ret.url + '/partner'}' width='${$form.find("[name='width']").val()}' height='${$form.find("[name='height']").val()}'${style}></iframe>`;

                if (0 && $form.find('input[name="code_type"]:checked').val() == 1) { // disabled
                    $form.find("[name='title']").parents('.input-box').show();
                    $form.find("[name='description']").parents('.input-box').show();
                    $form.find("[name='keywords']").parents('.input-box').show();

                    let html = ret.html_code.replace('[title]', $form.find('input[name="title"]').val());
                    html = html.replace('[description]', $form.find('input[name="description"]').val());
                    html = html.replace('[keywords]', $form.find('input[name="keywords"]').val());
                    html = html.replace('[iframe]', iframe_html);
                    $form.find('textarea').val(html.trim());
                } else {
                    $form.find("[name='title']").parents('.input-box').hide();
                    $form.find("[name='description']").parents('.input-box').hide();
                    $form.find("[name='keywords']").parents('.input-box').hide();

                    $('#iframe_generate [name="generated"]').val(iframe_html.trim());
                }

                $('#field_iframe_generate_url input').val(core.config.home + '/' + $form.find("[name='lang']").val() + '/' + ret.url);
            }

            generateCode();

            $form.find('input,select').change(generateCode).keyup(generateCode);

            $('#share-show-more').click(function () {
                $(this).hide().parent().find('.more').slideDown();
                $('#share-show-less').show();
            });

            $('#share-show-less').click(function () {
                $(this).hide().parent().find('.more').slideUp();
                $('#share-show-more').show();
            });

            //$('#field_iframe_generate_url').append($(` <a href="#" onclick="app.company.copy($('#field_iframe_generate_url input'), $('#field_iframe_generate_url i')); return false;"><i class="fas fa-check" style='display: none'></i> ${core.lang.get('common.copy_url')}</a>`));

            core.libs.form.handle($form, ret, function (data) {
            });
        }
    }
}

app.company = new Company();

metajarHotspotAction = function (str) {
    console.log(str, typeof str);
    if (typeof str == 'string') {
        eval('var a = ' + str + ';');
        app.company.hotspot(a);
    } else {
        app.company.hotspot(str);
    }
}
