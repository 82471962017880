class Krpano {
    MODE_PREVIEW = 'preview';
    MODE_EDIT = 'edit';

    embedded = {};
    hs_index = 1;

    renderHotspots(pano_id, hs_name = false) {
        if (typeof hs_name == "string") {
            hs_name = [hs_name];
        }

        setTimeout(() => {
            var hotspots = this.embedded[pano_id].stand.getHotspots();
            for (var k in hotspots) {
                if (!hs_name || hs_name.indexOf(hotspots[k].hs_name) != -1) {
                    this.addHotspot(pano_id, hotspots[k]);
                }
            }

            this.checkEditMode();
            if (this.embedded[pano_id].onready_callback) {
                this.embedded[pano_id].onready_callback();
            }
        }, !this.embedded[pano_id].refreshed ? 400 : 0);
    }

    clickContactData(pano_id) {
        runAction({action: 'company/contact', id_company: this.embedded[pano_id].data.company.id});
    }

    clickGoogleMap(pano_id) {
        showModal(core.lang.get('company.google_map'), 'Map')
    }

    clickOpenStreetMap(pano_id) {
        showModal(core.lang.get('company.open_street_map'), 'Map')
    }

    clickGallery(pano_id, gallery) {
        showModal(core.lang.get('company.gallery'), '<img src="' + gallery.url + '" class="gallery-modal-image">');
    }

    clickVideo(pano_id, video) {
        showModal(core.lang.get('video.video'), core.template.render(core.template.video.player, {video_url: video.video_url, video_id: video.video_id}));
    }
    
    get() {
        return this.embedded['krpanoSWFObject'].krpano;
    }
    
    editHotspot(pano_id, hs_name) {
        var krpano = this.embedded[pano_id].krpano;

        $('#mh').remove();
        $('#mhc').remove();
        var $div = $('<div id="mh" style="position: fixed; bottom: 5px; left: 5px; width: 300px; padding: 10px; background: white; border: 2px solid black; z-index: 10000;"><b>' + hs_name + '</b></div');
        $('body').append($div);
        ['ath', 'atv', 'rx', 'ry', 'rz', 'width', 'height', 'scale', 'tx', 'ty', 'tz'].forEach((key) => {
            var value = krpano.get("hotspot[" + hs_name + "]." + key);

            var inp = $(`<input type='text' name='${key}' value='${value}' />`).keyup(function () {
                //hs_params[key] = $(this).val();
                krpano.set("hotspot[" + hs_name + "]." + key, $(this).val());

                var code = '';
                $('#mh').find('input').each(function () {
                    var v = $(this).val();
                    if (v != '' && v != '0') {
                        code += $(this).attr('name') + ': ' + v + ',<br />'
                    }
                });
                $('#mhc').html(code);
            });

            var html = $(`<div>${key} </div>`).append(inp);
            $div.append(html);
        });

        var $divcode = $('<div id="mhc" style="position: fixed; bottom: 5px; left: 310px; width: 300px; padding: 10px; background: white; border: 2px solid black; z-index: 10000;" />');
        $('body').append($divcode);
    }
    
    isVisible(pano_id) {
        return this.embedded[pano_id] !== undefined && this.embedded[pano_id].id !== undefined && $('#'+this.embedded[pano_id].id).length > 0;
    }
    
    initMap(url) {
        if (url) {
            var regex = new RegExp('@(.*),(.*),');
            var lat_long_match = url.match(regex);

            if (lat_long_match && lat_long_match.length > 2) {
                var lat = parseFloat(lat_long_match[1]);
                var lng = parseFloat(lat_long_match[2]);

                // The location of Uluru
                const uluru = {lat, lng };
                // The map, centered at Uluru
                const map = new google.maps.Map(document.getElementById("preview-company-map"), {
                    zoom: 12,
                    center: uluru,
                });
                // The marker, positioned at Uluru
                const marker = new google.maps.Marker({
                    position: uluru,
                    map: map,
                });
            } else {
                console.log('Incorrect google map link');
            }
        }
    }
    
    addCompanyDataLayer(pano_id) {
        $('#company-data-layer').remove();
        $('body').append(core.template.render(core.template.company.show_company_data_layer, this.embedded[pano_id].data));

        const observer = new MutationObserver(function (mutations_list) {
            mutations_list.forEach(function (mutation) {
                mutation.removedNodes.forEach(function (removed_node) {
                    if ($(removed_node).hasClass('show-company')) {
                        $('#company-data-layer').remove();
                    }
                });
            });
        });

        observer.observe(document.querySelector('.container'), { subtree: true, childList: true });
        this.initMap(this.embedded[pano_id].data.company.google_map);
    }

    setData(pano_id, data, join_data = true) {
        if (this.embedded.hasOwnProperty(pano_id) && join_data) {
            this.embedded[pano_id].data = {...this.embedded[pano_id].data, ...data};

            if(this.embedded[pano_id].stand.stand_number != data.company.stand) {
                this.setEmbedded(pano_id, data);
            }
        } else {
            this.setEmbedded(pano_id, data);
        }
    }

    setEmbedded(pano_id, data) {
        console.log(pano_id);
        // Create stand class object.
        var classname = 'Stand' + data.company.stand;

        this.embedded[pano_id] = {
            pano_id,
            refreshed: 0,
            hotspot_names: {},
            krpano: false,
            data,
            stand: new (eval(classname))(this, pano_id, window.localStorage.getItem('krpanoEditMode') != '1' ? 3000 : 0)
        };

    }

    edit(enabled) {
        window.localStorage.setItem('krpanoEditMode', enabled);
        if (window.localStorage.getItem('krpanoEditMode')) {

        }

        this.checkEditMode();
    }

    checkEditMode() {
        var self = this;
        if (window.localStorage.getItem('krpanoEditMode') == 1) {
            for (var pano_id in this.embedded) {
                var krpano = this.embedded[pano_id].krpano;
                var hotspot_count = krpano.get('hotspot.count');

                for (var i = 0; i < hotspot_count; i++) {
                    var hs_name = krpano.get('hotspot[ ' + i + '].name');
                    krpano.set("hotspot[" + hs_name + "].onclick", function (hs_name) {
                        self.editHotspot(pano_id, hs_name);
                    }.bind(null, hs_name));
                }
            }
        }
    }

    render(pano_id, mode, reset = true, onready_callback) {
        if (!this.embedded.hasOwnProperty(pano_id)) {
            alert('Set krpano data before rendering!');
        }

        if (reset || !$('#' + 'krpano-' + pano_id).length) {
            $('#' + pano_id).html('');
            delete this.embedded[pano_id].krpano;
        }

        if (!this.embedded[pano_id].krpano) {
            embedpano({
                swf: core.config.home + "/public/js/krpano/krpano.swf",
                id: 'krpano-' + pano_id,
//                xml: core.config.home + "/public/js/krpano/stand_" + this.embedded[pano_id].data.company.stand + "/stand.xml",
                xml: core.config.home + "/public/js/krpano/stand_" + this.embedded[pano_id].data.company.stand + "/indexdata/index.xml",
                target: pano_id,
                consolelog: true,
                passQueryParameters: true,
                onready: (krpano) => {
                    this.embedded[pano_id].id = 'krpano-' + pano_id;
                    this.embedded[pano_id].krpano = krpano;
                    this.embedded[pano_id].mode = mode;
                    this.embedded[pano_id].refreshed = 0;
                    this.embedded[pano_id].onready_callback = onready_callback;
                    this.renderHotspots(pano_id);
                }
            });
        } else {
            this.embedded[pano_id].id = 'krpano-' + pano_id;
            this.embedded[pano_id].mode = mode;
            this.embedded[pano_id].onready_callback = onready_callback;
            this.renderHotspots(pano_id);
        }

        this.addCompanyDataLayer(pano_id);
    }

    addHotspot(pano_id, hs_params) {
        var hs_name = hs_params.hs_name;
        if (!this.embedded.hasOwnProperty(pano_id)) {
            alert('Set krpano data before rendering!');
        }

        hs_params.distorted = true;
        hs_params.capture = false;

//        if (this.embedded[pano_id].hotspot_names.hasOwnProperty(hs_name)) {
//            this.removeHotspot(pano_id, hs_name);
//        }

        var krpano = this.embedded[pano_id].krpano;
        krpano.call("addhotspot(" + hs_name + ")");
        for (var k in hs_params) {
            switch (k) {
                case 'hs_name':
                    break;
                case 'click':
                    //console.log(hs_params[k]);
                    if (krpano.get("device.html5")) {
                        krpano.set("hotspot[" + hs_name + "].onclick", hs_params[k].bind(this, hs_name));
                    }
                    break;
                case 'calls':
                    for (var c in hs_params[k]) {
                        var call = hs_params[k][c];
                        krpano.call(call);
                    }
                    break;
                default:
                    krpano.set("hotspot[" + hs_name + "]." + k, hs_params[k]);
            }
        }

        this.embedded[pano_id].hotspot_names[hs_name] = {};
        return hs_name;
    }

    removeHotspot(pano_id, hs_name) {
        if (!this.embedded.hasOwnProperty(pano_id)) {
            alert('Set krpano data before rendering!');
        }

        var krpano = this.embedded[pano_id].krpano;

        krpano.call("removehotspot(" + hs_name + ")");
        delete this.embedded[pano_id].hotspot_names[hs_name];
    }

    removeAllHotspots(pano_id) {
        if (this.embedded[pano_id] && this.embedded[pano_id].krpano) {
            var krpano = this.embedded[pano_id].krpano;
            var hotspot_count = krpano.get('hotspot.count');

            for (var i = hotspot_count - 1; i >= 0; i--) {
                var hs_name = krpano.get('hotspot[ ' + i + '].name');
                this.removeHotspot(pano_id, hs_name);//
            }
        }
    }

    addFairButtonRight(pano_id, callback) {
        var krpano = this.embedded[pano_id].krpano;
        var hs_name = 'button_right';
        krpano.call("addlayer(" + hs_name + ")");
        krpano.set("layer[" + hs_name + "].url", "public/img/arrow_right.png");
        krpano.set("layer[" + hs_name + "].align", 'rightbottom');
        krpano.set("layer[" + hs_name + "].x", 16);
        krpano.set("layer[" + hs_name + "].y", 26);
        krpano.set("layer[" + hs_name + "].width", 80);
        krpano.set("layer[" + hs_name + "].height", 80);
        krpano.set("layer[" + hs_name + "].onclick", callback);
    }

    addFairButtonLeft(pano_id, callback) {
        var krpano = this.embedded[pano_id].krpano;
        var hs_name = 'button_left';
        krpano.call("addlayer(" + hs_name + ")");
        krpano.set("layer[" + hs_name + "].url", "public/img/arrow_left.png");
        krpano.set("layer[" + hs_name + "].align", 'leftbottom');
        krpano.set("layer[" + hs_name + "].x", 16);
        krpano.set("layer[" + hs_name + "].y", 26);
        krpano.set("layer[" + hs_name + "].width", 80);
        krpano.set("layer[" + hs_name + "].height", 80);
        krpano.set("layer[" + hs_name + "].onclick", callback);
    }
    
    addFairButtonMap(pano_id, callback) {
        var krpano = this.embedded[pano_id].krpano;
        var hs_name = 'button_map';
        krpano.call("addlayer(" + hs_name + ")");
        krpano.set("layer[" + hs_name + "].url", "public/img/map.png");
        krpano.set("layer[" + hs_name + "].align", 'centerbottom');
        krpano.set("layer[" + hs_name + "].y", 26);
        krpano.set("layer[" + hs_name + "].width", 80);
        krpano.set("layer[" + hs_name + "].height", 80);
        krpano.set("layer[" + hs_name + "].onclick", callback);
    }
    
    addFairButtonChat(pano_id, callback) {
        var krpano = this.embedded[pano_id].krpano;
        var hs_name = 'button_chat';
        krpano.call("addlayer(" + hs_name + ")");
        krpano.set("layer[" + hs_name + "].url", "public/img/map.png");
        krpano.set("layer[" + hs_name + "].align", 'centerbottom');
        krpano.set("layer[" + hs_name + "].y", -40);
        krpano.set("layer[" + hs_name + "].width", 80);
        krpano.set("layer[" + hs_name + "].height", 80);
        krpano.set("layer[" + hs_name + "].onclick", callback);
    }
}

const krpano2 = new Krpano();
var krpano;