/*
 * Displays content page like About us, Contact etc.
 */

class Page {
    timer_interval = false;

    constructor() {
        this.data = { };
        this.blue_tip_shown = 0;
        addPromise(this, 'readyForActions');
        this.ret_getShown = false;

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    setTimer(d, n) {
        if (n < d) {
            var t = (d - n) / 1000;
            var dd = parseInt(t / 86400);
            t = t - dd * 86400;
            var h = parseInt(t / 3600);
            t = t - h * 3600;
            var m = parseInt(t / 60);
            t = parseInt(t - m * 60);

            var text_html = '';
            if (dd > 1) {
                text_html += dd+' days, '
            } else {
                text_html += dd+' day, '
            }

            if (h > 1) {
                text_html += h+' hours, '
            } else {
                text_html += h+' hour, '
            }

            if (m > 1) {
                text_html += m+' minutes, '
            } else {
                text_html += m+' minute, '
            }

            if (t > 1) {
                text_html += t+' seconds left!'
            } else {
                text_html += t+' second left!'
            }

            if (dd < 10) {
                dd = '0'+dd;
            }

            if (h < 10) {
                h = '0'+h;
            }

            if (m < 10) {
                m = '0'+m;
            }
            if (t < 10) {
                t = '0'+t;
            }

            var html = '';
            var all = dd+':'+h+':'+m+':'+t;
            for (var i in all) {
                if (all[i] == ':') {
                    html += '<span></span>'
                } else {
                    html += '<b>'+all[i]+'</b>'
                }
            }

            $('.timer').html(`${html}`);
            $('.timertext').html(`${text_html}`);
        } else {
            $('.timer').html('');
        }
    }

    async getShownFair() {
        if (!this.ret_getShown) {
            this.ret_getShown = await core.libs.ws.sendAndWait({
                action: 'fair/getShown'
            });

            for (var i in this.ret_getShown.fairs) {
                if (this.ret_getShown.fairs[i].id == this.ret_getShown.current_fair_id) {
                    this.current_fair = this.ret_getShown.fairs[i];
                    app.fair.handleKeywords(this.current_fair);
                }
            }
        }

        return this.ret_getShown;
    }

    async unlockAction() {
        window.localStorage.setItem('unlock_page', true);
        this.reloadAction();
    }

    async policyAction() {
        $('#content').html(core.template.render("<div class='box'>"+core.template.user['regulation_'+core.lang.language]+"</div>", { }));
    }

    async contactAction() {
        $('#content').html(core.template.render(core.template.page.contact, { }));
    }

    async searchAction() {
        $('#content').html(core.template.render(core.template.page.search, { }));
        this.companyAction({companies_display_index: 12 });
    }

    async sellAction() {
        $('#content').html(core.template.render(core.template.page.sell, { }));
    }

    async infohubAction() {
        $('#content').html(core.template.render(core.template.page.info_hub, { }));
    }

    async pricelistAction(data) {
        var self = this;

       // await app.company.showStudioMenu('settings', 'packages');

        var show_buy_credits_button = false;

        $('#content').html('<div class="studio-container pricelist"><div class="studio-items-container">'+core.template.render(core.template.user.packages, {
            pricelist_mode: true,
            level_1_credits_for_month: core.config.level_1_credits_month,
            level_1_credits_for_year: core.config.level_1_credits_year,
            level_2_credits_for_month: core.config.level_2_credits_month,
            level_2_credits_for_year: core.config.level_2_credits_year,
            level_3_credits_for_month: core.config.level_3_credits_month,
            level_3_credits_for_year: core.config.level_3_credits_year,

            level_1_number_of_spaces: core.config.number_of_public_spaces_for_level_1,
            level_2_number_of_spaces: core.config.number_of_public_spaces_for_level_2,
            level_3_number_of_spaces: core.config.number_of_public_spaces_for_level_3,
            level_1_gallery_photos: core.config.number_of_files_in_library_for_level_1_package,
            level_2_gallery_photos: core.config.number_of_files_in_library_for_level_2_package,
            level_3_gallery_photos: core.config.number_of_files_in_library_for_level_3_package,
            // level_1_days_text: ret.level_1_days_text,
            // level_2_days_text: ret.level_2_days_text,
            // level_3_days_text: ret.level_3_days_text
            level_1_package_name: core.config.level_1_name,
            level_2_package_name: core.config.level_2_name,
            level_3_package_name: core.config.level_3_name,
            show_buy_credits_button: show_buy_credits_button,
            level_1_number_of_urls: core.config.level_1_number_of_urls,
            level_2_number_of_urls: core.config.level_2_number_of_urls,
            level_3_number_of_urls: core.config.level_3_number_of_urls,
        })+'</div></div>');


        $('.choose-currency button').on('click', function() {
            $(this).parent().find('.selected').removeClass('selected')
            $(this).addClass('selected');
            let cur = $(this).data('change-currency');
            $("[data-currency][data-currency!='"+cur+"']").hide();
            $("[data-currency='"+cur+"']").show();
            return false;
        });
        $('.choose-currency button:first-child').trigger('click');

        var period_button = $('.studio-items-container .package-period-button');

        period_button.click(function() {
            var self = $(this);
            var period = self.data('period');
            period_button.removeClass('selected');
            self.addClass('selected');
            var table = $('.studio-items-container table.packages');

            table.find('tr.period-costs').hide();
            table.find('tr.period-costs.period-' + period).show();
            table.find('tr.period-buy').hide();
            table.find('tr.period-buy.period-' + period).show();
        })
     }

    async isFairCompany() {
        if (this.company) {
            return this.company;
        }

        await this.getShownFair();
        if (this.current_fair) {
            for(var i in this.current_fair.map) {
                if (this.current_fair.map[i].id_user == app.user.id) {
                    $('.company_name').html(this.current_fair.map[i].name+' '+core.lang.get('page.dashboard'));

                    if (!this.company) {
                        this.company = await core.libs.ws.sendAndWait({
                            action: 'fair/get',
                            id: this.current_fair.id,
                            index: this.current_fair.map[i].index
                        });

                        this.company = this.company.company ? this.company.company.company : null;
                    }

                    return true;
                }
            }
        }

        return false;
    }

    async getCurrentFair() {
        await this.getShownFair();
        return this.current_fair
    }

    async reloadAction() {
        window.location = '/';
        runActionUrl('explore');
        window.location.reload(true);
    }

    async setAction(request) {
       this.data = request.data;
    }

    async homeAction(request) {
        runActionUrl('explore');
    }

    async exploreAction(request) {
        this.companyAction({companies_display_index: 12 });
        this.groupsAction({groups_display_index: 6});
        $('#content').html(core.template.render(core.template.page.home, this.data));
    }

    async underconstructionAction(request) {
        $('#content').html(core.template.render(core.template.page.under_construction, this.data));
    }

    async groupsAction(request) {

        var groups_display_index = request.groups_display_index ? request.groups_display_index : 6;

        var ret = await core.libs.ws.sendAndWait({
            action: 'group/all',
            members_groups_display_index: groups_display_index
        });

        if(ret.most_members_groups.length) {
            ret.most_members_groups.forEach((mmb) => {
                ret.langs.forEach((lang) => {
                    mmb['display_name_' + lang] = mmb['name_' + lang].length > 30 ? mmb['name_' + lang].slice(0, 30) + '...' : mmb['name_' + lang];
                })
            })
        }

        var ret2 = await core.libs.ws.sendAndWait({
            action: 'user/checkBusinessPackageDate'
        });

        $('#content .groups-list').html(core.template.render(core.template.page.groups, {
            groups_count: ret.most_members_groups_count,
            groups: ret.most_members_groups,
            current_groups_display: ret.most_members_groups.length,
            can_add_new_group: ret2.business_package_valid,
            see_more_groups: parseInt(ret.most_members_groups_count) > 0 && parseInt(ret.most_members_groups_count) > parseInt(ret.most_members_groups.length) ? true : false,
        }));

    }

    async companyAction(request) {
        var url_params = getUrlParams('page/company');
        var companies_display_index = request.companies_display_index ? request.companies_display_index : 12;

        var ret = await core.libs.ws.sendAndWait({
            action: 'page/loadDataCompanies',
            companies_display_index: companies_display_index,
            id_industry: url_params ? url_params.industry : null
        });

        for (let i in ret.companies) {
            ret.companies[i].show_city_country = ret.companies[i]['city_'+core.lang.language] && ret.companies[i]['country_'+core.lang.language]
        }

        $('#content .companies-list').html(core.template.render(core.template.page.companies, {
            companies: ret.companies,
            current_companies_display: ret.companies.length,
            id_industry: url_params ? url_params.industry : null,
            see_more_companies: parseInt(ret.companies_count) > parseInt(ret.companies.length) ? true : false,
        }));

        if(url_params.industry) {
            $('.home .companies.box a.industry-name').removeClass('selected');
            $('.home .companies.box a.industry-name[data-industry="'+url_params.industry+'"]').addClass('selected');
        }
    }

    async homeHydrogenAction() { // Targi
        var self = this, goto;
        clearInterval(this.timer_interval);

        var ret = await this.getShownFair();

        var map_class = '';
        $('.page-container').removeClass('current_fair_hide').removeClass('current_fair_active');

        var n, d;
        if (this.current_fair && (this.current_fair.show || this.fair_open_force)) {
            d = new Date(this.current_fair.startdate);
            var tmp = this.current_fair.starthour.split(':');
            d.setHours(tmp[0]);
            if (tmp[1]) {
                d.setMinutes(tmp[1]);
            }

            n = new Date();

            map_class = n > d ? 'current_fair_active' : 'current_fair_hide';

            if (n < d) {
                this.timer_interval = setInterval(() => {
                    var n = new Date();
                    this.setTimer(d, n);
                    if (n > d) {
                        $('.page-container').removeClass('current_fair_hide').addClass('current_fair_active');
                        clearInterval(this.timer_interval);
                    }
                }, 1000);
            }

            $('.page-container').addClass(map_class);

            if (window.location.pathname != '/') {
                for(var i in this.current_fair.map) {
                    if ('/'+this.current_fair.map[i].url == window.location.pathname) {
                        goto = this.current_fair.map[i];
                    }
                }
            }
        } else {
            $('.buttons_right').remove();
        }

        if (goto) {
            window.history.pushState("", "", '/');
            runActionHash(`fair/show/id/${this.current_fair.id}/index/${goto.index}`);
        }

        var map = this.current_fair && (this.current_fair.show || this.fair_open_force) ? await app.fair.showMapPlain(this.current_fair) : '';
        //var map = this.current_fair ? await app.fair.showMap(this.current_fair, false, false) : '';
        $('#content').html(core.template.render(core.template.page.home, {
            fairs: ret.fairs,
            current_fair: this.current_fair,
            show_fair: this.current_fair && (this.current_fair.show || this.fair_open_force) ? true : false,
            has_fairs: ret.fairs.length,
            map
        }));

        $('#content .fair_spaces > div > div > a').hover(function() {
            if ($(this).is(":hover")) {
                $(this).parent().find('img.space').css({
                    filter: "none",
                });
            } else {
                $(this).parent().find('img.space').css({
                    filter: "brightness(70%)",
                });
            }
        });

        $('body').find('.button_search_form').off('click').click(() => {
            app.fair.searchCompany(this.current_fair, false);
        });

        this.setTimer(d, n);

        $('.fairs .card').click(function() {
            var id_fair = $(this).data('id');
            app.page.showMapById(ret.fairs, id_fair);
        });

        setTimeout(function() {
            $('.fair_search_form input').keyup();
        }, 50);
    }

    groupAction() {
        this.fair_open_force = true;
        runActionHash('home');
    }

    async showMapById(fairs, id, modal = true) {
        const fair = fairs.find(element => element.id == id);
        if (fair) {
            var map = await app.fair.showMap(fair, modal, false);
        }

        return map;
    }

    async iframeAction() {
        var content = $('#content');
        content.html(core.template.render(core.template.page.iframe, {  }));
    }

    resetAction() {
        app.user.logoutAction();
    }

    pricingAction() {
        var content = document.getElementById('content');
        content.innerHTML = core.template.render(core.template.page.pricing, { });
    }

    showBlueTip() {
        $('.blue-tip').fadeIn();
        setTimeout(() => {
            $('.blue-tip').fadeOut();
        }, 4000);

        this.blue_tip_interval = setTimeout(() => { app.page.showBlueTip() }, 8000);
        this.blue_tip_shown = 1;
    }

    onReady() {
        for(var group of this.data.industryA) {
            group.name = group['name_'+core.lang.language];
            group.city = group['city_'+core.lang.language];
            group.country = group['country_'+core.lang.language];
        }

        for(var group of this.data.industryB) {
            group.name = group['name_'+core.lang.language];
            group.city = group['city_'+core.lang.language];
            group.country = group['country_'+core.lang.language];
        }

        //triggerUrl();

        resolvePromise(this, 'readyForActions')

        if (core.config.add_to_head_with_delay) {
            setTimeout(() => {
                $('head').append(core.config.add_to_head_with_delay);
            }, core.config.add_to_head_with_delay_timeout ? core.config.add_to_head_with_delay_timeout : 300);
        }
    }

    onLayoutReady() {
        $(document).on("click", 'a.open-help-modal', function() {
            let url = $(this).attr('href')+'-'+core.lang.language;
            let html = core.template.render(core.template.page.help_modal, { url });
            let modal = showModal(core.lang.get('user.help'), html, undefined, true, { width: 1200 });
            return false;
        });
    }

    async onTriggerUrl(data) {
        if (data.action == undefined || data.action == '') {
            data.action = core.config.action_on_empty_url ? core.config.action_on_empty_url : 'home';
        }

        if (data.action == core.config.action_on_empty_url && (app.user.is_regular || !app.user.groups.logged)) {
            //$('body').addClass('show_register_button');
        } else {
            $('body').removeClass('show_register_button');
        }

        if (!app.user.groups.company) {
            if(data.action != 'user/profile' && data.action != 'user/accountType' && data.action != 'company/studio' && data.action != 'company/create') {
                if (!this.blue_tip_shown) {
                    this.blue_tip_interval = setTimeout(() => { app.page.showBlueTip() }, 3000);
                    this.blue_tip_shown = 1;
                }
            }
        } else {
            this.blue_tip_shown = 0;
            clearTimeout(this.blue_tip_interval);
            $('.blue-tip').fadeOut();
        }
    }

    async error404Action() {
        var content = document.getElementById('content');
        content.innerHTML = core.template.render(core.template.page.error404, { });
    }
}

app.page = new Page();

showCurrentFair = function() {
    $('.page-container').removeClass('current_fair_hide').addClass('current_fair_active');
}

function delay(delayInms) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, delayInms);
  });
}


var test_clients = [];
var testPerformance = async function(time = 50, number_of_test_clients = 200) {
    console.log('testPerformance');
    if (core.config.performance_test) {
        console.log('connecting');

        for(var i = 0; i < number_of_test_clients; i++) {
            var ws = new Ws();
            test_clients.push(ws);
            await delay(time);
        }

        initial_params = {
            language: window.localStorage.getItem('language'),
            session: window.localStorage.getItem('session'),
            tempid: window.localStorage.getItem('tempid'),
        }


        console.log('get all start');
        for(var i in test_clients) {
            test_clients[i].sendJson({
                action: '*/getAll',
                initial_params: initial_params
            });
        }

        await delay(number_of_test_clients * time);
        console.log('get all end');

        await delay(1000);
        console.log('logging in start');
        for(var i in test_clients) {
            var ret = await test_clients[i].sendAndWait({
                action: 'chat/show',
                id_receiver: 1,
                data: { code: 'U1G0' },
            });

            console.log('access_success', ret.access_success, ret.name, ret.email);
        }

        console.log('logging in end');

        return;
    } else {
        console.log('Disabled');
    }
}

var testChat = async function(time = 100) {
    if (core.config.performance_test) {
        for(var i = 0; i < 100; i++) {
            var ret = await core.libs.ws.sendAndWait({
                action: 'chat/send',
                id_chat: app.chat.chat.id_chat,
                panel: false,
                message: { message: 'Testing message '+Date.now(), created: Date.now() }
            });

            await delay(time);
            console.log('Done '+i);
        }
    }
}