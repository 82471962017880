class Fair {

    constructor() {
        this.current_search_text = '';
        this.companies_skip = [];
        this.fairs = {};
        
        // postMessage
        window.addEventListener("message", (event) => {
            if (event.data && typeof event.data == 'string') {
                var tmp = event.data.trim();
                var e;
                eval("e = "+tmp);
                if (e && e.url) {
                    var modal = showModal('', `<iframe style='height: 65vh; ' class='modal_iframe' src="${e.url}"></iframe>`, undefined, true, { width: 1200 });
                    modal.onhide(function () {
                        modal.setBody('');
                    });
                }
            }
        }, false);
    }

    async listAction(data) {
        var self = this;

        (async () => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'fair/getList'
            });
            
            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }
            
            $('#content').html(core.template.render(core.template.fair.list, { fairs: ret.fairs, exists: ret.fairs.length > 0 }));
        })();

        return false;
    }
            
    async openAction() {
        runActionHash('home')
        setTimeout(() => { showCurrentFair(); }, 500);
    }
    
    async showAction(data) {
        var self = this, ret;
        var index = parseInt(data.index) || 1;
        if (isNaN(index)) {
            index = 1;
        }
        
        var ret = { fair: await app.page.getCurrentFair() };
        if (ret.fair && ret.fair.map) {
            ret = await core.libs.ws.sendAndWait({
                action: 'fair/get',
                id: data.id,
                index,
                company_only: true
            });
            
            ret.fair = await app.page.getCurrentFair();
        } else {
            var ret = await core.libs.ws.sendAndWait({
                action: 'fair/get',
                id: data.id,
                index
            });        
        }

        ret.show_contact =  true; // ret.company.company.meeting_url || ret.company.company.bookafy_url;
        
        $('#content').html(core.template.render(core.template.fair.show, ret));
        
        var prev = index - 1;
        if (prev < 1 || prev == 1 && self.companies_skip.indexOf(prev) != -1) {
            prev = ret.fair.map.length;
        }

        for (; true; prev--) {
            if (prev == 0) {
                prev = ret.fair.map.length;
            }
            if (prev == ret.index) {
                prev = -1;
                break;
            }
            if (self.companies_skip.indexOf(prev) == -1) {
                break;
            }
        }
        
        var next = index + 1;
        if (next > ret.fair.map.length || next == ret.fair.map.length && self.companies_skip.indexOf(next) != -1) {
            next = 1;
        }

        for (; true; next++) {
            if (next == ret.fair.map.length + 1) {
                next = 1;
            }
            if (next == ret.index) {
                break;
            }

            if (self.companies_skip.indexOf(next) == -1) {
                break;
            }
        }
        
        $('.buttons_bottom').find('.left *').css('visibility', prev == index ? 'hidden' : 'visibile');
        $('.buttons_bottom').find('.right *').css('visibility', next == index ? 'hidden' : 'visibile');
        
        for (var i in ret.fair.map) {
            if (ret.fair.map[i].index == prev) {
                $('.buttons_bottom').find('.left > div').css('background-image', 'url('+ret.fair.map[i].logo_url+')')
            }
            if (ret.fair.map[i].index == next) {
                $('.buttons_bottom').find('.right > div').css('background-image', 'url('+ret.fair.map[i].logo_url+')')
            }
        }
        
//        console.log(prev, index, next, ret.fair.map.length);
//        console.log(ret.company.company);
        
        if (prev == -1) {
            $('.buttons_bottom').find('.left').hide();
        } else {
            $('.buttons_bottom').find('.left').show();
        }
        $('.buttons_bottom').find('.left').off('click').click(function() {
            if (prev != -1) {
                runActionHash('fair/show/id/'+data.id+'/index/'+prev);
                app.chat.hideChat();
            }
        });
        
        if (ret.fair) {
            $('#content').find('.button_map').off('click').click(() => {
                runActionHash('home');
            });
        }
        
        if (ret.show_contact) {            
            $('.buttons_bottom').find('.contact').off('click').click(async function() {
                app.chat.hideChat();
                await app.chat.showAction({ company: ret.company.company, id_user: ret.company.company.id_user, id_fair: ret.fair.id, index });                
            });
        }
        
        /*$('.buttons_bottom').find('.chat').off('click').click(function() {
            app.chat.hideChat();
            app.chat.showAction({ id_user: ret.company.company.id_user });
        });*/
        
        if (next == -1) {
            $('.buttons_bottom').find('.right').hide();
        } else {
            $('.buttons_bottom').find('.right').show();
        }
        $('.buttons_bottom').find('.right').off('click').click(function() {
            if (next != -1) {
                runActionHash('fair/show/id/'+data.id+'/index/'+next);
                app.chat.hideChat();
            }
        });
        
        if (ret.show_contact) {
            $('.buttons_bottom').find('.button_contact').off('click').click(function() {
                var html = core.template.render(core.template.company.company_data, { company: ret.company.company });
                var modal = showModal(ret.company.company.name, html, undefined, false, { width: 600, /* animation: 'slide-in-left' */ });
            });
        } else {
            $('.buttons_bottom').find('.button_contact').hide();
        }
    }
    
    async showKrpanoAction(data) {
        var self = this;
        var ret = await core.libs.ws.sendAndWait({
            action: 'fair/get',
            id: data.id,
            index: data.index || 1,
        });

        if (ret.error) {
            showModalFail(ret.message, 5000);
            return;
        }
        
        if (!krpano.isVisible('preview-pano')) {
            $('#content').html(core.template.render(core.template.fair.show, ret ));        
        } else {
            krpano.removeAllHotspots('preview-pano');
        }
        
        app.company.updateKrpano('preview-pano', ret.company, async() => {
            krpano.addFairButtonRight('preview-pano', async () => {
                var index = ret.index + 1;
                if (index > ret.fair.map.length) {
                    index = 1;
                }
                runActionHash('fair/show/id/'+data.id+'/index/'+index);
            });
            
            krpano.addFairButtonLeft('preview-pano', async () => {               
                var index = ret.index - 1;
                if (index < 1) {
                    index = ret.fair.map.length;
                }
                runActionHash('fair/show/id/'+data.id+'/index/'+index);
            });
            
            krpano.addFairButtonMap('preview-pano', async () => {               
                this.showMap(ret.fair.id, ret.fair.map);
            });
            
            krpano.addFairButtonChat('preview-pano', async () => {               
                app.chat.showAction({ id_receiver: ret.company.company.id_user });
            });
        });
    }
    
    async showMap(fair, modal = true, search_on_modal = true) {
        var offset_x = 99999, offset_y = 99999;
        for (var i in fair.map) {
            if (fair.map[i].x < offset_x) {
                offset_x = fair.map[i].x;
            }
            if (fair.map[i].y < offset_y) {
                offset_y = fair.map[i].y;
            }
        }
        
        var map = core.template.render(core.template.fair.map, { map: fair.map, callback: 'app.fair.mapCallback('+fair.id+', true)', offset_x, offset_y });
        if (modal) {
            var modal = showModal(core.lang.get('fair.stands_map'), map, undefined, true, {width: 1200});
        } else {
            return map;
        }
    }
    
    async showMapPlain(fair) {
        var map = core.template.render(core.template.fair.map_plain, { map: fair.map, callback: 'app.fair.mapCallback('+fair.id+', true)' });
        return map;
    }
    
    mapCallback(id_fair, hash_action) {
        $('#fair_map > div').click(function() {
            if (!$(this).hasClass('off')) {
                var index = $(this).data('index');
                if (!hash_action) {
                    app.fair.showAction({ id: id_fair, index, no_refresh: true });
                    hideModal();
                } else {
                    runActionHash('fair/show/id/'+id_fair+'/index/'+index);
                    hideModal();
                }
            }
        });
    }

    async editAction(data) {        
        (async () => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'fair/edit',
                id: data ? data.id : 0,
            });
            
            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }
            
            var modal = showModal(
                core.lang.get('fair.edit_fair'), 
                core.template.render(core.template.fair.form, { form: ret.form, values: ret.fair, fair: ret.fair }),
                [ 
                    { label: core.lang.get('common.close'), class: 'btn-secondary', close: true },
                    { label: core.lang.get('common.save'), class: 'btn-primary', onclick: function() {
                            $('#' + ret.form.id).submit();
                    }},
                ]
            );

            var $form = $('#' + ret.form.id);
            core.libs.form.handle($form, ret, async(data) => {
                var ret = await core.libs.ws.sendAndWait({
                    action: 'fair/edit',
                    data
                });

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }

                if (ret.success) {
                    modal.hide();
                    showModalSuccess(ret.message, 5000);
                    //runActionHash('fair/list');
                    await this.listAction();
                }
            });
        })();

        return false;
    }
    
    async deleteAction(data) {        
        var ret = await core.libs.ws.sendAndWait({
            action: 'fair/delete',
            id: data.id,
        });

        if (ret.error) {
            showModalFail(ret.message, 5000);
        }

        if (ret.success) {
            showModalSuccess(ret.message, 5000);
        }

        runActionHash('fair/list');
    }
    
    async mapEditAction(data) {        
        (async () => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'fair/editMap',
                id: data ? data.id : 0,
            });
            var min_x = 0, min_y = 0;
            for (var i in ret.fair.map) {
                if (ret.fair.map[i].x < min_x) {
                    min_x = ret.fair.map[i].x;
                }
                if (ret.fair.map[i].y < min_y) {
                    min_y = ret.fair.map[i].y;
                }
            }
            
            for (var i in ret.fair.map) { 
                ret.fair.map[i].x += -min_x;
                ret.fair.map[i].y += -min_y;
            }
            
            $('#content').html(core.template.render(core.template.fair.form_map, { form: ret.form, values: ret.fair, fair: ret.fair }));

            var $form = $('#' + ret.form.id);
            core.libs.form.handle($form, ret, async(data) => {
                var ret = await core.libs.ws.sendAndWait({
                    action: 'fair/editMap',
                    data
                });

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }

                if (ret.success) {
                    showModalSuccess(ret.message, 5000);
                    runActionHash('fair/list');
                }
            });
        })();

        return false;
    }
    
    handleKeywords(fair) {
        for(var j in fair.map) {
            if (!fair.map[j].keywords) {
                fair.map[j].keywords = [];
                fair.map[j].keywords.push(fair.map[j].name);
                fair.map[j].keywords.push(fair.map[j].country);
//                if (fair.map[j].industry) {
//                    var tmp = fair.map[j].industry.split(',');
//                    fair.map[j].keywords = fair.map[j].keywords.concat(tmp);
//                }
                
                fair.map[j].keywords = fair.map[j].keywords.join('||').toLowerCase();
            }
        }
    }
    
    searchCompany(fair, $element) {
        var self = this;
        var search_form_html = core.template.render(core.template.fair.search_form, { fair });
        
        if (!$element) {
            showModal(core.lang.get('fair.search_for_companies'), search_form_html, undefined, false);
        } else {
            $element.prepend(search_form_html);
        }

        self.hidden = false;
        $('.fair_search_form input').val(this.current_search_text).keyup(function() {
            $('.fair_search_form div').removeClass('sh');
            $('.fair_search_form div').removeClass('hd');
            $('#fair_map > div[data-id]').css({ opacity: 1 }).removeClass('off');
            self.current_search_text = $(this).val();
            if (self.current_search_text) {
                $('.button_clear').show();
            } else {
                $('.button_clear').hide();
            }
            var texts = self.current_search_text.toLowerCase().split(' ');
            self.companies_skip = [];
            
            for(var i in fair.map) {
                $('.fair_search_form div[search-company-data-id="'+fair.map[i].id+'"]').addClass('sh');
                for(var t in texts) {
                    var text = texts[t].trim();                    
                    if (text && fair.map[i].keywords.indexOf(text) == -1) {
                        self.companies_skip.push(fair.map[i].index);
                        $('#fair_map > div[data-id="'+fair.map[i].id+'"]').css({ opacity: 0.15 }).addClass('off');
                        $('.fair_search_form div[search-company-data-id="'+fair.map[i].id+'"]').addClass('hd');
                        $('.fair_search_form div[search-company-data-id="'+fair.map[i].id+'"]').removeClass('sh');
                        break;
                    }
                }
            }

            if (self.hidden) {
                $('.fair_search_form div.hd').slideUp();
            } else {
                $('.fair_search_form div.hd').hide();
            }
            $('.fair_search_form div.sh').slideDown();
            self.hidden = true;
        }).focus().keyup();
        
        $('.fair_search_form > b').click(function() {
            $('.fair_search_form input').val('').keyup();
        });

        $('.fair_search_form div[search-company-data-id]').click(function() {
            hideModal();
            runActionHash('fair/show/id/'+fair.id+'/index/'+$(this).data('index'));
        });
    }

}

app.fair = new Fair();
