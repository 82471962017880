class Invoice {

    constructor() {

    }

    async createAction(request) {
        let self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'invoice/create',
        });

        if(ret.status_code == 'SUCCESS') {
            window.location.href = ret.redirect_uri;
        }

    }

    async generatePdfAction(request) {
        return false;

        let self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'invoice/generatePdf',
            id: request.id
        });

        if(ret.url) {
            setTimeout(() => {
                window.open(ret.url);
            }, ret.generated ? 2000 : 0)
        }
    }

    async downloadPdfAction(request) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'invoice/downloadPdf',
            id: request.id
        });

        if(ret.url) {
            setTimeout(() => {
                window.open(ret.url);
            }, ret.generated ? 2000 : 0)
        } else {
            console.log('Error - no url');
        }
    }

    async creditsAction(request) {

        var self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'invoice/credits',
        });

        let html = core.template.render(core.template.user.credits, {form: ret.form});
        let modal = showModal(core.lang.get('user.choose_number_of_credits'), html, undefined, true, { width: 800 });
        var $form = $('#' + ret.form.id);

        core.libs.form.handle($form, ret, function (data) {
            (async() => {
                var ret2 = await core.libs.ws.sendAndWait({
                    action: 'invoice/credits',
                    data
                });

                if (ret2.errors) {
                    core.libs.form.showErrors($form, ret2);
                }

                if(ret2.currency_error) {
                    showModalFail(ret2.message, 5000);
                }

                if (ret2.success) {
                    if(ret2.status_code == 'SUCCESS') {
                        window.location.href = ret2.redirect_uri;
                    }
                }
            })();
        });


        $('#field_number_of_credits_number_of_credits input[type="number"]').keypress((event) => {
            if(event.which == '13') event.preventDefault();
        })

        var code_timer = false;

        $('#field_number_of_credits_number_of_credits input[type="number"], #field_number_of_credits_currency select[name="currency"]').on('change', async function() {

            var affiliation_code = $('#field_number_of_credits_affiliation_code input[name="affiliation_code"]').val();
            var number_of_credits = parseInt($('#field_number_of_credits_number_of_credits input[type="number"]').val());
            var current_currency = $('#field_number_of_credits_currency select[name="currency"]').val();
            var credits_price_info_box = $('#number_of_credits .credits-price-info');
            var credits_price_sum = credits_price_info_box.find('.credits-price-sum');
            var credits_price_currency = credits_price_info_box.find('.credits-price-currency');
            var current_credit_value = await self.getCurrentCreditValueForCurrency(current_currency);

            var sum_for_credits = (parseInt(number_of_credits) * parseInt(current_credit_value.credit_unit_value)) / 100;

            if(number_of_credits && current_currency.length) {
                credits_price_info_box.removeClass('d-none');
                credits_price_sum.html(sum_for_credits);
                credits_price_currency.html(current_currency);
            } else {
                credits_price_info_box.addClass('d-none');
                credits_price_sum.html('');
                credits_price_currency.html('');
            }

            if(affiliation_code.length) await self.handleAffiliationCode(affiliation_code);
        })

        $('#field_number_of_credits_affiliation_code input[name="affiliation_code"]').on('keyup blur', async function() {
            var affiliation_code = $(this).val();
            var validation_box = $('#field_number_of_credits_affiliation_code .invalid-feedback');
            var number_of_credits = parseInt($('#field_number_of_credits_number_of_credits input[type="number"]').val());
            var current_currency = $('#field_number_of_credits_currency select[name="currency"]').val();
            var current_credit_value = await self.getCurrentCreditValueForCurrency(current_currency);

            if(affiliation_code.length) {

                var code_check = await core.libs.ws.sendAndWait({
                    action: 'admin/checkAffiliationCode',
                    code: affiliation_code
                });

                if(!code_check.code_found) {
                    validation_box.show();
                    validation_box.html(core.lang.get('user.affiliation_code_not_found'))
                } else if(code_check.is_out_of_time_range) {
                    validation_box.show();
                    validation_box.html(core.lang.get('user.affiliation_code_is_out_of_time_range'))
                } else {
                    validation_box.hide();
                    validation_box.html('');
                }
            }

            clearTimeout(code_timer);
            code_timer = setTimeout(async function () {
                await self.handleAffiliationCode(affiliation_code);
            }, 1000);

        });
    }

    async getCurrentCreditValueForCurrency(current_currency) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'invoice/getCurrentCreditValueForCurrency',
            currency: current_currency
        });

        return ret;
    }

    async handleAffiliationCode(affiliation_code) {
        var affiliation_code = $('#field_number_of_credits_affiliation_code input[name="affiliation_code"]').val();
        var number_of_credits = parseInt($('#field_number_of_credits_number_of_credits input[type="number"]').val());
        var current_currency = $('#field_number_of_credits_currency select[name="currency"]').val();
        var current_credit_value = await this.getCurrentCreditValueForCurrency(current_currency);
        var sum_for_credits = (parseInt(number_of_credits) * parseInt(current_credit_value.credit_unit_value)) / 100;
        var credits_code_price_info_box = $('#number_of_credits .credits-code-price-info');
        var credits_code_price_currency = credits_code_price_info_box.find('.credits-code-price-currency');
        var price_with_code = 0;

        var ret2 = await core.libs.ws.sendAndWait({
            action: 'admin/checkAffiliationCode',
            code: affiliation_code
        });

        if(ret2.success) {
            credits_code_price_info_box.removeClass('d-none');
            if(!ret2.is_expired && !ret2.is_out_of_time_range && !isNaN(number_of_credits)) {
                price_with_code = sum_for_credits - (sum_for_credits * ret2.code.credits_discount) / 100;
                var code_price_info = core.lang.get('user.to_pay_for_credits_with_code');
                price_with_code = isNaN(price_with_code) ? 0 : price_with_code;
                var price_sum_text = code_price_info + ' ' + '<b>' + price_with_code + '</b>' + ' ' + current_currency;
                credits_code_price_info_box.html(price_sum_text);
            }
        }
        else {
            credits_code_price_info_box.removeClass('d-none');
            credits_code_price_info_box.html('');
            credits_code_price_currency.html('');
        }
    }

}

app.invoice = new Invoice();

