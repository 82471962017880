/*
 * User session
 */

class User {
    session = '';
    groups = [];
    just_login = false;

    constructor() {
        addPromise(this, 'ready');
        addPromise(this, 'readyForActions');
    }

    async loginAction() {

        if(app.user.groups.logged) {
            runActionUrl('home');
            return ;
        }

        var self = this;
        (async () => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'user/login'
            });

            //var html = core.template.render(core.template.user.login_popup, {form: ret.form});

            $('#content').html(core.template.render(core.template.user.login, {form: ret.form}));

            // var modal = showModal(core.lang.get('user.login_popup'), html, {
            //     cancel: { label: core.lang.get('common.cancel'), class: 'btn-secondary', close: true },
            //     login: { label: core.lang.get('user.do_login'), class: 'btn-primary', onclick: function() {
            //             $('#'+ret.form.id).submit();
            //         }
            //     },
            // });

            core.libs.form.handle($('#'+ret.form.id), ret, function (data) {
                (async() => {
                    var ret = await core.libs.ws.sendAndWait({
                        action: 'user/login',
                        data: data
                    });

                    if (ret.errors) {
                        core.libs.form.showErrors(self, ret);
                    }

                    if (ret.error) {
                        let $err = $(`<div class='login_error'>${ret.message}</div>`);
                        $('.password-input-box').find('.login_error').remove();
                        $('.password-input-box').append($err);
                    }

                    if (ret.success) {
                        // Refresh user data.
                        app.user.just_login = true;
                        await core.libs.ws.sendJson({
                            action: 'user/getAll',
                        });

                        // See setAction method!
                    }
                })();
            });
        })();
    }

    async logoutAction() {
        var data = { tempid: uniqid() };
        var ret = await core.libs.ws.sendAndWait({
            action: 'user/logout',
            data
        });

        if (ret.success) {
            await this.setAction({ data });
            /*showModalSuccess(ret.message);*/

            window.localStorage.clear();
            window.localStorage.setItem('logout_notification', ret.message);
            document.cookie = `session=; expires=Thu, 18 Dec 2050 12:00:00 UTC; path=/`;
            window.location = '/';
        }
    }

    async onTriggerUrl(data) {
        if (app.user.groups.logged && (app.user.force_password_change || app.user.password_set === false) && data.action != 'user/accountPassword') {
            app.user.redirect_to = data.action;
            data.action = 'user/accountPassword';
        }
    }

    async accountTypeAction() {

        if(!app.user.groups.logged || !app.user.is_regular) {
            runActionUrl('home');
            return ;
        }

        $('#content').html(core.template.render(core.template.user.account_type, { }));
    }

    async profileAction() {

        var self = this;

        await app.company.showStudioMenu('settings', 'profile');

         var ret = await core.libs.ws.sendAndWait({
             action: 'user/profile'
         });

         $('.studio-items-container').html(core.template.render(core.template.user.profile, {error: ret.error, error_message: ret.message, form: ret.form, values: ret.user }));

         var $form = $('#' + ret.form.id);

         core.libs.form.handle($form, ret, function (data) {
             (async() => {
                 var ret2 = await core.libs.ws.sendAndWait({
                     action: 'user/profile',
                     data
                 });

                 if (ret2.errors) {
                     core.libs.form.showErrors($form, ret2);
                 }

                 if (ret2.success) {
                     self.profileAction();
                     showModalSuccess(ret2.message, 5000);
                     $('.top-menu .avatar img.user-avatar').attr('src', ret2.avatar_url);
                 }
             })();
         });
     }

    async passwordAction() {

        var self = this;

        await app.company.showStudioMenu('settings', 'password');

         var ret = await core.libs.ws.sendAndWait({
             action: 'user/password'
         });

         $('.studio-items-container').html(core.template.render(core.template.user.password, {error: ret.error, error_message: ret.message, form: ret.form, values: ret.user, header: core.lang.get('user.password_and_login') }));

         var $form = $('#' + ret.form.id);

         core.libs.form.handle($form, ret, function (data) {
             (async() => {
                 var ret2 = await core.libs.ws.sendAndWait({
                     action: 'user/password',
                     data
                 });

                 if (ret2.errors) {
                     core.libs.form.showErrors($form, ret2);
                 }

                 if (ret2.success) {
                     self.passwordAction();
                     showModalSuccess(ret2.message, 5000);
                 }
             })();
         });
     }

    async paymentsAction(data) {
        hideModal();
        var self = this;

        await app.company.showStudioMenu('settings', 'payments');

        // checks if user is regular(no company) or company user
        var ret2 = await core.libs.ws.sendAndWait({
            action: 'user/checkType',
        });

        if(ret2.regular) runActionUrl('home');

         var ret = await core.libs.ws.sendAndWait({
             action: 'user/payments'
         });

         var show_buy_credits_button = false;

        if(ret.user.invoice_data_correct) {
            show_buy_credits_button = true;
        }

         $('.studio-items-container').html(core.template.render(core.template.user.payments, { error: ret.error, error_message: ret.message, form: ret.form, values: ret.user, user: ret.user, show_buy_credits_button: show_buy_credits_button }));

         self.loadPaymentsHistory();

         if(data.back && data.back == 'payu') {
            setInterval(() => {
                self.loadPaymentsHistory();
            }, 5000);
         }

         var $form = $('#' + ret.form.id);

         if (ret.user.updated) {
             $form.find('input,select').attr('disabled', true);
         }

         core.libs.form.handle($form, ret, function (data) {
             (async() => {
                 var ret3 = await core.libs.ws.sendAndWait({
                     action: 'user/payments',
                     data
                 });

                 if (ret3.errors) {
                     core.libs.form.showErrors($form, ret3);
                 }

                 if (ret3.success) {
                     self.paymentsAction();
                     showModalSuccess(ret3.message, 5000);
                     $form.find('input,select').attr('disabled', true);
                 } else {
                     if (ret3.message) {
                        showModalFail(ret3.message);
                    }
                 }
             })();
         });

         if (data.auto) {
             runAction({ action: "invoice/credits" })
         }
     }

     async resetPasswordAction(request) {

        var dataset = {
            reset_password: true,
            hash: request.hash
        }

         var ret = await core.libs.ws.sendAndWait({
            action: 'user/password',
            dataset
         });

         if(ret.error) {
            content.innerHTML = core.template.render(core.template.user.reset_password_error);
            return false;
         }

         $('#content').html('<div class="box">' + core.template.render(core.template.user.password, {error: ret.error, error_message: ret.message, form: ret.form, values: ret.user, header: core.lang.get('user.reset_password') }) + '</div>');

         var $form = $('#' + ret.form.id);

         core.libs.form.handle($form, ret, function (data) {

            var dataset = {
                reset_password: true,
                hash: request.hash
            }

            data.reset_password = true;
            data.hash = request.hash;

             (async() => {
                 var ret2 = await core.libs.ws.sendAndWait({
                     action: 'user/password',
                     data,
                     dataset
                 });

                 if (ret2.errors) {
                     core.libs.form.showErrors($form, ret2);
                 }

                 if (ret2.success) {
                    runActionUrl('home');
                    showModalSuccess(core.lang.get('user.password_was_reset'), 5000);
                 }
             })();
         });

     }

     async favoritesAction() {

        var self = this;

        await app.company.showStudioMenu('settings', 'favorites');

         var ret = await core.libs.ws.sendAndWait({
             action: 'user/favorites'
         });

         if(ret.favorites.length) {
            ret.favorites.forEach((fav) => {
                fav.space_inactive = !fav.is_public || fav.is_inactive;
            })
         }

         $('.studio-items-container').html(core.template.render(core.template.user.favorites, { form: ret.form, favorites: ret.favorites }));

         var $form = $('#' + ret.form.id);

         core.libs.form.handle($form, ret, function (data) {
             (async() => {
                 var ret2 = await core.libs.ws.sendAndWait({
                     action: 'user/favorites',
                     data
                 });

                 if(ret2.add_error) {
                    showModalFail(ret2.message, 5000);
                 }

                 if (ret2.errors) {
                     core.libs.form.showErrors($form, ret2);
                 }

                 if (ret2.success) {
                     self.favoritesAction();
                     showModalSuccess(ret2.message, 5000);
                 }
             })();
         });
     }

     async packagesAction(data) {

        var self = this;

        await app.company.showStudioMenu('settings', 'packages');

        var ret = await core.libs.ws.sendAndWait({
            action: 'user/getUser'
        });

        //console.log(ret);

        let affiliate_code_discount_info;
        if (ret.discount) {
            //affiliate_code_discount_info = core.lang.get('common.affiliate_code_discount_info').replace(/\{discount\}/gm, ret.discount).trim();
            affiliate_code_discount_info = core.lang.get('common.affiliate_code_discount_info', { discount: ret.discount });
        }

        var ret2 = await core.libs.ws.sendAndWait({
            action: 'user/payments'
        });

        var show_buy_credits_button = false;

        if(ret.user.invoice_data_correct) {
            show_buy_credits_button = true;
        }

        $('.studio-items-container').html(core.template.render(core.template.user.packages, {
            error: ret.error,
            user: ret.user,
            affiliate_code_discount_info, discount: ret.discount,
            level_1_credits_for_month: core.config.level_1_credits_month,
            level_1_credits_for_year: core.config.level_1_credits_year,
            level_2_credits_for_month: core.config.level_2_credits_month,
            level_2_credits_for_year: core.config.level_2_credits_year,
            level_3_credits_for_month: core.config.level_3_credits_month,
            level_3_credits_for_year: core.config.level_3_credits_year,

            level_1_credits_for_year_discounted: core.config.level_1_credits_year * (100 - ret.discount) / 100,
            level_2_credits_for_year_discounted: core.config.level_2_credits_year * (100 - ret.discount) / 100,
            level_3_credits_for_year_discounted: core.config.level_3_credits_year * (100 - ret.discount) / 100,

            level_1_number_of_spaces: core.config.number_of_public_spaces_for_level_1,
            level_2_number_of_spaces: core.config.number_of_public_spaces_for_level_2,
            level_3_number_of_spaces: core.config.number_of_public_spaces_for_level_3,
            level_1_gallery_photos: core.config.number_of_files_in_library_for_level_1_package,
            level_2_gallery_photos: core.config.number_of_files_in_library_for_level_2_package,
            level_3_gallery_photos: core.config.number_of_files_in_library_for_level_3_package,
            // level_1_days_text: ret.level_1_days_text,
            // level_2_days_text: ret.level_2_days_text,
            // level_3_days_text: ret.level_3_days_text
            current_level_name: core.config['level_' + ret.user.current_level + '_name'],
            level_1_package_name: core.config.level_1_name,
            level_2_package_name: core.config.level_2_name,
            level_3_package_name: core.config.level_3_name,
            current_level_days_text: ret.current_level_days_text,
            show_buy_credits_button: show_buy_credits_button,
            level_1_number_of_urls: core.config.level_1_number_of_urls,
            level_2_number_of_urls: core.config.level_2_number_of_urls,
            level_3_number_of_urls: core.config.level_3_number_of_urls,
        }));

        $('.choose-currency button').on('click', function() {
            $(this).parent().find('.selected').removeClass('selected')
            $(this).addClass('selected');
            let cur = $(this).data('change-currency');
            $("[data-currency][data-currency!='"+cur+"']").hide();
            $("[data-currency='"+cur+"']").show();
            return false;
        });
        $('.choose-currency button:first-child').trigger('click');

        var period_button = $('.studio-items-container .package-period-button');

        period_button.click(function() {
            var self = $(this);
            var period = self.data('period');
            period_button.removeClass('selected');
            self.addClass('selected');
            var table = $('.studio-items-container table.packages');

            table.find('tr.period-costs').hide();
            table.find('tr.period-costs.period-' + period).show();
            table.find('tr.period-buy').hide();
            table.find('tr.period-buy.period-' + period).show();
        })
     }

     async makePaymentAction(data) {
        var ret = await core.libs.ws.sendAndWait(data);
        if (ret.message) {
            showModalFail(ret.message);
        } else
        if (ret.confirm_message) {
            let modal = showModalConfirm(ret.confirm_message, async function () {
                modal.hide();
                data.confirmed = 1;
                ret = await core.libs.ws.sendAndWait(data);

                console.log(ret);
                if (ret.status_code == 'SUCCESS' && ret.redirect_uri) {
                    window.location.href = ret.redirect_uri;
                } else {
                    if (ret.reload) {
                        app.user.packagesAction();
                        showModalSuccess(core.lang.get('user.package_has_been_set'));
                    } else {
                        showModalFail(core.lang.get('user.payment_error'));
                    }
                }
            }, false, 'blue', core.lang.get('common.yes_confirm'));
        } else {
            runActionUrl('user/login');
        }
     }

     /**
      * user clicks 'Buy now' button on packages list and confirms package purchase
      * @param {*} data
      */
     /*async confirmPackagePurchaseAction(data) {
        console.log('confirmPackagePurchaseAction', data);
        if (!app.user.groups.logged) {
            runActionHash('user/registration');
            return;
        }

        var ret = await core.libs.ws.sendAndWait({
            action: 'user/checkCredits',
            data
        });

        var ret2 = await core.libs.ws.sendAndWait({
            action: 'user/payments'
        });

        var show_buy_credits_button = false;

        if(ret.user.invoice_company_name &&
            ret2.user.invoice_company_name.length &&
            ret2.user.invoice_street &&
            ret2.user.invoice_street.length &&
            ret2.user.invoice_zipcode &&
            ret2.user.invoice_zipcode.length &&
            ret2.user.invoice_city &&
            ret2.user.invoice_city.length &&
            (ret.user.invoice_type == 2 || (ret.user.invoice_nip && ret.user.invoice_nip.length))) {
                show_buy_credits_button = true;
        }

        let package_chosen = data.package ? data.package : 3;
        let period_chosen = data.period ? data.period : 2;
        let button_class = 'buy-package level-' + package_chosen;
        let package_name = core.lang.get('common.package_level_' + package_chosen);
        let package_period_name = core.lang.get('common.package_period_name_' + period_chosen);

        let view_data = {
            can_buy_package: ret.can_buy_package,
            package: data.package,
            period: data.period,
            button_class: button_class,
            package_name: package_name,
            package_period_name: package_period_name,
            show_buy_credits_button: show_buy_credits_button
        }

        // if user buys lower package level
        if(parseInt(data.package) < ret.user.current_level) {

            var ret3 = await core.libs.ws.sendAndWait({
                action: 'user/getUserSpacesForDeactivation',
                deactivation: true,
                current_level: ret.user.current_level,
                new_level: data.package
            });

            if(ret3.spaces.length) {
                view_data.spaces_to_deactivate = true;
                view_data.deactivated_spaces = ret3.spaces;
            }
        }

        let html = core.template.render(core.template.user.package_confirm, view_data);

        let modal = showModal(core.lang.get('user.confirm_package_purchase'), html, undefined, true, { width: 800 });
     }*/

     async buyPackageAction(data) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'user/buyPackage',
            data
        });

        if(ret.success) {
            $('#predef_modal').modal('hide');
            this.packagesAction({});
        }
     }

    async loadPaymentsHistory() {

        var ret = await core.libs.ws.sendAndWait({
            action: 'invoice/getForUser'
        });

        $('.studio-items-container .payments-history').html(core.template.render(core.template.user.payments_history, { invoices: ret.invoices }));
    }

    async accountPasswordAction(action_url) {

        app.user.force_password_change = true;

        var self = this;
        var ret = await core.libs.ws.sendAndWait({
            action: 'user/accountPassword'
        });

        var values = { email: this.email }

        $('#content').html(core.template.render(core.template.company.account_password, { form: ret.form, values: values }));

        core.libs.form.handle($('#'+ret.form.id), ret, function (data) {

            (async() => {

                var ret2 = await core.libs.ws.sendAndWait({
                    action: 'user/accountPassword',
                    data: data
                });

                if (ret2.errors) {
                    core.libs.form.showErrors($('#'+ret.form.id), ret2);
                }

                if (ret2.success) {
                    app.user.password_set = true;
                    app.user.force_password_change = false;

                    runActionUrl('user/accountType');

                    // if (app.user.redirect_to) {
                    //     runActionUrl(app.user.redirect_to);
                    // } else {
                    //     runActionUrl('company/studio');
                    // }
                }

            })();
        });
    }

    async setAction(request) {
        /*var ret = await core.libs.ws.sendAndWait({
            action: 'product/getAllProducts'
        });*/

        var prevous_state = JSON.stringify({
            id: this.id,
            groups: this.groups
        });
console.log('setAction', request.data, app.user.just_login);
        this.id = request.data.id;
        this.name = request.data.name;
        this.email = request.data.email;
        this.password_set = request.data.password_set;
        this.name_set = request.data.name_set;
        this.tempid = request.data.tempid;
        this.session = request.data.session;
        this.groups = request.data.groups;
        this.login_count = request.data.login_count;
        this.company_count = request.data.company_count;
        this.chat_notification_count = request.data.chat_notification_count;
        this.avatar_url = request.data.avatar_url;
        this.is_regular = request.data.is_regular;
        this.has_valid_level_2 = request.data.has_valid_level_2;
        /*this.products = ret.products;*/

        ['id','tempid','session'].forEach(key => {
            if (this.hasOwnProperty(key) && this[key]) {
                window.localStorage.setItem(key, this[key]);
            } else {
                window.localStorage.removeItem(key);
            }
        });

        document.cookie = `session=${this.session}; expires=Thu, 18 Dec 2050 12:00:00 UTC; path=/`;

        var current_state = JSON.stringify({
            id: this.id,
            groups: this.groups
        });

        if (prevous_state != current_state) {
            runMethod('onPermissionsChanged');
        }

        if (app.user.just_login) {
            app.user.just_login = false;
            console.log('Login count', app.user.login_count);
            if (app.company.last_viewed_company_url) {
                runActionUrl(app.company.last_viewed_company_url);
                app.company.last_viewed_company_url = false;
            } else {
                if (app.user.login_count <= 1) {
                    runActionUrl('user/accountType');
                } else {
                    runActionUrl('company/studio');

                    //runActionUrl(core.config.default_action_url ? core.config.default_action_url : 'home');

                    // if (app.user.company_count == 0 && app.user.has_valid_level_2) {
                    //     runActionUrl('company/marketplace');
                    // } else {
                    //     runActionUrl('company/studio');
                    // }
                }
            }

            //showModalSuccess(core.lang.get('user.login_success'), 5000);
            /*modal.setBody(getSuccessHtml(ret.message)).setButtonClose().closeAfter(2200);*/
        }

        resolvePromise(this, 'ready')
    }

    /*async registerAction() {
        var content = document.getElementById('content');

        (async () => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'user/register'
            });

            var company_data = await core.libs.ws.sendAndWait({
                action: 'company/getCompanyData',
            });

            $('#content').html(core.template.render(core.template.user.register, {form: ret.form, company: company_data.company}));

            core.libs.form.handle($('#'+ret.form.id), ret, function (data) {
                (async() => {
                    var ret = await core.libs.ws.sendAndWait({
                        action: 'user/register',
                        data: data
                    });

                    if (ret.errors) {
                        core.libs.form.showErrors(self, ret);
                    }

                    if (ret.error) {
                        showModalFail(ret.message);
                    }

                    if (ret.success) {
                        content.innerHTML = core.template.render(core.template.user.register_success, { });
                    }
                })();
            });
        })();
    }*/

    async beforeRegistrationAction() {
        $('#content').html(core.template.render(core.template.user.before_registration, {}));
    }

    async registrationAction(request) {
        if(app.user.groups.logged) {
            runActionUrl('user/accountType');
            return ;
        }

        var self = this;

        (async () => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'user/registration'
            });

            let html = core.template.render(core.template.user.registration, {form: ret.form});
            html = html.replaceAll('§', '<span style="font-size: 17px;">§</span>');

            $('#content').html(html);
            if (request && request.affiliate_code) {
                window.localStorage.setItem('affiliate_code', request.affiliate_code);
            }

            if (window.localStorage.getItem('affiliate_code')) {
                $('#content input[name="affiliate_code"]').val(window.localStorage.getItem('affiliate_code'));
            }

            $('#move_up').insertAfter($('#field_registration_affiliate_code'));
            $('.account-exists').insertBefore($('#move_up'));

            $('#content form .regulation a').click(function(e) {
                e.stopPropagation();
                let a = $(this).parents('.regulation').find($(this).attr('href')).offset().top - $(this).parents('.regulation').offset().top;
                $('#content form .regulation').scrollTop(a - 10);
                return false;
            });

            /*$('#'+ret.form.id).find('input[type="checkbox"]').attr('disabled', 1);
            $('#content form .regulation').on('scroll', function() {
                if ($(this).scrollTop() >= $(this)[0].scrollHeight - $(this).height() - 40) {
                    $('#'+ret.form.id).find('input[type="checkbox"]').removeAttr('disabled');
                }
            });*/

            core.libs.form.handle($('#'+ret.form.id), ret, function (data) {
                (async() => {
                    var ret2 = await core.libs.ws.sendAndWait({
                        action: 'user/registration',
                        data: data
                    });

                    if (ret2.errors) {
                        core.libs.form.showErrors($('#'+ret.form.id), ret2);
                    }

                    if (ret2.error) {
                        showModalFail(ret2.message);
                    }

                    if (ret2.success) {
                        self.registrationAction();
                        showModalSuccess(ret2.message, 10000);
                        $('#predef_modal').on('hide.bs.modal', function (event) {
                            $('#predef_modal').off('hide.bs.modal');
                            runActionUrl('home');
                        });
                    }
                })();
            });
        })();
    }

    async forgotPasswordAction() {
        (async () => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'user/forgotPassword'
            });

            $('#content').html(core.template.render(core.template.user.forgot_password, {form: ret.form}));

            core.libs.form.handle($('#'+ret.form.id), ret, function (data) {
                (async() => {
                    var ret2 = await core.libs.ws.sendAndWait({
                        action: 'user/forgotPassword',
                        data: data
                    });

                    if (ret2.errors) {
                        core.libs.form.showErrors($('#'+ret.form.id), ret2);
                    }

                    if (ret2.error) {
                        showModalFail(ret.message);
                    }

                    if (ret2.success) {
                        content.innerHTML = core.template.render(core.template.user.forgot_password_success, { });
                    }
                })();
            });
        })();
    }

    activateAction(message) {
        (async () => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'user/activate',
                hash: message.hash
            });

            if (ret.success) {
                await core.libs.ws.sendJson({
                    action: 'user/getAll',
                });

                showModalSuccess(ret.message, 5000);
                runActionUrl('user/accountPassword');

            } else {
                showModalFail(ret.message);
                runActionUrl('page/home');
            }
        })();
    }

    updateNotificationDot() {
        if (this.chat_notification_count > 0) {
            $('.new-recommendations').addClass('dot').find('div').html(this.chat_notification_count);
        } else {
            $('.new-recommendations').removeClass('dot').find('div').html(this.chat_notification_count);
        }
    }

    async confirmRemoveFromFavoritesAction(data) {

        var company_data = await core.libs.ws.sendAndWait({
            action: 'company/getCompanyData',
            data: { id: data.id_company }
        });

        let html = core.template.render(core.template.user.confirm_remove_from_favorites, {company: company_data.company, section: data.section});
        let modal = showModal(core.lang.get('user.confirm_removal_from_favorites'), html, undefined, true, { width: 800 });
     }

    async addSpaceToFavoritesAction(data) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'user/addSpaceToFavorites',
            id_company: data.id_company
        });

        if(ret.result.operation == 'add') {
            var message = core.lang.get('user.space_added_to_favorites');
            $('.container.show-company').find('.add-to-favorites').addClass('in-favorites');
        } else {
            var message = core.lang.get('user.space_remove_from_favorites');
            $('.container.show-company').find('.add-to-favorites').removeClass('in-favorites');
        }

        if(data.section == 'profile') {
            this.favoritesAction();
        }

        showModalSuccess(message);
    }

    onLayoutReady() {
        /*var content = document.getElementById('content');
         content.innerHTML = core.template.render(core.template.user.avatar, { });*/
        var notification = window.localStorage.getItem('logout_notification');
        if (notification && notification != '') {
            window.localStorage.setItem('logout_notification', '');
            //showModalSuccess(notification);
        }

        resolvePromise(this, 'readyForActions')
    }

    hasGroup(group) {
        return this.groups.hasOwnProperty(group);
    }

    async downloadRegulationsAction() {
        switch(core.lang.language) {
            case 'pl':
                var file = 'regulamin';
            break;
            case 'en':
                var file = 'regulations';
            break;
        }

        var file_url = core.config.home + '/public/download/' + file + '.pdf';

        window.open(file_url);
    }
}

app.user = new User();

