class Video {

    constructor() {
        
    }

    async displayVideoLinksAction(data) {
        var self = this;

        (async () => {
            const id_company = data.id_company;
            const id_user = data.id_user;
            
            var ret = await core.libs.ws.sendAndWait({
                action: 'video/getVideoLinks'
            });
            
            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }

            var add_more_links = ret.video_links.length < core.config.max_video_links;
            var links_exist = ret.video_links.length > 0;

            $('#modal').html(core.template.render(core.template.video.video_link_list_modal, {video_links: ret.video_links, video_links_exist: links_exist, add_more_links: add_more_links, title: core.lang.get('video.youtube_videos')}));

            var options = {};
            var myModal = new bootstrap.Modal(document.getElementById('visual_form_modal'), options);
            myModal.show();

            $('#visual_form_modal .add-user-video-link').on('click', function() {
                myModal.hide();
                self.editLinkAction({});
            });

            $('#visual_form_modal .edit-user-video-link').on('click', function() {
                myModal.hide();
                self.editLinkAction({id: $(this).data('link_id') });
            });

        })();

        return false;

    }

    async editLinkAction(data) {
        var self = this;

        (async () => {
            
            var ret = await core.libs.ws.sendAndWait({
                action: 'video/editLink',
                id: data.id
            });
            
            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }
            
            ret.form.id = 'visual_company_form';

            $('#modal').html(core.template.render(core.template.video.video_form, {form: ret.form, values: ret.video_link, video: ret.video_link, title: core.lang.get('video.video_link')}));

            var options = {};
            var myModal = new bootstrap.Modal(document.getElementById('visual_form_modal'), options);
            myModal.show();

            var $form = $('#' + ret.form.id);

            core.libs.form.handle($form, ret, function (data) {

                (async() => {
                    var ret = await core.libs.ws.sendAndWait({
                        action: 'video/editLink',
                        data
                    });

                    if (ret.errors) {
                        core.libs.form.showErrors($form, ret);
                    }

                    if (ret.success) {
                        document.getElementById('visual_form_modal').addEventListener('hidden.bs.modal', function (event) {
                            showModalSuccess(ret.message, 5000);
                        })
                        myModal.hide();

                        app.company.updateKrpano();
                    }
                })();
            });
        })();

        return false;

    }

}

app.video = new Video();

