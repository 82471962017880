class ormUser extends orm.base {
    avatar() {
        return `<div class='avatar'><img src='${this.avatar_url}' alt='' /></div>`;
    }
    
    lastChatMessage() {
        return this.last_chat_message;
    }
}

orm.user = ormUser;
