/*
 * Forms.
 */

// Element for drag&drop
let move_drag_element = -1;

class Form {
    constructor() {

    }

    /**
     * Handle form validation and file upload
     * @param jquery element form_element
     * @param object params: { form, rules }
     * @param function pass_callback
     * @param function fail_callback
     * @returns bool
     */
    async handle(form_element, params, pass_callback, fail_callback, before_data_validation) {
        var self = this;
        var files = {};


        for (let k in params.form.fields) {
            if (params.form.fields[k].template == 'form.widget.file') {
                $(form_element).find("[name='" + params.form.fields[k].name + "']").change(function () {
                    const [file] = this.files;

                    var file_type_images = ['image/png', 'image/jpeg', 'image/gif'];
                    var file_type_other = {
                        'application/pdf': '<i class="far fa-file-pdf fa-4x"></i>',
                        'application/msword': '<i class="far fa-file-word fa-4x"></i>',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '<i class="far fa-file-excel fa-4x"></i>',
                        'audio/mpeg': '<i class="far fa-file-audio fa-4x"></i>',
                        'video/mp4': '<i class="far fa-file-video fa-4x"></i>',
                        'text/plain': '<i class="far fa-file-alt fa-4x"></i>'
                    }

                    for (var k in params.form.fields) {
                        if (params.form.fields[k].name == $(this).attr('name')) {
                            params.form.fields[k].promise = new Promise(async (resolve, reject) => {
                                const preview = $(this).parent().parent().find('.preview');
                                const progress = $(this).parent().parent().find('.progress');
                                const reader = new FileReader();
                                reader.onloadstart = function (event) {
                                    $(progress).removeClass('visually-hidden').css('opacity', 1);
                                    $(progress).find('>div').width(0).attr('aria-valuenow', 0);
                                    $(preview).css('opacity', 0.4);
                                }
                                reader.onloadend = function (e) {
                                    $(preview).css('opacity', 1).slideDown();
                                    if (!params.form.fields[k].hide_thumbnail) {
                                        if(file_type_images.includes(file.type)) {
                                            $(preview).find('.file-other-type').html('');
                                            $(preview).find('img')[0].src = URL.createObjectURL(file);
                                        } else {
                                            if(file_type_other.hasOwnProperty(file.type)) {
                                                $(preview).find('img')[0].src = '';
                                                $(preview).find('.file-other-type').html(file_type_other[file.type]);
                                            } else {
                                                $(preview).find('.file-other-type').html('<i class="far fa-file-alt fa-4x"></i>');
                                            }
                                        }
                                    }

                                    $(progress).animate({ opacity: 0 }, 1500);

                                    files[params.form.fields[k].name] = {
                                        name: file.name,
                                        size: file.size,
                                        type: file.type,
                                        content: reader.result,
                                    }

                                    if (params.form.fields[k].onload) {
                                        params.form.fields[k].onload(file);
                                    }

                                    resolve(true);
                                }
                                reader.onprogress = function (event) {
                                    var pr = Math.round((event.loaded / event.total) * 100);
                                    $(progress).find('>div').width(pr+'%').attr('aria-valuenow', pr);

                                    if (params.form.fields[k].onprogress) {
                                        params.form.fields[k].onprogress(pr);
                                    }
                                }

                                function handleEvent(event) {
                                    resolve();
                                }

                                reader.addEventListener('error', handleEvent);
                                reader.addEventListener('abort', handleEvent);

                                // Trigger upload.
                                reader.readAsDataURL(file);
                            });

                            return;
                        }
                    }
                });
            }

            /**
             * Uploader
             */

            // Params passed to the core.libs.form.handle does not have the .values object which is required for uploader widget to show existing files!
            if (params.form.fields[k].template == 'form.widget.uploader') {
                let $field = $(`#field_${params.form.id}_${params.form.fields[k].name}`);
                let $files = {};
                let tokens = {};

                if (!params.form.fields[k].token_group) {
                    alert('Uploader field does not have token_group defined');
                }

                // Check if all files are already uploaded.
                params.form.fields[k].promises = [];
                let promises = {};

                $field.find('.btn.browse_button').on('click', () => { $field.find('.dropfile').click(); return false; });

                let getFileCount = function() {
                    return $field.find('.icon').length;
                }

                let showInfo = function() {
                    if (getFileCount()) {
                        $field.find('.dropfile .info').hide();
                    } else {
                        $field.find('.dropfile .info').show();
                    }
                }

                let uploader = new plupload.Uploader({
                    runtimes : 'html5,html4',
                    browse_button : $field.find('.browse_button').get(0), // you can pass in id...
                    container: $field.find('.btn-primary').parent().get(0), // ... or DOM Element itself

                    url : core.config.home+"/upload",

                    filters : {
                        max_file_size : params.form.fields[k].token_group.max_file_size+'kb',
                        mime_types: params.form.fields[k].mime_types || [
                            { title : "Video", extensions : "mp4" },
                        ]
                    },

                    multipart_params: {
//                            token: params.form.fields[k].token.token
                    },

                    chunk_size: "500kb",
                    dragdrop: true,
                    drop_element: $field.find('.dropfile').get(0),

                    init: {
                        PostInit: function() {
                            //$field.find('.filelist').html('');

                            // Callback when uploader is ready. Catched on the uploader widget.
                            window['readyUploader'+params.form.fields[k].token_group.token](uploader);
                        },

                        FilesAdded: async function(up, files) {
                            var data = {};
                            // Limit files.
                            files = files.slice(0, params.form.fields[k].token_group.max_files);

                            let left = params.form.fields[k].token_group.max_files - getFileCount();

                            // If there is no space left then remove previous files
                            if (files.length > left) {
                                for(let i = 0; i < files.length - left; i++) {
                                    $field.find('.filelist .icon:first-child .btn').click();
                                }
                            }

                            /*if (getFileCount() + 1 > params.form.fields[k].token_group.max_files) {
                                let over = getFileCount() - params.form.fields[k].token_group.max_files;
                                files = files.slice(0, files.length - over);

                                let remove = [];
                                for(let i in up.files) {
                                    if (i >= params.form.fields[k].token_group.max_files) {
                                        remove.push(up.files[i].id);
                                    }
                                }

                                for(let i in remove) {
                                    uploader.removeFile(remove[i]);
                                }

                                showModalFail(core.lang.get('common.you_cant_add_more_files'));
                                return;
                            }*/

                            plupload.each(files, function(file) {
                                data[file.id] = {name: file.name, type: file.type, size: file.size};
                            });

                            var new_token = await core.libs.ws.sendAndWait({
                                action: "functions/getSingleToken",
                                files,
                                token_group: params.form.fields[k].token_group.token
                            });

                            if (new_token.error) {
                                showModalFail(new_token.message);
                            } else {
                            }

                            if (new_token.tokens) {
                                Object.assign(tokens, new_token.tokens);

                                plupload.each(files, function(file) {
                                    if (tokens[file.id]) {
                                        showInfo();

                                        $files[file.id] = $('<div/>').addClass('import-file').html(`
                                            <div><span class="name">${file.name}</span> (${plupload.formatSize(file.size)})</div>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                                    0%
                                                </div>
                                            </div>
                                        `);

                                        $field.find('.dropfile').append($files[file.id]);

                                        let promise = new Promise(async (resolve, reject) => {
                                            promises[file.id] = { resolve, reject };
                                        });

                                        params.form.fields[k].promises.push(promise);
                                    }
                                });

                                uploader.start();
                            }
                        },

                        BeforeUpload: function (up, file) {
                            up.settings.multipart_params.token = tokens[file.id];
                        },

                        UploadProgress: function(up, file) {
                            if ($files[file.id]) {
                                $files[file.id].find('.progress-bar').html(file.percent+'%').css({ width: file.percent+'%' });
                            }
                        },

                        FileUploaded: function(up, file, response) {
                            response = JSON.parse(response.response);
                            if (params.form.fields[k].onuploaded) {
                                params.form.fields[k].onuploaded(response);
                            }

                            $files[file.id].animate({ opacity: 0 }, 1500);
                            promises[file.id].resolve();
                            uploader.addFileIcon(response, file);

                            $files[file.id].remove();
                        },

                        Error: function(up, err) {
                            //document.getElementById('console').innerHTML += "\nError #" + err.code + ": " + err.message;
                            console.log(err.code + ": " + err.message);
                            if (err.code == -600) {
                                showModalFail(core.lang.get('common.file_size_too_big') + params.form.fields[k].token_group.max_file_size+'kb');
                            } else {
                                showModalFail(core.lang.get('common.incorrect_file_format'));
                            }
                        }
                    }
                });

                uploader.addFileIcon = function(response, file) {
                    let $icon = getFileIcon(response, {
                        icon_callback: (e) => {
                            (async() => {
                                if (file) {
                                    // Remove just uploaded file.
                                    await core.libs.ws.sendAndWait({
                                        action: "functions/removeSingleToken",
                                        files,
                                        token_group: params.form.fields[k].token_group.token,
                                        token: tokens[file.id],
                                    });

                                    uploader.removeFile(file.id);
                                    delete tokens[file.id];
                                    $files[file.id].remove();
                                    $icon.remove();
                                }

                                if (response.hash) {
                                    // Remove existing file.
                                    let ret = await core.libs.ws.sendAndWait({
                                        action: "functions/removeFileByHash",
                                        id: response.id,
                                        token_group: params.form.fields[k].token_group.token,
                                        hash: response.hash
                                    });

                                    if (ret.success) {
                                        $icon.remove();
                                    } else {
                                        showModalFail(ret.message)
                                    }
                                }

                                if (params.form.fields[k].delete_callback) {
                                    await params.form.fields[k].delete_callback(response, file);
                                }

                                showInfo();
                            })();

                            e.stopPropagation();
                            return false;
                        }
                    });

                    $field.find('.filelist').append($icon);
                    showInfo();
                }

                uploader.init();

                if (params.values) {
                    let name = params.form.fields[k].name;

                    if (Array.isArray(params.values[name])) {
                        // Multiply files.
                        for(let i in params.values[name]) {
                            uploader.addFileIcon(params.values[name][i]);
                        }
                    } else {
                        // Single file.
                        if (params.values[name]) {
                            uploader.addFileIcon(params.values[name]);
                        }
                    }
                }
            }

            /**
             *  Filepicker
             */

            if (params.form.fields[k].template == 'form.widget.filepicker') {
                let call_onchange = false;
                let $field = $(`#field_${params.form.id}_${params.form.fields[k].name}`);
                let $input = $field.find('input');
                let $selected = $field.find('.selected');
                let $library = $field.find('.library');
                let name = params.form.fields[k].name;
                let modal = false;

                let $icon_move = false;
                let insertIconOnDrag = function(e, ) {
                    let x = e.pageX;// - parentOffset.left;
                    let y = e.pageY;// - parentOffset.top;

                    let i = 0;
                    let br = false;
                    [$selected, $library].forEach(function($container) {
                        let cof = $container.offset();
                        //$container.css('background', 'yellow');
                        if (x >= cof.left && x <= cof.left + $container.width() && y >= cof.top && y <= cof.top + $container.height()) {
                            $container.find('> .icon').each(function() {
                                if (!br) {
                                    let of = $(this).offset();

                                    if (of.top + $(this).height() + 20 > y && of.left + $(this).width() + 20 > x) {
                                        br = true;
                                    }

                                    i++;
                                }
                            });

                            if (!br) {
                                i = $container.find('>').length + 1;
                                move_drag_element.i = -1;
                            }

                            if (move_drag_element.i != i) {
                                if (move_drag_element.i < i) {
                                    i++;
                                }

                                let $inaft = $container.find('> *:nth-child('+i+')');
                                if ($inaft.length) {
                                    $icon_move.insertBefore($inaft);
                                } else {
                                    $container.append($icon_move);
                                }
                                move_drag_element.i = i;
                            }
                        } else {
                            //$container.css('background', 'white');
                        }
                    });
                }

                let canAdd = function() {
                    return $selected.find('> .icon').length < params.form.fields[k].max;
                }

                let updateSelectedIds = function() {
                    let ids = [];
                    $selected.find('> .icon').each(function() {
                        ids.push($(this).data('id'));
                    });

                    $input.val(ids.join(','));

                    if ($selected.find('> .icon').length == 0) {
                        $selected.find('.info').show();
                    } else {
                        $selected.find('.info').hide();
                    }

                    if (canAdd()) {
                        $selected.removeClass('limit');
                    } else {
                        $selected.addClass('limit');
                    }

                    if (call_onchange && params.form.fields[k].onchange) {
                        params.form.fields[k].onchange(ids);
                    }
                }

                let clickElement = function($el) {
                    if ($el.parent().hasClass('library')) {
                        $el.appendTo($selected).find('.fa-plus').removeClass('fa-plus').addClass('fa-times');
                        $el.attr('draggable', true).addClass('draggable');
                        if (params.form.fields[k].add_callback) {
                            params.form.fields[k].add_callback($el);
                        }
                    } else {
                        $el.appendTo($library).find('.fa-times').removeClass('fa-times').addClass('fa-plus');;
                        $el.removeAttr('draggable').removeClass('draggable');
                        if (params.form.fields[k].delete_callback) {
                            params.form.fields[k].delete_callback($el);
                        }
                    }
                    if (modal) {
                        modal.hide();
                    }
                    updateSelectedIds();
                }

                // Selected
                let skip = [];
                if (params.values && params.values[name]) {
                    /*if (!Array.isArray(params.values[name])) {
                        params.values[name] = [params.values[name]];
                    }*/

                    for (let i in params.values[name]) {
                        let file = params.values[name][i];

                        if(file) {
                            skip.push(file.id);

                            let $icon = getFileIcon(file, {
                                icon_callback: (e, file) => {
                                    clickElement($(e.target).parents('.icon'));
                                }
                            });

                            // Move from selected files
                            $icon.attr('draggable', true).addClass('draggable').on('drag', (e) => {
                                if (!move_drag_element) {
                                    $icon_move = $(e.target);
                                    $icon_move.addClass('move');
                                    move_drag_element = file;
                                    move_drag_element.i = -1;
                                }

                                insertIconOnDrag(e, $library);
                            });

                            $selected.append($icon);
                        }
                    }
                    updateSelectedIds();
                    call_onchange = true;
                }

                // Library
                for (let i in params.form.fields[k].files) {
                    let file = params.form.fields[k].files[i];
                    if (!skip.includes(file.id)) {
                        let $icon = getFileIcon(file, {
                            icon: `<i class="fas fa-plus"></i>`,
                            icon_callback: (e, file) => {
                                clickElement($(e.target).parents('.icon'));
                            },
                        });

                        // Move from library
                        if (!params.form.fields[k].library_on_modal) {
                            $icon.attr('draggable', true).addClass('draggable');
                        }

                        $icon.on('drag', (e) => {
                            if (!move_drag_element) {
                                $icon_move = $(e.target);
                                $icon_move.addClass('move');
                                move_drag_element = file;
                                move_drag_element.i = -1;
                            }

                            insertIconOnDrag(e, $selected);
                        });

                        $library.append($icon);
                    }
                }

                $selected.parent().on('drop', (e) => {
                    e.preventDefault();
                    updateSelectedIds();
                    $icon_move.find('.fa-plus').removeClass('fa-plus').addClass('fa-times').addClass('draggable');
                    $icon_move.removeClass('move');
                    move_drag_element = false;
                    if (params.form.fields[k].add_callback) {
                        params.form.fields[k].add_callback($icon_move);
                    }
                });

                $library.parent().on('drop', (e) => {
                    e.preventDefault();
                    updateSelectedIds();
                    $icon_move.removeClass('move');
                    $icon_move.find('.fa-times').removeClass('fa-times').addClass('fa-plus');
                    if (params.form.fields[k].library_on_modal) {
                        $icon_move.removeAttr('draggable').removeClass('draggable');
                    }
                    if (params.form.fields[k].delete_callback) {
                        params.form.fields[k].delete_callback($icon_move);
                    }
                    move_drag_element = false;
                });

                if (move_drag_element === -1) {
                    $(document).on('mouseup', () => {
                        if (move_drag_element) {
                            $icon_move.removeClass('move');
                            move_drag_element = false;
                        }
                    });
                    move_drag_element = false;
                }

                if (params.form.fields[k].library_on_modal) {
                    let $libparent = $library.parent();
                    $libparent.hide();
                    $selected.on('click', () => {
                        if (canAdd()) {
                            modal = showModal('', ' ', undefined, true, { width: 580 });
                            $library.appendTo($(modal._element).find('.modal-body'));

                            $(modal._element).on('hidden.bs.modal', () => {
                                $library.appendTo($libparent);
                            });
                        }
                    });
                } else {
                }
            }

            if (params.form.fields[k].template == 'form.widget.multicheckbox') {

            }
        }

        $(form_element).submit(function() {
            (async() => {
                const formdata = new FormData(form_element[0]);
                const getValues = function() {
                    var data = Object.fromEntries(formdata);
                    var values = {};
                    for(let name in data) {
                        if(name.indexOf('.') == -1) {
                            values[name] = data[name];
                        }else {
                            let tokens = name.split('.');
                            let intermediateValue = values;
                            for(let index = 0; index < tokens.length - 1; index++) {
                                let token = tokens[index];
                                if(!intermediateValue.hasOwnProperty(token)) {
                                    intermediateValue[token] = {};
                                }
                                intermediateValue = intermediateValue[token];
                            }
                            intermediateValue[tokens[tokens.length - 1]] = data[name];
                        }
                    }

                    for (let k in params.form.fields) {
                        if (params.form.fields[k].template == 'form.widget.multicheckbox') {
                            values[params.form.fields[k].name] = [];
                            $('#'+'field_'+params.form.id+'_'+params.form.fields[k].name+' input:checked').each(function() {
                                values[params.form.fields[k].name].push($(this).val());
                            });
                        }
                    }

                    return values;
                }

                const data = getValues();
                for (let k in params.form.fields) {
                    if (params.form.fields[k].template == 'form.widget.checkbox' && !data[params.form.fields[k].name]) {
                        data[params.form.fields[k].name] = '0'; // to make the validation possible
                    }
                }

                //console.trace();
                //console.log('data', data);

                if (before_data_validation) {
                    before_data_validation(data);
                }

                const validator = new Validator(data, params.rules, core.lang.validator);

                var promises = []
                for (var k in params.form.fields) {
                    if (params.form.fields[k].promise) {
                        promises.push(params.form.fields[k].promise);
                    }
                    if (params.form.fields[k].promises) {
                        promises = promises.concat(params.form.fields[k].promises);
                    }
                }

                if (promises) {
                    // Check when all files are uploaded.
                    await Promise.all(promises);
                }

                $(form_element).find('.invalid-feedback').hide();
                $(form_element).find('[name]').removeClass('is-invalid');

                if (core.config.form_validator_on_frontend) {
                    $(form_element).css('opacity', 0.4);

                    var attribute_names = { };
                    for (var k in params.form.fields) {
                        if (params.form.fields[k].multilang) {
                            core.config.languages.forEach(lang => {
                                attribute_names[params.form.fields[k].name+'_'+lang] = params.form.fields[k].label + ' (' + core.lang.common[lang] + ')';
                            });
                        } else {
                            attribute_names[params.form.fields[k].name] = params.form.fields[k].label;
                        }
                    }

                    validator.setAttributeNames(attribute_names);

                    function passes() {
                        const values = getValues(formdata);
                        $(form_element).css('opacity', 1);
                        if (pass_callback) {
                            pass_callback({ ...values, ...files });
                        }
                    }

                    function fails() {
                        $(form_element).css('opacity', 1);
                        self.showErrors(form_element, validator.errors, 'frontend');
                        if (fail_callback) {
                            fail_callback(validator);
                        }
                    }

                    validator.checkAsync(passes, fails);
                } else {
                    const values = getValues(formdata);
                    pass_callback({ ...values, ...files });
                }
            })();
        });
    }

    showErrors(form_element, params, from = 'backend') {
        if (core.config.debug_validator) {
            console.log(`Debug validator (${from})`, params.errors);
        }

        console.log('Form errors', params.errors);

        for (var field in params.errors) {
            $(form_element).find('[name="' + field + '"]').addClass('is-invalid')
            $(form_element).find('.invalid-feedback[for="' + field + '"]').html(params.errors[field].join('<br />')).show();
        }
    }
}

core.libs.form = new Form();
