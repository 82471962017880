class Contact {

    constructor() {

    }

    async addAction(request) {
        let self = this;
        var ret = await core.libs.ws.sendAndWait({
            action: 'contact/add',
            id: request.id,
            remove: request ? request.remove : 0,
        });

        request.modal = true;

        if (request.no_dataset !== undefined) {
            this.no_dataset = request.no_dataset;
        } else {
            request.no_dataset = this.no_dataset;
        }

        if (!request.modal) {
            await app.company.showStudioMenu('3dspaces', 'contents');

            ret.form.fields[0].add_callback = async ($el) => {
                await self.loadPreviewDataAction($('.add-contact-form .preview-container'), null, true);
            };

            ret.form.fields[0].delete_callback = async ($el) => {
                await self.loadPreviewDataAction($('.add-contact-form .preview-container'), null, true);
            };

            $('.studio-items-container').html(core.template.render(core.template.contact.add, { form: ret.form, values: ret.values, dataset_form: ret.dataset_form, message_form: ret.message_form, category_name: core.lang.get('contact.contact_details') }));
            $('.studio-container .studio-nav-menu').hide();
        } else {
            let html = core.template.render(core.template.contact.add, { form: ret.form, values: ret.values, dataset_form: ret.dataset_form, message_form: ret.message_form, category_name: core.lang.get('contact.contact_details') });
            var modal = showModal(core.lang.get('contact.contact_details'), html, null, true, { width: 800 });
            $(modal._element).on('hide.bs.modal', function (event) {
                if (request.callback) {
                    request.callback(modal);
                }
            });
            if(request.hasOwnProperty('show_template_name_input') && request.show_template_name_input) {
                $('#field_add_contact_name').slideDown();
            }
        }

        $('#field_add_contact_dataset').appendTo($('#template_container'));
        app.contact.contactPerson(true);

        $('#field_add_contact_dataset select').on('change', async function() {
            await self.loadDataAction(parseInt($(this).val()));
            /*if($(this).val().length) {
                await self.loadPreviewDataAction($('.add-contact-form .preview-container'), parseInt($(this).val()));
            } else {
                await self.loadPreviewDataAction($('.add-contact-form .preview-container'), null, true);
            }*/
        });

        var preview_timer = false;

        $('#add_contact input, #add_contact textarea').on('keyup', async function() {
            var contact_form = $(this).closest('form');
            clearTimeout(preview_timer);
            preview_timer = setTimeout(async function () {
                await self.loadPreviewDataAction($('.add-contact-form .preview-container'), contact_form.find("input:hidden[name='id']").val(), true);
            }, 1000);
        });

        $('#add_contact select, #add_contact :checkbox').on('change', async function() {
            var contact_form = $(this).closest('form');
            clearTimeout(preview_timer);
            preview_timer = setTimeout(async function () {
                await self.loadPreviewDataAction($('.add-contact-form .preview-container'), contact_form.find("input:hidden[name='id']").val(), true);
            }, 1000);
        });

        var $form = $('#' + ret.form.id);

        if (request.no_dataset) {
            $('#field_add_contact_dataset').hide();
            $('#extra_buttons_contact').remove();
        } else {
            $('#extra_buttons_contact').appendTo($('#template_container'));
            $('#extra_buttons_contact .add').click(function() { app.contact.addAction({ id: 0, show_template_name_input: true }) });
            $('#extra_buttons_contact .rename').click(function() { $('#field_add_contact_name').slideDown(); $('#field_add_contact_name input').trigger('focus'); });
            $('#extra_buttons_contact .copy').click(function() { var sel = app.contact.getDatalistSelected(); if (sel) {
                let modal = showModalConfirm(core.lang.get('contact.confirm_copy') + '<b>' + sel.name + '</b> ?', function () {
                    modal.hide();
                    app.contact.loadDataAction(sel.id, true)
                }, false, 'primary', core.lang.get('common.yes'));
            }} );
            $('#extra_buttons_contact .delete').click(function() { var sel = app.contact.getDatalistSelected(); if (sel) {
                let modal = showModalConfirm(core.lang.get('contact.confirm_delete') + '<b>' + sel.name + '</b> ?', function () {
                    modal.hide();
                    app.contact.addAction({ id: 0, remove: sel.id })
                }, false);
            }} );
        }

        if(request.id) {
            $('#field_add_contact_dataset').val(request.id);
            await self.loadDataAction(parseInt(request.id));
            //await self.loadPreviewDataAction($('.add-contact-form .preview-container'), parseInt(request.id));
        } else {
            //await self.loadPreviewDataAction($('.add-contact-form .preview-container'), null, true);
            this.handleForm($form, ret, request, 'contact/add', request.callback || false);
        }
    }

    getDatalistSelected() {
        let id = $('#field_add_contact_dataset select').val();
        return id ? {id, name: $('#field_add_contact_dataset select option[value="'+id+'"]').html() } : false;
    }

    async contactPerson(add = true, data = {}) {
        if (add) {
            let html = $('#contact_person > div').clone().appendTo($('#contact-persons-container')).addClass('real');
            if (data) {
                $(html).find('input').each(function() {
                    $(this).val(data[$(this).attr('name')]);
                });
            }
        }
        let i = 1;
        $('#contact-persons-container > div').each(function() {
            $(this).find('> div > div:first-child').html(i+'.'); i++;
        });

        this.showHideAddContactButtons();
    }

    showHideAddContactButtons() {
        if ($('#contact-persons-container > div').length >= 10) {
            $('#contact-persons-container .add-contact').hide();
        } else {
            $('#contact-persons-container .add-contact').show();
        }
    }

    async addOldAction(request) {
        let self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'contact/add',
            id: request.id
        });

        await app.company.showStudioMenu('3dspaces', 'contents');

        ret.form.fields[0].add_callback = async ($el) => {
            await self.loadPreviewDataAction($('.add-contact-form .preview-container'), null, true);
        };

        ret.form.fields[0].delete_callback = async ($el) => {
            await self.loadPreviewDataAction($('.add-contact-form .preview-container'), null, true);
        };

        $('.studio-items-container').html(core.template.render(core.template.contact.add, { form: ret.form, values: ret.values, dataset_form: ret.dataset_form, message_form: ret.message_form, category_name: core.lang.get('contact.contact_details') }));
        $('.studio-container .studio-nav-menu').hide();

        $('#field_add_contact_dataset').on('change', async function() {
            await self.loadDataAction(parseInt($(this).val()));
            if($(this).val().length) {
                await self.loadPreviewDataAction($('.add-contact-form .preview-container'), parseInt($(this).val()));
            } else {
                await self.loadPreviewDataAction($('.add-contact-form .preview-container'), null, true);
            }
        });

        var preview_timer = false;

        $('#add_contact input, #add_contact textarea').on('keyup', async function() {
            var contact_form = $(this).closest('form');
            clearTimeout(preview_timer);
            preview_timer = setTimeout(async function () {
                await self.loadPreviewDataAction($('.add-contact-form .preview-container'), contact_form.find("input:hidden[name='id']").val(), true);
            }, 1000);
        });

        $('#add_contact select, #add_contact :checkbox').on('change', async function() {
            var contact_form = $(this).closest('form');
            clearTimeout(preview_timer);
            preview_timer = setTimeout(async function () {
                await self.loadPreviewDataAction($('.add-contact-form .preview-container'), contact_form.find("input:hidden[name='id']").val(), true);
            }, 1000);
        });

        var $form = $('#' + ret.form.id);

        if(request.id) {
            $('#field_add_contact_dataset').val(request.id);
            await self.loadDataAction(parseInt(request.id));
            await self.loadPreviewDataAction($('.add-contact-form .preview-container'), parseInt(request.id));
        } else {
            await self.loadPreviewDataAction($('.add-contact-form .preview-container'), null, true);
            this.handleForm($form, ret, request, 'contact/add', 'last');
        }
    }

    async loadDataAction(contact_id, make_copy = false) {

        var self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'contact/getById',
            id: contact_id,
            copy: make_copy,
        });

        $('.add-contact-form .contact-form-cont').html(core.template.render(core.template.contact.contact_form, ret));
        if (ret.values.id && make_copy) {
            // Update the dropdown with the templates
            $('#field_add_contact_dataset select').append($('<option value="'+ret.values.id+'">'+ret.values.name+'</option>'));
            $('#field_add_contact_dataset select').val(ret.values.id);
        }

        var $form = $('#' + ret.form.id);
        var preview_timer = false;
        $('#add_contact input, #add_contact textarea').on('keyup', async function() {
            var contact_form = $(this).closest('form');
            clearTimeout(preview_timer);
            preview_timer = setTimeout(async function () {
                await self.loadPreviewDataAction($('.add-contact-form .preview-container'), contact_form.find("input:hidden[name='id']").val(), true);
            }, 1000);
        });

        $('#add_contact select, #add_contact :checkbox').on('change', async function() {
            var contact_form = $(this).closest('form');
            clearTimeout(preview_timer);
            preview_timer = setTimeout(async function () {
                await self.loadPreviewDataAction($('.add-contact-form .preview-container'), contact_form.find("input:hidden[name='id']").val(), true);
            }, 1000);
        });

        if (ret.values.contactpersons && ret.values.contactpersons.length) {
            for(let p of ret.values.contactpersons) {
                app.contact.contactPerson(true, p);
            }
        } else {
            app.contact.contactPerson(true);
        }

        this.handleForm($form, ret, {}, 'contact/add', this.success_action_url);
    }

    handleForm($form, ret, request = {}, form_action = 'contact/add', success_action_url = 'library/getLibraryFiles') {
        if (success_action_url === 'last') {
            success_action_url = this.success_action_url;
        } else {
            this.success_action_url = success_action_url;
        }

        for(let i in ret.form.fields) {
            if (ret.form.fields[i].name == 'id_logo') {
                ret.form.fields[i].onchange = () => {
                    var contact_form = $(this).closest('form');
                    this.loadPreviewDataAction($('.add-contact-form .preview-container'), contact_form.find("input:hidden[name='id']").val(), true);
                }
            }
        }

        core.libs.form.handle($form, ret, function (data) {
            (async() => {
                data.lang = core.lang.language;
                if(request.folder)  data.id_folder = request.folder;

                let contact_persons = [];
                $('.contact_person.real').each(function() {
                    let person = {};
                    $(this).find('input').each(function() {
                        person[$(this).attr('name')] = $(this).val();
                    });
                    contact_persons.push(person);
                });

                data.contact_persons = contact_persons;

                var ret = await core.libs.ws.sendAndWait({
                    action: form_action,
                    data
                });

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }

                if(ret.errors && ret.errors.consent) {
                    $('#send_contact_message .consent-error').addClass('d-flex');
                    $('#send_contact_message .consent-error').html(ret.errors.consent);
                } else {
                    $('#send_contact_message .consent-error').removeClass('d-flex');
                }

                if (ret.success) {
                    if(typeof success_action_url == 'function') {
                        success_action_url();
                    } else if(typeof success_action_url == 'string') {
                        //runActionUrl(success_action_url);
                        window.location.reload();
                    } else {
                        hideModal();
                        //$('.preview-box .message-form').html(core.template.render(core.template.email.message_sent_confirmation, { message:core.lang.get('contact.message_sent') }))
                    }

                }
            })();
        });
    }

    async loadPreviewDataAction(preview_container, contact_id = null, from_form = false) {
        console.log('Disabled loadPreviewDataAction');
        return;

        var ret = await core.libs.ws.sendAndWait({
            action: 'contact/preview',
            contact_id: contact_id
        });

        var add_contact_form = document.querySelector('#add_contact');

        if(from_form && add_contact_form) {
            var form_data = new FormData(add_contact_form);
            var contact_data = {};
            for(var fde of form_data.entries()) {
                contact_data[fde[0]] = fde[1];
            }

            if (contact_data.id_logo) {
                var ret2 = await core.libs.ws.sendAndWait({
                    action: 'library/getUserLibraryFiles',
                    id: contact_data.id_logo,
                    category: 'logotypes',
                });
                contact_data.logo = ret2.files[0] ? ret2.files[0] : false;
            } else {
                delete contact_data.logo;
            }
        } else {
            var contact_data = ret.contact;
        }

        if(contact_data) {
            var ret3 = await core.libs.ws.sendAndWait({
                action: 'contact/getIndustry',
                industry_id: contact_data.industry
            });

            contact_data.social_media_list = this.getSocialMedia(contact_data);

            ret3.langs.forEach(lang => {
                contact_data['company_industry_' + lang] = ret3['company_industry_' + lang] ?
                    ret3['company_industry_' + lang] :
                    core.lang.get('contact.default_company_industry');
            });
        }

        /*if (contact_data && contact_data.logo) {
            var ret2 = await core.libs.ws.sendAndWait({
                action: 'library/getUserLibraryFiles',
                id: contact_data.logo,
                category: 'logotypes',
            });
            contact_data.logo_url = ret2.files[0] ? ret2.files[0].url : '';
        }*/

        preview_container.html(core.template.render(core.template.contact.preview, { form: ret.form, values: ret.contact, data: contact_data }));

        //this.handleForm($('#' + ret.form.id), ret, 'contact/preview', false);
    }

    async getContactsAction(request) {

        await app.company.showStudioMenu('3dspaces', 'contents');
        $('.studio-container .studio-nav-menu').show();

        var ret = await core.libs.ws.sendAndWait({
            action: 'contact/getUserContacts'
        });

        var ret2 = await core.libs.ws.sendAndWait({
            action: 'library/getUserLibraryFiles',
            category: request.category
        });

        var lang = window.localStorage.getItem('language');
        var url_params = getUrlParams('contact/getContacts');

        $('.studio-items-container').html(core.template.render(core.template.library.contents, ret2 ));
        $('.studio-items-container .contents-box').html(core.template.render(core.template.contact.contact_list, { contacts: ret.contacts }));

        if(url_params.category) {
            $('.studio-items-container .tags .tag[data-category="'+url_params.category+'"]').addClass('selected');
        }
    }

    getSocialMedia(data) {

        var media = '';

        if(data.youtube_url) {
            media += ' YouTube ';
        }

        if(data.linkedin_url) {
            media += ' LinkedIn ';
        }

        if(data.facebook_url) {
            media += ' Facebook ';
        }

        if(data.twitter_url) {
            media += ' Twitter ';
        }

        if(data.instagram_url) {
            media += ' Instagram ';
        }

        return media;

    }

    async showAction(data) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'contact/preview',
            contact_id: data.id
        });

        let html = core.template.render(core.template.contact.preview, {});
        let modal = showModal(core.lang.get('contact.contact_details'), html, undefined, true, { width: 1000 });

        await this.loadPreviewDataAction($('#predef_modal .modal-body'), data.id);

        core.libs.form.handle($('#'+ret.form.id), ret, function (data) {
            (async() => {
                var ret2 = await core.libs.ws.sendAndWait({
                    action: 'contact/preview',
                    data: data
                });

                if (ret2.errors) {
                    core.libs.form.showErrors($('#'+ret.form.id), ret2);
                    var consent_field = $('#'+ret.form.id).find('#field_send_contact_message_consent');
                    var consent_text = $('#'+ret.form.id).find('.user-consent');

                    if(ret2.errors.consent) {
                        consent_field.removeClass('col-2').addClass('col-10');
                        consent_text.addClass('offset-2')
                    } else {
                        consent_field.removeClass('col-10').addClass('col-2');
                        consent_text.removeClass('offset-2')
                    }
                }

                if (ret2.success) {
                    $('#'+ret.form.id).get(0).reset()
                    $('.message-form .message-sent-confirmation').show().delay(5000).fadeOut();
                }
            })();
        });
    }
}

app.contact = new Contact();

