/*
 * Show product
 */

class Product {

    constructor() {
    }

    async editAction(data) {
        var self = this;

        (async () => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'product/productData',
                id: data.id
            });

            $('#content').html(core.template.render(core.template.product.product_form, {form: ret.form, values: ret.product, product: ret.product}));

            var $form = $('#' + ret.form.id);
            core.libs.form.handle($form, ret, function (data) {
                (async() => {
                    var ret = await core.libs.ws.sendAndWait({
                        action: 'product/productData',
                        data
                    });

                    if (ret.errors) {
                        core.libs.form.showErrors($form, ret);
                    }

                    if (ret.error) {
                        showModalFail(ret.message);
                    }

                    if (ret.success) {
                        self.editAction({id: data.id});
                        showModalSuccess(ret.message, 5000);
                    }
                })();
            });
        })();
    }

    async displayProductAction(data) {
        (async () => {
            var modal = showModal(data.name, core.template.render(core.template.product.display, data));
        })();

        return false;
    }
}

app.product = new Product();

