/*
 * Layout.
 */

class Layout {
    constructor() {

    }

    async onReady() {
        await this.render();
        // $('body').append('<script src="public/js/krpano.js"></script>');
    }

    async render() {
        var date = new Date();
        if (app_config.lock_page && !window.localStorage.getItem('unlock_page', true)) {
            document.body.innerHTML = core.template.render(core.template.layout.lock_page, { test: 'layout.header', year: date.getFullYear() });
        } else {
            document.body.innerHTML = core.template.render(core.template.layout.main, { test: 'layout.header', year: date.getFullYear() });
        }

        $('body').attr('lang', core.lang.language);
        $('body').addClass(this.isIframe() ? 'iframe' : 'window');

        await runMethod('onLayoutReady');
    }

    async onChangeLanguage(language) {
        $('body').attr('lang', language);
        core.lang.language = language;
        await this.render();
    }

    async onTriggerUrlAfter(data) {
        if(typeof gtag == 'function') {
            gtag('event', data.action.replaceAll('/','_'), {
                'time': new Date(),
             });
        }
    }

    async onPermissionsChanged() {
        if (core.template.layout) {
            $('#menu').html(core.template.render(core.template.layout.menu, { }));
        }
    }

    async onCompanyChanged() {
        this.onPermissionsChanged();
    }

    isIframe () {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }
}

app.layout = new Layout();

