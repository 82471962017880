
class Library {

    constructor() {
        this.seleted_library_files = [];
        this.chosen_folder_to_move = null;
        this.pathname = null;
    }

    async setAction(request) {
        this.library_file_categories = request.library_file_categories;
    }

    async setPathnameAction(request) {
        if(request.pathname) this.pathname = request.pathname;
    }

    async resetPathnameAction() {
        this.pathname = null;
    }

    async addAction(request) {

        await app.company.showStudioMenu('3dspaces', 'contents');

        // checks if user is regular(no company) or company user
        var ret2 = await core.libs.ws.sendAndWait({
            action: 'user/checkType',
        });

        if(ret2.regular) runActionUrl('home');

        let self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'library/add',
            category: request.category
        });

        $('.studio-items-container').html(core.template.render(core.template.library.add_file, { form: ret.form, category_name: request.category, category_display_name: core.lang.get(`company.${request.category}`) }));

        var $form = $('#' + ret.form.id);
        core.libs.form.handle($form, ret, function (data) {

            if(request.folder)  data.id_parent = request.folder;

            (async() => {
                data.lang = core.lang.language;

                var ret = await core.libs.ws.sendAndWait({
                    action: 'library/add',
                    data
                });

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }

                if (ret.success) {
                    if(ret.category == 'folder' && ret.file_id) {
                        runActionUrl('library/folder/id/' + ret.file_id);
                    } else {

                        if(self.pathname) {
                            runActionUrl(self.pathname);
                            runAction({action: 'library/resetPathname'});
                        } else {
                            runActionUrl('library/getLibraryFiles');
                        }
                    }
                }
            })();
        });
    }

    async getLibraryFilesAction(data) {
        var self = this;

        await app.company.showStudioMenu('3dspaces', 'contents');
        $('.studio-container .studio-nav-menu').show();

        // checks if user is regular(no company) or company user
        var ret2 = await core.libs.ws.sendAndWait({
            action: 'user/checkType',
        });

        if(ret2.regular) runActionUrl('home');

        var url_params = getUrlParams('library/getLibraryFiles');

        var ret = await core.libs.ws.sendAndWait({
            action: 'library/getUserLibraryFiles',
            category: data.category,
            files_for_folder: false,
            only_on_home: true
        });

        if(ret.folders.length) {
            ret.folders.forEach(folder => {
                folder.folder_id = folder.id;
            })
        }

        ret.edit_actions = [];
        for(let i of ret.library_file_categories) {
            ret.edit_actions[i.name] = i.edit_action;
        }

        for(let i in ret.files) {
            ret.files[i].template = 'library.'+ret.files[i].category;
            ret.files[i].edit_action_event = true;
            ret.files[i].file_id = ret.files[i].id;
        }

        ret.pathname = window.location.pathname;
console.log(ret);
        $('.studio-items-container').html(core.template.render(core.template.library.contents, ret ));
        $('.studio-items-container .contents-box').html(core.template.render(core.template.library.library_files, ret ));

        if(url_params.category) {
            $('.studio-items-container .tags .tag[data-category="'+url_params.category+'"]').addClass('selected');
        } else {
            $('.studio-items-container .tags .tag[data-category="all"]').addClass('selected');
        }

        this.libraryFileCheckboxHandle();
    }

    async loadFilesForFolderAction(data) {
        var lang = app.company.getLang();
        let current =
            app.company.hotspot_clicked &&
            app.company.company_shown.company.config.hotspots[lang] &&
            app.company.company_shown.company.config.hotspots[lang][app.company.hotspot_clicked.id] ? app.company.company_shown.company.config.hotspots[lang][app.company.hotspot_clicked.id][data.selected_type] : false;
        var current_selected_id = current ? current.id : 0;

        await this.loadUserFolderFilesAction({ folder_id: data.folder_id, type: data.selected_type, category: data.selected_category, selected_type: data.selected_type, current_selected_id, onclick: data.onclick }, async(id) => {
            if (data.onclick) {
                data.onclick(id);
            } else {
                if (app.company.hotspot_clicked) {
                    var ret = await core.libs.ws.sendAndWait({
                        action: 'company/updateHotspot',
                        type: data.selected_type,
                        lang,
                        id: app.company.company_shown.id,
                        id_hotspot: app.company.hotspot_clicked.id,
                        name: data.selected_category,
                        id_parent: data.folder_id,
                        id_record: id,
                    });

                    app.company.company_shown.company.config = ret.config;
                    app.company.company_shown.company.completed = ret.completed;
                    app.company.updateKrpanoHotspots();
                    await app.company.hotspot(app.company.hotspot_clicked);
                } else {
                    console.log('Error - hotspot not selected or onclick callback is missing!');
                }
            }
        })

    }

    async loadUserFolderFilesAction(data, onclick = false) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'library/getUserLibraryFiles',
            category: data.category,
            files_for_folder: data.folder_id ? true : false,
            folder_id: data.folder_id,
            selected_category: data.category,
            category: data.category,
            include_folders: true,
        });

        var ret2 = await core.libs.ws.sendAndWait({
            action: 'library/getFolderBreadcrumbs',
            current_id: data.folder_id
        });

        for(let i in ret.files) {
            ret.files[i].selected = ret.files[i].id == parseInt(data.current_selected_id) ? true : false;
            ret.files[i].template = 'library.'+ret.files[i].category;
            ret.files[i].edit_action_event = false;
            ret.files[i].file_id = ret.files[i].id;
        }

        let no_files_message;

        if (data.category == 'logotypes' || data.category == 'photos') {
            //no_files_message = '<button class="btn btn-primary" onclick="hideAllModals(); runActionUrl(\'/library/add/category/'+data.category+'/folder/'+data.folder_id+'\'); return false;">'+core.lang.get('library.go_to_add_'+data.category)+'</button>';
        }

        if (ret2.folder_tree && ret2.folder_tree.length) {
            $('.breadcrumbs').html(core.template.render(core.template.company.folder_breadcrumbs, ret2));
        } else {
            $('.breadcrumbs').html('');
        }
        $('.category-files-container.library-files-selection').html(core.template.render(core.template.library.selected_folder_files, { files: ret.files, no_files_message, folder_tree: ret2.folder_tree }));

        var library_file_box = $('.category-files-container.library-files-selection').find('.library-file-box');

        $('.breadcrumbs li').click(function() {
            var file_id = $(this).data('file_id');
            runAction({action: "library/loadFilesForFolder", folder_id: file_id, selected_category: data.category, selected_type: data.selected_type, onclick: data.onclick });
        });

        library_file_box.click(async function () {
            var file_id = $(this).data('file_id');
            if ($(this).hasClass('folder')) {
                runAction({action: "library/loadFilesForFolder", folder_id: file_id, selected_category: data.category, selected_type: data.selected_type, onclick: data.onclick });
            } else {
                var self = $(this);

                library_file_box.removeClass('file-selected');
                self.addClass('file-selected');

                hideModal();

                if (onclick) {
                    onclick(file_id);
                }
            }
        });
    }

    libraryFileCheckboxHandle() {
        var self = this;
        var library_file_checkbox = $('.studio-items-container .move-to-icon-box .library-file-checkbox');
        var action_buttons = $('.studio-items-container .action-buttons');

        library_file_checkbox.change(function() {
            var this_checkbox = $(this);
            var category_name = this_checkbox.data('category_name');
            var file_id = this_checkbox.val();
            var object_key = category_name + '-' + file_id;

            var object_to_save = {key: object_key, category_name: category_name, file_id: file_id};

            var library_file_checkbox_checked = $('.studio-items-container .move-to-icon-box .library-file-checkbox:checked');
            if(library_file_checkbox_checked.length > 0) {
                action_buttons.removeClass('hide');
            } else {
                action_buttons.addClass('hide');
            }

            if(this_checkbox.is(':checked')) {
                self.seleted_library_files.push(object_to_save);
            } else {
                self.seleted_library_files = self.seleted_library_files.filter(function( obj ) {
                    return obj.key !== object_key;
                });
            }

            return false;
        })
    }

    async showDeleteFilesConfirmationAction() {
        let modal = showModalConfirm(core.lang.get('company.confirm_delete_elements'), function () {
            app.library.confirmDeleteLibraryFilesAction();
        });

        // let html = core.template.render(core.template.library.confirm_file_delete, {});
        // let modal = showModal(core.lang.get('library.confirm_file_delete'), html, [
        //     {label: core.lang.get('common.confirm'), class: 'btn btn-primary', onclick: function() { runAction({action: "library/confirmDeleteLibraryFiles"}); return false }},
        //     {label: core.lang.get('common.cancel'), class: 'btn btn-secondary', onclick: function() { runAction({action: "library/cancelLibraryFiles"}); return false }}
        // ], true, { width: 1000 });
    }

    async displayFolderTreeAction() {
        var ret = await core.libs.ws.sendAndWait({
            action: 'library/getFolderTree',
        });

        ret.display_folder_tree = ret.folder_tree.length ? true : false;

        let html = core.template.render(core.template.library.folder_tree, ret);
        let modal = showModal(core.lang.get('library.folders'), html, [
            {label: core.lang.get('common.confirm'), class: 'btn btn-primary', onclick: function() { runAction({action: "library/confirmMoveFilesToFolder"})}},
            {label: core.lang.get('common.cancel'), class: 'btn btn-sm btn-secondary', onclick: function() { runAction({action: "library/cancelLibraryFiles"}) }}
        ], true, { width: 1000 });

        $('#predef_modal #library-folder-tree p.folder-item').on('click', function() {
            $('#predef_modal #library-folder-tree p.folder-item').removeClass('selected');
            $(this).addClass('selected');
        });
    }

    async chooseFolderToMoveAction(data) {
        this.chosen_folder_to_move = data.id;
    }

    async confirmMoveFilesToFolderAction(data) {
        var self = this;

        if(self.chosen_folder_to_move && self.seleted_library_files.length) {

            var data = { selected_files: self.seleted_library_files, chosen_folder_to_move: self.chosen_folder_to_move };

            var ret = await core.libs.ws.sendAndWait({
                action: 'library/moveFilesToFolder',
                data
            });

            if(ret.success) {
                this.seleted_library_files = [];
                $('#predef_modal').modal('hide');
                self.makeRedirect();
            }
        }
    }

    async confirmDeleteLibraryFilesAction(data) {
        var self = this;
        if(self.seleted_library_files.length) {
            var data = { selected_files: self.seleted_library_files };

            var ret = await core.libs.ws.sendAndWait({
                action: 'library/deleteLibraryFiles',
                data
            });

            if(ret.success) {
                $('#predef_modal').modal('hide');
                self.makeRedirect();
            }
        }
    }

    async cancelLibraryFilesAction() {
        var self = this;
        $('.studio-items-container .move-to-icon-box .library-file-checkbox').prop('checked', false);
        $('.studio-items-container .action-buttons').addClass('hide');
        self.seleted_library_files = [];
        self.chosen_folder_to_move = null;
        $('#predef_modal').modal('hide');
    }

    async showPhotoAction(data) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'library/getUserLibraryFiles',
            category: data.category,
            id: data.id,
            public: data.public,
            force_show: true
        });

        ret.gallery_images = ret.files;
        app.gallery.showAction(data, ret);
        return;

        let options = { width: "80%" };
        //let options = { el: 'predef_modal_black' };

        let modal = showModal('', core.template.render(core.template.gallery.show_gallery, { hide_arrows: true, gallery: ret.gallery, gallery_name: '', gallery_images: ret.files }), false, true, options);
        return;

        // old
        for(let i in ret.files) {
            showModal('', core.template.render(core.template.library.show_photo, { file: ret.files[i] } ), undefined, true, { width: 1200 });
            return;
        }
    }

    async downloadPdfAction(data) {

        var ret = await core.libs.ws.sendAndWait({
            action: 'library/getUserLibraryFiles',
            category: data.category,
            id: data.id,
            public: data.public,
            force_show: true
        });

        if(typeof ret.files[0] == 'object' && ret.files[0].url) {
            setTimeout(() => {
                window.open(ret.files[0].url);
            }, 1000)
        }
    }

    async folderAction(data) {

        await app.company.showStudioMenu('3dspaces', 'contents');

        var ret = await core.libs.ws.sendAndWait({
            action: 'library/getFolderFiles',
            id_parent: data.id
        });

        var ret2 = await core.libs.ws.sendAndWait({
            action: 'library/getFolderBreadcrumbs',
            current_id: data.id
        });

        var ret3 = await core.libs.ws.sendAndWait({
            action: 'library/getUserLibraryFiles',
            category: data.category
        });

        if(ret3.folders.length) {
            ret3.folders.forEach(folder => {
                folder.folder_id = folder.id;
            })

            ret.folders = ret3.folders;
        }

        ret.edit_actions = [];
        for(let i of ret.library_file_categories) {
            ret.edit_actions[i.name] = i.edit_action;
        }

        if(ret.files.length) {
            for(let i in ret.files) {
                ret.files[i].template = 'library.'+ret.files[i].category;
                ret.files[i].edit_action_event = true;
            }
        }

        ret.folder_id = data.id;
        ret.pathname = window.location.pathname;

        $('.studio-items-container').html(core.template.render(core.template.library.show_folder, ret ));
        $('.studio-items-container .breadcrumbs').html(core.template.render(core.template.library.folder_breadcrumbs, ret2 ));
        $('.studio-items-container .contents-box').html(core.template.render(core.template.library.library_files, ret ));

        this.libraryFileCheckboxHandle();
    }

    async moveToFolderAction(data) {
        var ret = await core.libs.ws.sendAndWait({
            action: 'library/moveFileToFolder',
            file_id: data.file_id,
            folder_id: data.folder_id
        });

        if(ret.success) {
            this.getLibraryFilesAction({});
            showModalSuccess(ret.message, 5000);
        }
    }

    async makeRedirect() {
        var current_location = window.location.pathname;
        if(current_location.includes('library/getLibraryFiles')) {
            runActionUrl('library/getLibraryFiles');
        } else if(current_location.includes('library/folder')) {
            var url_params = getUrlParams('library/folder');
            if(url_params.id) {
                runActionUrl('library/folder/id/' + url_params.id);
            } else {
                runActionUrl('library/getLibraryFiles');
            }
        }
    }
}

app.library = new Library();

