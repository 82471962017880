getFile = async function(id) {
    var ret = await core.libs.ws.sendAndWait({
        action: 'library/getFile',
        id
    });

    return ret;
}

getFileIcon = function(file, params) {
    let $icon = $(`<div data-id='${file.id}' class='icon'><div class='thumbnail'></div></div>`);

    if (file.thumbnail) {
        file.thumb_url = file.thumbnail
    }
    
    if (file.thumb_url) {
        $icon.find('.thumbnail').css('background-image', 'url('+file.thumb_url+')');
    }

//    if (params.ondelete) {
//        params.delete_icon = params.delete_icon || `<i class="fas fa-times"></i>`;
//        let $button = $(`<button class='btn btn-primary btn-sm remove'>${params.delete_icon}</i></button>`).click((e) => {
//            if (params.ondelete) {
//                params.ondelete(e, file);
//            }
//
//            e.stopPropagation();
//            return false;
//        });
//        
//        $icon.append($button);
//    }

    if (params.icon_callback && !params.icon) {
        params.icon = `<i class="fas fa-times"></i>`;
    }

    if (params.icon) {
        if (params.icon == true) {
            params.icon = `<i class="fas fa-times"></i>`;
        }
        
        let $button = $(`<button class='btn btn-primary btn-sm click'>${params.icon}</i></button>`).click((e) => {
            if (params.icon_callback) {
                params.icon_callback(e, file);
            }

            e.stopPropagation();
            return false;
        });
        $icon.append($button);
    }
    
    $icon.click((e) => {
        if (params.onclick) {
            params.onclick(e, file);
        }

        e.stopPropagation();
        return false;
    });
    
    return $icon;
}

function getUrlParams(action = 'group/show') {

    var result = {};
    var pathname = window.location.pathname;

    if(!pathname.includes(action)) return false;

    var url_params = pathname.split(action)[1];

    var params = url_params.split('/').filter((el) => {
        return el != '';
    });

    if(!params.length) return result;

    params.forEach((x, i, a) => {
        if(i % 2) {
            result[a[i - 1]] = x;
        }
    });

    return result;
}

function getCurrentLocation() {
    return window.location.pathname;
}

function setFullScreen(state) {
    var element = document.querySelector('body');

    if(state) {
        $(element).addClass('fullscreen');
        
        var requestMethod = element.requestFullScreen 
                || element.webkitRequestFullScreen 
                || element.mozRequestFullScreen 
                || element.msRequestFullScreen;

        if (requestMethod) { // Native full screen
            requestMethod.call(element);
        } else if (typeof window.ActiveXObject !== "undefined") {
            var wscript = new ActiveXObject("WScript.Shell");
            if (wscript !== null) {
                wscript.SendKeys("{F11}");
            }
        }
    }else {
        $(element).removeClass('fullscreen');
        var requestMethod = document.exitFullscreen
                || document.webkitExitFullscreen
                || document.mozCancelFullScreen
                || document.msExitFullscreen;

        var isFullscreen = document.isFullscreen
                || document.webkitIsFullScreen
                || document.mozIsFullScreen
                || document.msIsFullscreen
                || false;
        if(!isFullscreen) {
            return ;
        }

        if (requestMethod) { 
            try{
                requestMethod.call(document);
            }catch(er) {}
        } else if (typeof window.ActiveXObject !== "undefined") {
            var wscript = new ActiveXObject("WScript.Shell");
            if (wscript !== null) {
                wscript.SendKeys("{F11}");
            }
        }
    }
}

function fullScreenExit() {

    let onFullScreenExit = function() {
        if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) setFullScreen(false);
    }

    document.addEventListener('fullscreenchange', onFullScreenExit, false);
    document.addEventListener('mozfullscreenchange', onFullScreenExit, false);
    document.addEventListener('MSFullscreenChange', onFullScreenExit, false);
    document.addEventListener('webkitfullscreenchange', onFullScreenExit, false);
}