/*
 * Languages.
 */

class Lang {
    _sections = [];

    constructor() {
        addPromise(this, 'ready');
    }

    async setAllAction(request) {
        if (request.language) {
            this.language = request.language;
        }

        if (request.data) {
            this.setSections(request.data);
            resolvePromise(this, 'ready');
        }
    }

    async onChangeLanguage(language) {
        this.language = language;
    }

    setSections(sections) {
        for (var section in sections) {
            this._sections.push(section);
            this[section] = sections[section];
        }
    }

    clearSections() {
        for (var section of this._sections) {
            delete this[section];
        }
    }

    get(name, params) {
        var names = name.split('.');

        if (names.length == 1 && this.hasOwnProperty(names[0])) {
            return this.replaceParams(this[names[0]], params);
        }

        if (names.length == 2 && this.hasOwnProperty(names[0]) && this[names[0]].hasOwnProperty(names[1])) {
            return this.replaceParams(this[names[0]][names[1]], params);
        }

        if (names.length == 3 && this.hasOwnProperty(names[0]) && this[names[0]].hasOwnProperty(names[1]) && this[names[0]][names[1]].hasOwnProperty(names[2])) {
            return this.replaceParams(this[names[0]][names[1]][names[2]], params);
        }

        if (core.config.development) {
            core.libs.ws.sendJson({
                action: 'lang/add',
                name
            });

            return "[NEW LANG: "+name+"]";
        }

        return "???:"+name+"";
    }

    replaceParams(text, params, key_prefix = '', level = 0) {
        if(typeof text != 'string') {
            return text;
        }
        if(params) {
            //params.baseUrl = core.libs.app_config.express.home;
            for(let key in params) {
                if(typeof params[key] == 'object' && params[key]) {
                    if(params[key].hasOwnProperty('_class')) {
                        text = replaceAll(text, '{'+key_prefix+key+'}', params[key].toString());
                    }
                    if(level < 3) {
                        text = this.replaceParams(text, params[key], key_prefix + key + '.', level + 1);
                    }
                }else {
                    //text = text.replace('{'+key_prefix+key+'}', params[key]);
                    text = replaceAll(text, '{'+key_prefix+key+'}', params[key]);
                }
            }
        }
        return text;
    }

    async changeLanguage(language) {
        var result = await core.libs.ws.sendAndWait({
            action: 'lang/changeLanguage',
            language
        });

        if (result.success) {
            this.clearSections();
            this.setSections(result.data);
            /*resetPromises('readyForActions');*/
            runMethod('onChangeLanguage', language);
            /*await whenModules('readyForActions');*/
            window.localStorage.setItem('language', language);
            triggerUrl();
        }
    }
}

core.lang = new Lang();
