/*
 * Show gallery
 */

class Gallery {
    logged = false;

    constructor() {
    }

    async editAction(request) {
        await app.company.showStudioMenu('3dspaces', 'contents');

        let self = this;

        var ret = await core.libs.ws.sendAndWait({
            action: 'gallery/edit',
            id: request.id,
        });

        ret.category_name = 'galleries';
        $('.studio-items-container').html(core.template.render(core.template.library.add_file, ret ));

        var $form = $('#' + ret.form.id);

        ret.form.fields[1].add_callback = ($el) => {
            //console.log($el.data('id'));
        };

        ret.form.fields[1].delete_callback = ($el) => {
            //console.log($el.data('id'));
        };

        core.libs.form.handle($form, ret, function (data) {

            (async() => {
                data.lang = core.lang.language;

                if(request.folder)  data.id_folder = request.folder;

                var ret = await core.libs.ws.sendAndWait({
                    action: 'gallery/edit',
                    data
                });

                if (ret.errors) {
                    core.libs.form.showErrors($form, ret);
                }

                if (ret.success) {
                    if(app.library.pathname) {
                        runActionUrl(app.library.pathname);
                        runAction({action: 'library/resetPathname'});
                    } else {
                        runActionUrl('library/getLibraryFiles');
                    }
                }
            })();
        });
    }

    async showAction(data, ret = false) {
        var self = this;
        var gallery_name = '&nbsp;';

        if (!ret) {
            ret = await core.libs.ws.sendAndWait({
                action: 'gallery/show',
                id: data.id
            });
            gallery_name = ret.gallery['name_'+core.lang.language];
        } else {

        }

        $('#photoswipe-container').remove();
        let html = core.template.render(core.template.gallery.show_gallery_photoswipe, { gallery: ret.gallery, gallery_name: gallery_name, gallery_images: ret.gallery_images });
        $('body').append(html);
        //$('#photoswipe-container > div > a:first-child').click();
    }

    async showAction_OLD(data, ret = false) {
        var self = this;
        var gallery_name = '&nbsp;';

        if (!ret) {
            ret = await core.libs.ws.sendAndWait({
                action: 'gallery/show',
                id: data.id
            });
            gallery_name = ret.gallery['name_'+core.lang.language];
        } else {

        }

        //let options = { width: "80%" };
        let options = { el: 'predef_modal_black' };
        let modal = showModal(gallery_name, core.template.render(core.template.gallery.show_gallery, { gallery: ret.gallery, gallery_name: gallery_name, gallery_images: ret.gallery_images }), false, true, options);
        if (ret.gallery_images.length <= 1) {
            $('#predef_modal_black .slide-indicator').remove();
        } else {
            if (!window.mobile) {
                $('#predef_modal_black .slide-indicator').hide();
                $('#predef_modal_black .gallery-images').on('mouseenter', function() {
                    $('#predef_modal_black .slide-indicator').stop().fadeIn();
                });
                $('#predef_modal_black .gallery-images').on('mouseleave', function() {
                    $('#predef_modal_black .slide-indicator').stop().fadeOut();
                });
            }
        }
    }

    async showGalleryImageAction(data) {
        let modal = showModal(data.file_name, core.template.gallery.show_gallery_image, { file_name: data.file_name, img_src: data.img_src });
    }

    /*async homeAction() {
        var self = this;

        (async () => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'gallery/home'
            });

            var ret2 = await core.libs.ws.sendAndWait({
                action: 'product/getAllProducts'
            });

            //var gallery = ret.company ? Object.values(ret.gallery) : [];
            $('#content').html(core.template.render(core.template.gallery.gallery_form, {form: ret.form, values: ret.gallery, company: ret.company}));
            var krpano = ret.company ? core.template.render(core.template.company.show, { company: ret.company, gallery: Object.values(ret.company.gallery), products: ret2.products }) : '';
            $('#krpano_container').html(krpano);

            var $form = $('#' + ret.form.id);
            core.libs.form.handle($form, ret, function (data) {
                (async() => {
                    var ret = await core.libs.ws.sendAndWait({
                        action: 'company/gallery',
                        data
                    });

                    if (ret.errors) {
                        core.libs.form.showErrors($form, ret);
                    }

                    if (ret.success) {
                        self.homeAction();
                        showModalSuccess(ret.message, 5000);
                    }
                })();
            });
        })();
    }

    async visualGalleryAction(data) {
        var self = this;

        (async () => {

            var ret = await core.libs.ws.sendAndWait({
                action: 'gallery/home'
            });

            if (ret.error) {
                showModalFail(ret.message, 5000);
                return;
            }

            ret.form.id = 'visual_company_form';

            $('#modal').html(core.template.render(core.template.company.visual_editor_modal, {form: ret.form, values: ret.gallery, title: core.lang.get('company.gallery')}));

            var options = {};
            var myModal = new bootstrap.Modal(document.getElementById('visual_form_modal'), options);
            myModal.show();

            var $form = $('#' + ret.form.id);

            core.libs.form.handle($form, ret, function (data) {

                (async() => {
                    var ret = await core.libs.ws.sendAndWait({
                        action: 'gallery/visualGallery',
                        data
                    });

                    if (ret.errors) {
                        core.libs.form.showErrors($form, ret);
                    }

                    if (ret.success) {
                        document.getElementById('visual_form_modal').addEventListener('hidden.bs.modal', function (event) {
                            showModalSuccess(ret.message, 5000);
                        })
                        myModal.hide();

                        var ret1 = await core.libs.ws.sendAndWait({
                            action: 'company/visualCompany'
                        });

                        var ret2 = await core.libs.ws.sendAndWait({
                            action: 'product/getAllProducts'
                        });

                        var ret3 = await core.libs.ws.sendAndWait({
                            action: 'gallery/home'
                        });

                        runAction({action: 'company/loadStandKrpano', company: ret1.company, stand_number: ret1.company.current_stand, user_products: ret2.products, gallery: ret3.gallery, only_preview: true});

                        app.company.updateKrpano();
                        //var preview_data = await self.getPreviewPanoData(id_company);
                        //self.loadStandKrpano(preview_data.company.company, preview_data.company.company.current_stand, preview_data.products.products, preview_data.gallery.gallery, true);
                        //runAction({action: 'company/loadStandKrpano', company: preview_data.company.company, stand_number: preview_data.company.company.current_stand, user_products: preview_data.products.products, gallery: preview_data.gallery.gallery, only_preview: true});
                    }
                })();
            });
        })();

        return false;

    }*/

}

app.gallery = new Gallery();

